<template>
  <div class="">
    <Header></Header>

    <!-- Main -->
    <main class="u-main">
      <Navigation></Navigation>

      <div class="u-content">
        <div class="u-body min-h-700">
          <h1 class="h2 mb-2">
            Events & Conferences

            <div class="pull-rights ui-mt-15 pull-right">
              <div class="dropdown">
                <span class="">
                  <div class="form-group">
                    <div class="custom-control custom-switch mb-2">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="customSwitch1"
                        :checked="row.status"
                        @click="onStatus"
                      />
                      <label
                        class="custom-control-label"
                        for="customSwitch1"
                        v-html="row.status ? 'Show Events' : 'Hide Events'"
                      >
                      </label>
                    </div>
                  </div>
                </span>
              </div>
            </div>
          </h1>

          <!-- Breadcrumb -->
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <router-link :to="{ name: 'dashboard' }">Dashboard</router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Events & Conferences
              </li>
            </ol>
          </nav>
          <!-- End Breadcrumb -->

          <!-- Loading -->
          <div v-if="pgLoading" class="row h-100">
            <div class="container text-center">
              <div class="spinner-grow mt-5" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </div>
          <!-- End Loading -->

          <!-- Form -->
          <form
            v-if="!pgLoading"
            @submit.prevent="editRow"
            enctype="multipart/form-data"
            class="h-100"
          >
            <!-- Content -->
            <div class="tab-content">
              <div class="row">
                <div class="col-md-12 mb-5">
                  <!-- Card Background -->
                  <div class="card">
                    <div class="card-body">
                      <div id="accordion" class="accordion">
                        <div id="TabBackground" class="card-header">
                          <h2
                            class="h4 card-header-title"
                            @click="collapseToggle('Background')"
                            aria-expanded="false"
                            aria-controls="collapseBackground"
                            data-toggle="collapse"
                            data-target="#collapseBackground"
                          >
                            Background
                            <span
                              id="iconToggleBackground"
                              class="ti-angle-up u-sidebar-nav-menu__item-arrow pull-right black"
                            >
                            </span>
                          </h2>
                        </div>
                        <div
                          id="collapseBackground"
                          class="collapse show"
                          aria-labelledby="TabBackground"
                          data-parent="#accordion"
                        >
                          <div class="col-12 pt-3">
                            <div class="form-group">
                              <label
                                >Background 1200 * 440
                                <button
                                  v-if="row.preview"
                                  type="button"
                                  class="btn btn-danger btn-sm btn-pill"
                                  style="margin-top: -10px; margin-left: 10px"
                                  @click="
                                    row.preview = '';
                                    row.image = '';
                                  "
                                >
                                  Remove Image
                                </button>
                              </label>
                              <p>
                                <img
                                  v-if="row.preview"
                                  :src="row.preview"
                                  style="max-width: 100%"
                                />
                              </p>
                              <input
                                type="file"
                                class="form-control"
                                v-on:change="onImageChange"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End Card Background -->

                  <!-- Card Event -->
                  <div class="card mt-5">
                    <div class="card-body">
                      <div id="accordion" class="accordion">
                        <div id="TabMask" class="card-header">
                          <h2
                            class="h4 card-header-title"
                            @click="collapseToggle('Mask')"
                            aria-expanded="false"
                            aria-controls="collapseMask"
                            data-toggle="collapse"
                            data-target="#collapseMask"
                          >
                            Mask
                            <span
                              id="iconToggleMask"
                              class="ti-angle-up u-sidebar-nav-menu__item-arrow pull-right black"
                            >
                            </span>
                          </h2>
                        </div>
                        <div
                          id="collapseMask"
                          class="collapse"
                          aria-labelledby="TabMask"
                          data-parent="#accordion"
                        >
                          <div class="col-12 row pt-3">
                            <!-- Title -->
                            <div class="form-group col-9">
                              <label for="input1">background Title</label>
                              <vue-editor
                                id="iEditor0"
                                v-model="row.bgTitle"
                                :init="{
                                  height: 200,
                                }"
                              />
                            </div>
                            <!-- End Title -->

                            <!-- Slug -->
                            <div class="form-group col-3">
                              <label for="input2">background Color</label>
                              <input
                                class="form-control"
                                id="input2"
                                type="color"
                                style="height: 200px"
                                v-model="row.bgColor"
                              />
                            </div>
                            <!-- End Slug -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End Card Writer -->

                  <!-- Card Content -->
                  <div class="card mt-5">
                    <div class="card-body">
                      <div id="accordion" class="accordion">
                        <div id="TabContent" class="card-header">
                          <h2
                            class="h4 card-header-title"
                            @click="collapseToggle('Content')"
                            aria-expanded="false"
                            aria-controls="collapseContent"
                            data-toggle="collapse"
                            data-target="#collapseContent"
                          >
                            Content
                            <span
                              id="iconToggleContent"
                              class="ti-angle-up u-sidebar-nav-menu__item-arrow pull-right black"
                            >
                            </span>
                          </h2>
                        </div>
                        <div
                          id="collapseContent"
                          class="collapse"
                          aria-labelledby="TabContent"
                          data-parent="#accordion"
                        >
                          <div class="col-12 pt-3">
                            <!-- Body -->
                            <div class="col-12 row" style="padding: 0">
                              <div class="form-group col-4">
                                <vue-editor
                                  id="iEditor1"
                                  v-model="row.body1"
                                  :init="{
                                    height: 300,
                                  }"
                                />
                              </div>
                              <div class="form-group col-4">
                                <vue-editor
                                  id="iEditor2"
                                  v-model="row.body2"
                                  :init="{
                                    height: 300,
                                  }"
                                />
                              </div>
                              <div class="form-group col-4">
                                <vue-editor
                                  id="iEditor3"
                                  v-model="row.body3"
                                  :init="{
                                    height: 300,
                                  }"
                                />
                              </div>
                              <div class="form-group col-4">
                                <vue-editor
                                  id="iEditor4"
                                  v-model="row.body4"
                                  :init="{
                                    height: 300,
                                  }"
                                />
                              </div>
                              <div class="form-group col-4">
                                <vue-editor
                                  id="iEditor5"
                                  v-model="row.body5"
                                  :init="{
                                    height: 300,
                                  }"
                                />
                              </div>
                              <div class="form-group col-4">
                                <vue-editor
                                  id="iEditor6"
                                  v-model="row.body6"
                                  :init="{
                                    height: 300,
                                  }"
                                />
                              </div>

                              <div class="col-12">
                                <br />
                                <hr />
                                <br />
                              </div>

                              <!-- Body -->
                              <div class="form-group col-4">
                                <label>Title</label>
                                <vue-editor
                                  id="iHint"
                                  v-model="row.bgHint"
                                  :init="{
                                    height: 300,
                                  }"
                                />
                              </div>
                              <div class="form-group col-8">
                                <label for=""
                                  >Background 1200 * 440
                                  <button
                                    v-if="row.background_preview"
                                    type="button"
                                    class="btn btn-danger btn-sm btn-pill"
                                    style="margin-top: -10px; margin-left: 10px"
                                    @click="
                                      row.background_preview = '';
                                      row.background_image = '';
                                    "
                                  >
                                    Remove Image
                                  </button>
                                </label>
                                <img
                                  :src="
                                    row.background_preview
                                      ? row.background_preview
                                      : ''
                                  "
                                  style="width: 100%; height: 300px"
                                />
                                <input
                                  type="file"
                                  class="form-control"
                                  @change="onBgImageChange"
                                />
                              </div>
                              <!-- End Body -->

                              <div class="col-12">
                                <br />
                                <hr />
                                <br />
                              </div>

                              <div class="form-group col-md-6 col-sm-12">
                                <vue-editor
                                  id="iEditor7"
                                  v-model="row.body7"
                                  :init="{
                                    height: 300,
                                  }"
                                />
                              </div>

                              <div class="form-group col-md-6 col-sm-12">
                                <vue-editor
                                  id="iEditor8"
                                  v-model="row.body8"
                                  :init="{
                                    height: 300,
                                  }"
                                />
                              </div>

                              <div class="form-group col-md-6 col-sm-12">
                                <vue-editor
                                  id="iEditor9"
                                  v-model="row.body9"
                                  :init="{
                                    height: 300,
                                  }"
                                />
                              </div>

                              <div class="form-group col-md-6 col-sm-12">
                                <vue-editor
                                  id="iEditor10"
                                  v-model="row.body10"
                                  :init="{
                                    height: 300,
                                  }"
                                />
                              </div>

                              <div class="col-12">
                                <br />
                                <hr />
                                <br />
                              </div>

                              <div class="form-group col-md-6 col-sm-12">
                                <vue-editor
                                  id="iEditor11"
                                  v-model="row.body11"
                                  :init="{
                                    height: 300,
                                  }"
                                />
                              </div>

                              <div class="form-group col-md-6 col-sm-12">
                                <vue-editor
                                  id="iEditor12"
                                  v-model="row.body12"
                                  :init="{
                                    height: 300,
                                  }"
                                />
                              </div>

                              <div class="form-group col-md-6 col-sm-12">
                                <vue-editor
                                  id="iEditor13"
                                  v-model="row.body13"
                                  :init="{
                                    height: 300,
                                  }"
                                />
                              </div>

                              <div class="form-group col-md-6 col-sm-12">
                                <vue-editor
                                  id="iEditor14"
                                  v-model="row.body14"
                                  :init="{
                                    height: 300,
                                  }"
                                />
                              </div>

                              <div class="col-12">
                                <br />
                                <hr />
                                <br />
                              </div>

                              <div class="form-group col-md-6 col-sm-12">
                                <vue-editor
                                  id="iEditor15"
                                  v-model="row.body15"
                                  :init="{
                                    height: 300,
                                  }"
                                />
                              </div>

                              <div class="form-group col-md-6 col-sm-12">
                                <vue-editor
                                  id="iEditor16"
                                  v-model="row.body16"
                                  :init="{
                                    height: 300,
                                  }"
                                />
                              </div>

                              <div class="form-group col-md-6 col-sm-12">
                                <vue-editor
                                  id="iEditor17"
                                  v-model="row.body17"
                                  :init="{
                                    height: 300,
                                  }"
                                />
                              </div>

                              <div class="form-group col-md-6 col-sm-12">
                                <vue-editor
                                  id="iEditor18"
                                  v-model="row.body18"
                                  :init="{
                                    height: 300,
                                  }"
                                />
                              </div>
                            </div>
                            <!-- End Body -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End Card Background -->
                </div>
              </div>
            </div>

            <div class="col-md-12 row">
              <div class="form-group mr-2">
                <button class="btn btn-primary" :disabled="btnLoading">
                  <span v-if="btnLoading">
                    <span
                      class="spinner-grow spinner-grow-sm mr-1"
                      role="status"
                      aria-hidden="true"
                    >
                    </span
                    >Loading...
                  </span>
                  <span v-if="!btnLoading" class="ti-check-box"></span>
                  <span v-if="!btnLoading"> Update Events & Conferences</span>
                </button>
              </div>

              <div class="form-group">
                <button
                  type="button"
                  class="btn btn-danger"
                  :disabled="btnLoading"
                  @click="cancel"
                >
                  <span class="ti-close"></span>
                  <span> Cancel </span>
                </button>
              </div>
            </div>
          </form>
          <!-- End Form -->
        </div>
        <!-- End Content Body -->

        <Footer></Footer>
      </div>
      <!-- Content -->
    </main>
    <!-- End Main -->
  </div>
</template>

<script>
import Header from "../layouts/Header.vue";
import Navigation from "../layouts/Navigation";
import Footer from "../layouts/Footer.vue";
import { VueEditor } from "vue2-editor";
import iziToast from "izitoast";
import axios from "axios";

export default {
  name: "Events",
  components: {
    Header,
    Navigation,
    Footer,
    VueEditor,
  },
  data() {
    return {
      auth: {
        access_token: "",
      },
      row: {
        bgTitle: "",
        bgColor: "",
        bgHint: "",
        preview: "",
        image: "",
        background_image: "",
        background_preview: "",
        body1: "",
        body2: "",
        body3: "",
        body4: "",
        body5: "",
        body6: "",
        body7: "",
        body8: "",
        body9: "",
        body10: "",
        body11: "",
        body12: "",
        body13: "",
        body14: "",
        body15: "",
        body16: "",
        body17: "",
        body18: "",

        status: 0,
      },

      croppieImage: "",
      cropped: false,

      pgLoading: true,
      btnLoading: false,
    };
  },
  mounted() {},
  created() {
    // AccessToken & Role
    if (localStorage.getItem("access_token")) {
      this.auth.access_token = localStorage.getItem("access_token");
    }

    this.fetchRow();
  },
  methods: {
    // fetchRow
    fetchRow() {
      this.pgLoading = true;
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.access_token,
      };
      const options = {
        url: window.baseURL + "/events/1",
        method: "GET",
        data: {},
        params: {},
      };
      this.axios(options)
        .then((res) => {
          this.pgLoading = false;
          this.row.preview = res.data.row.image;
          this.row.image = res.data.row.image;

          this.row.background_preview = res.data.row.background;
          this.row.background_image = res.data.row.background;

          this.row.bgTitle = res.data.row.bgTitle;
          this.row.bgColor = res.data.row.bgColor;
          this.row.bgHint = res.data.row.bgHint;

          this.row.body1 = res.data.row.body1;
          this.row.body2 = res.data.row.body2;
          this.row.body3 = res.data.row.body3;
          this.row.body4 = res.data.row.body4;
          this.row.body5 = res.data.row.body5;
          this.row.body6 = res.data.row.body6;
          this.row.body7 = res.data.row.body7;
          this.row.body8 = res.data.row.body8;
          this.row.body9 = res.data.row.body9;
          this.row.body10 = res.data.row.body10;
          this.row.body11 = res.data.row.body11;
          this.row.body12 = res.data.row.body12;
          this.row.body13 = res.data.row.body13;
          this.row.body14 = res.data.row.body14;
          this.row.body15 = res.data.row.body15;
          this.row.body16 = res.data.row.body16;
          this.row.body17 = res.data.row.body17;
          this.row.body18 = res.data.row.body18;

          this.row.status = res.data.active.status;
        })
        .catch(() => {})
        .finally(() => {});
    },

    // editRow
    editRow() {
      this.btnLoading = true;
      axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.access_token,
      };
      const config = { headers: { "Content-Type": "multipart/form-data" } };
      const options = {
        url: window.baseURL + "/events/1",
        method: "PUT",
        data: {
          image: this.row.image,
          background_image: this.row.background_image,

          bgTitle: this.row.bgTitle,
          bgColor: this.row.bgColor,
          bgHint: this.row.bgHint,

          body1: this.row.body1,
          body2: this.row.body2,
          body3: this.row.body3,
          body4: this.row.body4,
          body5: this.row.body5,
          body6: this.row.body6,
          body7: this.row.body7,
          body8: this.row.body8,
          body9: this.row.body9,
          body10: this.row.body10,
          body11: this.row.body11,
          body12: this.row.body12,
          body13: this.row.body13,
          body14: this.row.body14,
          body15: this.row.body15,
          body16: this.row.body16,
          body17: this.row.body17,
          body18: this.row.body18,
        },
      };
      axios(options, config)
        .then(() => {
          this.btnLoading = false;
          iziToast.success({
            icon: "ti-check",
            title: "Great job,",
            message: "Events Updated Successfully.",
          });
          //this.$router.push({ name: 'home' })
        })
        .catch((err) => {
          this.btnLoading = false;
          iziToast.warning({
            icon: "ti-alert",
            title: "Wow-man,",
            message: err.response ? err.response.data.message : "" + err,
          });
        })
        .finally(() => {});
    },

    // toggleCollapse
    collapseToggle(div) {
      let el = document.querySelector("span#iconToggle" + div);
      if (el.classList.contains("ti-angle-down")) {
        el.classList.remove("ti-angle-down");
        el.classList.add("ti-angle-up");
      } else {
        el.classList.remove("ti-angle-up");
        el.classList.add("ti-angle-down");
      }
    },

    onImageChange(e) {
      const file = e.target.files[0];
      this.row.preview = URL.createObjectURL(file);
      this.createBase64Image(file);
    },
    createBase64Image(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image = e.target.result;
      };
    },

    onBgImageChange(e) {
      const file = e.target.files[0];
      this.row.background_preview = URL.createObjectURL(file);
      this.createBgBase64Image(file);
    },

    createBgBase64Image(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.background_image = e.target.result;
      };
    },

    // Cancel
    cancel() {
      if (confirm("Are You Sure?")) {
        this.$router.push({ name: "dashboard" });
      }
    },

    onStatus() {
      if (this.row.status) {
        this.row.status = 0;

        this.axios.defaults.headers.common = {
          "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
          Authorization: `Bearer ` + this.auth.access_token,
        };
        const options = {
          url: window.baseURL + "/settings/inactive/20",
          method: "POST",
          data: {},
        };
        this.axios(options)
          .then(() => {})
          .catch(() => {})
          .finally(() => {});
      } else {
        this.row.status = 1;

        this.axios.defaults.headers.common = {
          "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
          Authorization: `Bearer ` + this.auth.access_token,
        };
        const options = {
          url: window.baseURL + "/settings/active/20",
          method: "POST",
          data: {},
        };
        this.axios(options)
          .then(() => {})
          .catch(() => {})
          .finally(() => {});
      }
    },
  },
};
</script>
