<template>
    <div class="">
        <Header></Header>

        <main class="u-main">
            <Navigation></Navigation>

            <div class="u-content">
                <div class="u-body min-h-700">
                    <div class="row">

                        <!-- Check Update -->
                        <!-- <div class="col-12 mb-3">
                            <button type="button"
                                @click="checkUpdate" 
                                class="pull-right btn btn-primary btn-sm btn-pill ui-mt-10 ui-mb-2 btn-with-icon">
                                <span v-if="!updateLoading" class="btn-icon ti-download mr-2"></span>
                                <span v-if="updateLoading">
                                    <div class="spinner-grow spinner-grow-sm mr-1" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </span>
                                <span v-html="(updateLoading) 
                                    ? ' Checking for updates...' 
                                    : ' No new updates available!' "></span>
                            </button>
                        </div> -->
                        <!-- End Check Update -->

                        

                        <!-- Total Training Applications -->
                        <div class="col-sm-6 col-xl-3 mb-5">
                            <div class="card">
                                <div class="card-body">

                                    <div class="dropdown show">
                                        <span class="ti-more pull-right cursor-pointer" 
                                            id="dropMenuPosts" 
                                            data-toggle="dropdown" 
                                            aria-haspopup="true" 
                                            aria-expanded="false"></span>
                                        <div class="dropdown-menu" aria-labelledby="dropMenuPosts">
                                            <span class="dropdown-item " 
                                                :class="(training_days == '0') ? 'active' : ''"
                                                @click="fetchTotalTrainings(0)">Today</span>
                                            <span class="dropdown-item "
                                                :class="(training_days == '1') ? 'active' : ''"
                                                @click="fetchTotalTrainings(1)">Yesterday</span>
                                            <span class="dropdown-item "
                                                :class="(training_days == '7') ? 'active' : ''"
                                                @click="fetchTotalTrainings(7)">Last 7 days</span>
                                            <span class="dropdown-item "
                                                :class="(training_days == '28') ? 'active' : ''"
                                                @click="fetchTotalTrainings(28)">Last 28 days</span>
                                            <span class="dropdown-item "
                                                :class="(training_days == '90') ? 'active' : ''"
                                                @click="fetchTotalTrainings(90)">Last 90 days</span>
                                            <span class="dropdown-item "
                                                :class="(training_days == '180') ? 'active' : ''"
                                                @click="fetchTotalTrainings(180)">Last 180 days</span>
                                            <span class="dropdown-item "
                                                :class="(training_days == 'infinity') ? 'active' : ''"
                                                @click="fetchTotalTrainings('infinity')">Last calendar year</span>
                                        </div>
                                    </div>

                                    <div class="media align-items-center py-2">
                                        <div class="media-body">
                                            <h5 class="h5 text-muted mb-2">Training 
                                                <p><span class="f11" 
                                                    style="margin-left:5px">&nbsp;&nbsp;Applications</span>
                                                </p>
                                            </h5>
                                            <span v-if="trainingLoading">
                                                <div class="spinner-grow spinner-grow-sm mr-1" role="status">
                                                      <span class="sr-only">Loading...</span>
                                                </div>
                                            </span>
                                            <span v-if="!trainingLoading" 
                                                class="h2 font-weight-normal mb-0">{{ total_training }}
                                            </span>
                                        </div>
                                        <div v-if="!trainingLoading" class="text-right ml-2" style="max-width: 70px">
                                            <div class="mb-2"></div>
                                            <span class="text-success">{{ percentage_training }}
                                                <span :class="arrow_training"></span>
                                            </span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <!-- End Total Training Applications -->


                        <!-- Total Member Applications -->
                        <div class="col-sm-6 col-xl-3 mb-5">
                            <div class="card">
                                <div class="card-body">

                                    <div class="dropdown show">
                                        <span class="ti-more pull-right cursor-pointer" 
                                            id="dropMenuPosts" 
                                            data-toggle="dropdown" 
                                            aria-haspopup="true" 
                                            aria-expanded="false"></span>
                                        <div class="dropdown-menu" aria-labelledby="dropMenuPosts">
                                            <span class="dropdown-item " 
                                                :class="(member_days == '0') ? 'active' : ''"
                                                @click="fetchTotalMembers(0)">Today</span>
                                            <span class="dropdown-item "
                                                :class="(member_days == '1') ? 'active' : ''"
                                                @click="fetchTotalMembers(1)">Yesterday</span>
                                            <span class="dropdown-item "
                                                :class="(member_days == '7') ? 'active' : ''"
                                                @click="fetchTotalMembers(7)">Last 7 days</span>
                                            <span class="dropdown-item "
                                                :class="(member_days == '28') ? 'active' : ''"
                                                @click="fetchTotalMembers(28)">Last 28 days</span>
                                            <span class="dropdown-item "
                                                :class="(member_days == '90') ? 'active' : ''"
                                                @click="fetchTotalMembers(90)">Last 90 days</span>
                                            <span class="dropdown-item "
                                                :class="(member_days == '180') ? 'active' : ''"
                                                @click="fetchTotalMembers(180)">Last 180 days</span>
                                            <span class="dropdown-item "
                                                :class="(member_days == 'infinity') ? 'active' : ''"
                                                @click="fetchTotalMembers('infinity')">Last calendar year</span>
                                        </div>
                                    </div>

                                    <div class="media align-items-center py-2">
                                        <div class="media-body">
                                            <h5 class="h5 text-muted mb-2">Membership 
                                                <p><span class="f11" 
                                                    style="margin-left:5px">&nbsp;&nbsp;Applications</span>
                                                </p>
                                            </h5>
                                            <span v-if="memberLoading">
                                                <div class="spinner-grow spinner-grow-sm mr-1" role="status">
                                                      <span class="sr-only">Loading...</span>
                                                </div>
                                            </span>
                                            <span v-if="!memberLoading" 
                                                class="h2 font-weight-normal mb-0">{{ total_member }}
                                            </span>
                                        </div>
                                        <div v-if="!memberLoading" class="text-right ml-2" 
                                            style="max-width: 70px">
                                            <div class="mb-2"></div>
                                            <span class="text-success">{{ percentage_member }}
                                                <span :class="arrow_member"></span>
                                            </span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <!-- End Total Member Appliation -->

                        <!-- Total Iam instructor Applications -->
                        <div class="col-sm-6 col-xl-3 mb-5">
                            <div class="card">
                                <div class="card-body">

                                    <div class="dropdown show">
                                        <span class="ti-more pull-right cursor-pointer" 
                                            id="dropMenuPosts" 
                                            data-toggle="dropdown" 
                                            aria-haspopup="true" 
                                            aria-expanded="false"></span>
                                        <div class="dropdown-menu" aria-labelledby="dropMenuPosts">
                                            <span class="dropdown-item " 
                                                :class="(instructor_days == '0') ? 'active' : ''"
                                                @click="fetchTotalInstructor(0)">Today</span>
                                            <span class="dropdown-item "
                                                :class="(instructor_days == '1') ? 'active' : ''"
                                                @click="fetchTotalInstructor(1)">Yesterday</span>
                                            <span class="dropdown-item "
                                                :class="(instructor_days == '7') ? 'active' : ''"
                                                @click="fetchTotalInstructor(7)">Last 7 days</span>
                                            <span class="dropdown-item "
                                                :class="(instructor_days == '28') ? 'active' : ''"
                                                @click="fetchTotalInstructor(28)">Last 28 days</span>
                                            <span class="dropdown-item "
                                                :class="(instructor_days == '90') ? 'active' : ''"
                                                @click="fetchTotalInstructor(90)">Last 90 days</span>
                                            <span class="dropdown-item "
                                                :class="(instructor_days == '180') ? 'active' : ''"
                                                @click="fetchTotalInstructor(180)">Last 180 days</span>
                                            <span class="dropdown-item "
                                                :class="(instructor_days == 'infinity') ? 'active' : ''"
                                                @click="fetchTotalInstructor('infinity')">Last calendar year</span>
                                        </div>
                                    </div>

                                    <div class="media align-items-center py-2">
                                        <div class="media-body">
                                            <h5 class="h5 text-muted mb-2">Iam Instructor 
                                                <p><span class="f11" 
                                                    style="margin-left:5px">&nbsp;&nbsp;Applications</span>
                                                </p>
                                            </h5>
                                            <span v-if="instructorLoading">
                                                <div class="spinner-grow spinner-grow-sm mr-1" role="status">
                                                      <span class="sr-only">Loading...</span>
                                                </div>
                                            </span>
                                            <span v-if="!instructorLoading" 
                                                class="h2 font-weight-normal mb-0">{{ total_instructor }}
                                            </span>
                                        </div>
                                        <div v-if="!instructorLoading" class="text-right ml-2" 
                                            style="max-width: 70px">
                                            <div class="mb-2"></div>
                                            <span class="text-success">{{ percentage_instructor }}
                                                <span :class="arrow_instructor"></span>
                                            </span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <!-- End Total Iam instructor Appliation -->

                        <!-- Total I've Experience Applications -->
                        <div class="col-sm-6 col-xl-3 mb-5">
                            <div class="card">
                                <div class="card-body">

                                    <div class="dropdown show">
                                        <span class="ti-more pull-right cursor-pointer" 
                                            id="dropMenuPosts" 
                                            data-toggle="dropdown" 
                                            aria-haspopup="true" 
                                            aria-expanded="false"></span>
                                        <div class="dropdown-menu" aria-labelledby="dropMenuPosts">
                                            <span class="dropdown-item " 
                                                :class="(experience_days == '0') ? 'active' : ''"
                                                @click="fetchTotalExperience(0)">Today</span>
                                            <span class="dropdown-item "
                                                :class="(experience_days == '1') ? 'active' : ''"
                                                @click="fetchTotalExperience(1)">Yesterday</span>
                                            <span class="dropdown-item "
                                                :class="(experience_days == '7') ? 'active' : ''"
                                                @click="fetchTotalExperience(7)">Last 7 days</span>
                                            <span class="dropdown-item "
                                                :class="(experience_days == '28') ? 'active' : ''"
                                                @click="fetchTotalExperience(28)">Last 28 days</span>
                                            <span class="dropdown-item "
                                                :class="(experience_days == '90') ? 'active' : ''"
                                                @click="fetchTotalExperience(90)">Last 90 days</span>
                                            <span class="dropdown-item "
                                                :class="(experience_days == '180') ? 'active' : ''"
                                                @click="fetchTotalExperience(180)">Last 180 days</span>
                                            <span class="dropdown-item "
                                                :class="(experience_days == 'infinity') ? 'active' : ''"
                                                @click="fetchTotalExperience('infinity')">Last calendar year</span>
                                        </div>
                                    </div>

                                    <div class="media align-items-center py-2">
                                        <div class="media-body">
                                            <h5 class="h5 text-muted mb-2">I've Experience 
                                                <p><span class="f11" 
                                                    style="margin-left:5px">&nbsp;&nbsp;Applications</span>
                                                </p>
                                            </h5>
                                            <span v-if="experienceLoading">
                                                <div class="spinner-grow spinner-grow-sm mr-1" role="status">
                                                      <span class="sr-only">Loading...</span>
                                                </div>
                                            </span>
                                            <span v-if="!experienceLoading" 
                                                class="h2 font-weight-normal mb-0">{{ total_experience }}
                                            </span>
                                        </div>
                                        <div v-if="!experienceLoading" class="text-right ml-2" 
                                            style="max-width: 70px">
                                            <div class="mb-2"></div>
                                            <span class="text-success">{{ percentage_experience }}
                                                <span :class="arrow_experience"></span>
                                            </span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <!-- End Total I've Experience Appliation -->

                        <!-- Total Visitors -->
                        <div class="col-sm-6 col-xl-3 mb-5">
                            <div class="card">
                                <div class="card-body" style="height: 150px">

                                    <div class="dropdown show">
                                        <span class="ti-more pull-right cursor-pointer" 
                                            id="dropMenuPosts" 
                                            data-toggle="dropdown" 
                                            aria-haspopup="true" 
                                            aria-expanded="false"></span>
                                        <div class="dropdown-menu" aria-labelledby="dropMenuPosts">
                                            <span class="dropdown-item " 
                                                :class="(visit_days == '0') ? 'active' : ''"
                                                @click="fetchTotalVisitors(0)">Today</span>
                                            <span class="dropdown-item "
                                                :class="(visit_days == '1') ? 'active' : ''"
                                                @click="fetchTotalVisitors(1)">Yesterday</span>
                                            <span class="dropdown-item "
                                                :class="(visit_days == '7') ? 'active' : ''"
                                                @click="fetchTotalVisitors(7)">Last 7 days</span>
                                            <span class="dropdown-item "
                                                :class="(visit_days == '28') ? 'active' : ''"
                                                @click="fetchTotalVisitors(28)">Last 28 days</span>
                                            <span class="dropdown-item "
                                                :class="(visit_days == '90') ? 'active' : ''"
                                                @click="fetchTotalVisitors(90)">Last 90 days</span>
                                            <span class="dropdown-item "
                                                :class="(visit_days == '180') ? 'active' : ''"
                                                @click="fetchTotalVisitors(180)">Last 180 days</span>
                                            <span class="dropdown-item "
                                                :class="(visit_days == 'infinity') ? 'active' : ''"
                                                @click="fetchTotalVisitors('infinity')">Last calendar year</span>
                                        </div>
                                    </div>

                                    <div class="media align-items-center py-2">
                                        <div class="media-body">
                                            <h5 class="h5 text-muted mb-2">Total Visitors</h5>
                                            <span v-if="visitLoading">
                                                <div class="spinner-grow spinner-grow-sm mr-1" role="status">
                                                      <span class="sr-only">Loading...</span>
                                                </div>
                                            </span>
                                            <span v-if="!visitLoading" 
                                                class="h2 font-weight-normal mb-0">{{ total_visit }}
                                            </span>
                                        </div>
                                        <div v-if="!visitLoading" 
                                                class="text-right ml-2" 
                                                style="max-width: 70px">
                                            <div class="mb-2"></div>
                                            <span class="text-success">{{ percentage_visit }}
                                                <span :class="arrow_visit"></span>
                                            </span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <!-- End Total Visitors -->


                        <!-- Total Users -->
                        <div class="col-sm-6 col-xl-3 mb-5">
                            <div class="card">
                                <div class="card-body" style="height: 150px">

                                    <div class="dropdown show">
                                        <span class="ti-more pull-right cursor-pointer" 
                                            id="dropMenuPosts" 
                                            data-toggle="dropdown" 
                                            aria-haspopup="true" 
                                            aria-expanded="false"></span>
                                        <div class="dropdown-menu" aria-labelledby="dropMenuPosts">
                                            <span class="dropdown-item " 
                                                :class="(user_days == '0') ? 'active' : ''"
                                                @click="fetchTotalUsers(0)">Today</span>
                                            <span class="dropdown-item "
                                                :class="(user_days == '1') ? 'active' : ''"
                                                @click="fetchTotalUsers(1)">Yesterday</span>
                                            <span class="dropdown-item "
                                                :class="(user_days == '7') ? 'active' : ''"
                                                @click="fetchTotalUsers(7)">Last 7 days</span>
                                            <span class="dropdown-item "
                                                :class="(user_days == '28') ? 'active' : ''"
                                                @click="fetchTotalUsers(28)">Last 28 days</span>
                                            <span class="dropdown-item "
                                                :class="(user_days == '90') ? 'active' : ''"
                                                @click="fetchTotalUsers(90)">Last 90 days</span>
                                            <span class="dropdown-item "
                                                :class="(user_days == '180') ? 'active' : ''"
                                                @click="fetchTotalUsers(180)">Last 180 days</span>
                                            <span class="dropdown-item "
                                                :class="(user_days == 'infinity') ? 'active' : ''"
                                                @click="fetchTotalUsers('infinity')">Last calendar year</span>
                                        </div>
                                    </div>

                                    <div class="media align-items-center py-2">
                                        <div class="media-body">
                                            <h5 class="h5 text-muted mb-2">Total Users</h5>
                                            <span v-if="userLoading">
                                                <div class="spinner-grow spinner-grow-sm mr-1" role="status">
                                                      <span class="sr-only">Loading...</span>
                                                </div>
                                            </span>
                                            <span v-if="!userLoading" 
                                                class="h2 font-weight-normal mb-0">{{ total_user }}
                                            </span>
                                        </div>
                                        <div v-if="!userLoading" class="text-right ml-2" style="max-width: 70px">
                                            <div class="mb-2"></div>
                                            <span class="text-success">{{ percentage_user }}
                                                <span :class="arrow_user"></span>
                                            </span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <!-- End Total Users -->


                        <!-- Total Accreditation Applications -->
                        <div class="col-sm-6 col-xl-3 mb-5">
                            <div class="card">
                                <div class="card-body">

                                    <div class="dropdown show">
                                        <span class="ti-more pull-right cursor-pointer" 
                                            id="dropMenuPosts" 
                                            data-toggle="dropdown" 
                                            aria-haspopup="true" 
                                            aria-expanded="false"></span>
                                        <div class="dropdown-menu" aria-labelledby="dropMenuPosts">
                                            <span class="dropdown-item " 
                                                :class="(accreditation_days == '0') ? 'active' : ''"
                                                @click="fetchTotalAccreditation(0)">Today</span>
                                            <span class="dropdown-item "
                                                :class="(accreditation_days == '1') ? 'active' : ''"
                                                @click="fetchTotalAccreditation(1)">Yesterday</span>
                                            <span class="dropdown-item "
                                                :class="(accreditation_days == '7') ? 'active' : ''"
                                                @click="fetchTotalAccreditation(7)">Last 7 days</span>
                                            <span class="dropdown-item "
                                                :class="(accreditation_days == '28') ? 'active' : ''"
                                                @click="fetchTotalAccreditation(28)">Last 28 days</span>
                                            <span class="dropdown-item "
                                                :class="(accreditation_days == '90') ? 'active' : ''"
                                                @click="fetchTotalAccreditation(90)">Last 90 days</span>
                                            <span class="dropdown-item "
                                                :class="(accreditation_days == '180') ? 'active' : ''"
                                                @click="fetchTotalAccreditation(180)">Last 180 days</span>
                                            <span class="dropdown-item "
                                                :class="(accreditation_days == 'infinity') ? 'active' : ''"
                                                @click="fetchTotalAccreditation('infinity')">Last calendar year</span>
                                        </div>
                                    </div>

                                    <div class="media align-items-center py-2">
                                        <div class="media-body">
                                            <h5 class="h5 text-muted mb-2">Accreditation 
                                                <p><span class="f11" 
                                                    style="margin-left:5px">&nbsp;&nbsp;Applications</span>
                                                </p>
                                            </h5>
                                            <span v-if="accreditationLoading">
                                                <div class="spinner-grow spinner-grow-sm mr-1" role="status">
                                                      <span class="sr-only">Loading...</span>
                                                </div>
                                            </span>
                                            <span v-if="!accreditationLoading" 
                                                class="h2 font-weight-normal mb-0">{{ total_accreditation }}
                                            </span>
                                        </div>
                                        <div v-if="!accreditationLoading" class="text-right ml-2" style="max-width: 70px">
                                            <div class="mb-2"></div>
                                            <span class="text-success">{{ percentage_accreditation }}
                                                <span :class="arrow_accreditation"></span>
                                            </span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <!-- End Total Accreditation Applications -->

                        <!-- Total Certificate Applications -->
                        <div class="col-sm-6 col-xl-3 mb-5">
                            <div class="card">
                                <div class="card-body">

                                    <div class="dropdown show">
                                        <span class="ti-more pull-right cursor-pointer" 
                                            id="dropMenuPosts" 
                                            data-toggle="dropdown" 
                                            aria-haspopup="true" 
                                            aria-expanded="false"></span>
                                        <div class="dropdown-menu" aria-labelledby="dropMenuPosts">
                                            <span class="dropdown-item " 
                                                :class="(certificate_days == '0') ? 'active' : ''"
                                                @click="fetchTotalCertificate(0)">Today</span>
                                            <span class="dropdown-item "
                                                :class="(certificate_days == '1') ? 'active' : ''"
                                                @click="fetchTotalCertificate(1)">Yesterday</span>
                                            <span class="dropdown-item "
                                                :class="(certificate_days == '7') ? 'active' : ''"
                                                @click="fetchTotalCertificate(7)">Last 7 days</span>
                                            <span class="dropdown-item "
                                                :class="(certificate_days == '28') ? 'active' : ''"
                                                @click="fetchTotalCertificate(28)">Last 28 days</span>
                                            <span class="dropdown-item "
                                                :class="(certificate_days == '90') ? 'active' : ''"
                                                @click="fetchTotalCertificate(90)">Last 90 days</span>
                                            <span class="dropdown-item "
                                                :class="(certificate_days == '180') ? 'active' : ''"
                                                @click="fetchTotalCertificate(180)">Last 180 days</span>
                                            <span class="dropdown-item "
                                                :class="(certificate_days == 'infinity') ? 'active' : ''"
                                                @click="fetchTotalCertificate('infinity')">Last calendar year</span>
                                        </div>
                                    </div>

                                    <div class="media align-items-center py-2">
                                        <div class="media-body">
                                            <h5 class="h5 text-muted mb-2">Certificate 
                                                <p><span class="f11" 
                                                    style="margin-left:5px">&nbsp;&nbsp;Applications</span>
                                                </p>
                                            </h5>
                                            <span v-if="certificateLoading">
                                                <div class="spinner-grow spinner-grow-sm mr-1" role="status">
                                                      <span class="sr-only">Loading...</span>
                                                </div>
                                            </span>
                                            <span v-if="!certificateLoading" 
                                                class="h2 font-weight-normal mb-0">{{ total_certificate }}
                                            </span>
                                        </div>
                                        <div v-if="!certificateLoading" class="text-right ml-2" style="max-width: 70px">
                                            <div class="mb-2"></div>
                                            <span class="text-success">{{ percentage_certificate }}
                                                <span :class="arrow_certificate"></span>
                                            </span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <!-- End Total Certificate Applications -->
                        
                    </div>






                    <div class="row">

                        <div class="col-md-8 mb-5">
                            <div class="card ui-h-480">
                                <header class="card-header 
                                        d-flex align-items-center justify-content-between">
                                    <h2 class="h4 card-header-title">Visitors During the year</h2>
                                    
                                    <!-- Dropdown -->
                                    <span class="ti-more pull-right cursor-pointer"
                                            id="dropMenuCharts" 
                                            data-toggle="dropdown" 
                                            aria-haspopup="true" 
                                            aria-expanded="false"></span>
                                    
                                     <div class="dropdown-menu" aria-labelledby="dropMenuCharts">
                                        <span class="dropdown-item " 
                                                :class="(line_type == 'weekly') ? 'active' : ''"
                                                @click="fetchLineChart('weekly')">Weekly
                                        </span>
                                        <span class="dropdown-item "
                                                :class="(line_type == 'monthly') ? 'active' : ''"
                                                @click="fetchLineChart('monthly')">Monthly
                                        </span>
                                        <span class="dropdown-item "
                                                :class="(line_type == 'quarter') ? 'active' : ''"
                                                @click="fetchLineChart('quarter')">Quarter
                                        </span>
                                        <span class="dropdown-item "
                                                :class="(line_type == 'yearly') ? 'active' : ''"
                                                @click="fetchLineChart('yearly')">Yearly
                                        </span>
                                    </div>
                                    <!-- End Dropdown -->

                                </header>

                                <div class="card-body pt-0 text-center">
                                    <span v-if="chartLoading">
                                        <div class="spinner-grow spinner-grow-md mr-1 ui-mt150" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    </span>
                                    <apexchart v-if="!chartLoading" 
                                                class="mt-md-3 mt-xl-0"
                                                type=area 
                                                :options="chartOptions1" 
                                                :series="series1"  
                                                height="380" />
                                </div>
                            </div>
                        </div>




                        <div class="col-md-4 mb-5">
                            <div class="card ui-h-300">
                                <header class="card-header  
                                    d-flex align-items-center justify-content-between">

                                    <h2 class="h4 card-header-title">Where your visitors located</h2>

                                    <!-- Dropdown -->
                                    <a class="link-muted d-flex pull-right" 
                                            role="button" 
                                            id="dropMenuCountries" 
                                            aria-haspopup="true" 
                                            aria-expanded="false"
                                            data-toggle="dropdown">
                                            <span class="ti-more pull-right cursor-pointer"></span>
                                   </a>
                                    
                                    <div class="u-header-dropdown__menu dropdown-menu dropdown-menu-right" 
                                            aria-labelledby="dropMenuCountries">
                                        <span class="dropdown-item " 
                                                :class="(pie_days == '0') ? 'active' : ''"
                                                @click="fetchCountries(0)">Today
                                        </span>
                                        <span class="dropdown-item "
                                                :class="(pie_days == '1') ? 'active' : ''"
                                                @click="fetchCountries(1)">Yesterday
                                        </span>
                                        <span class="dropdown-item "
                                                :class="(pie_days == '7') ? 'active' : ''"
                                                @click="fetchCountries(7)">Last 7 days
                                        </span>
                                        <span class="dropdown-item "
                                                :class="(pie_days == '28') ? 'active' : ''"
                                                @click="fetchCountries(28)">Last 28 days
                                        </span>
                                        <span class="dropdown-item "
                                                :class="(pie_days == '90') ? 'active' : ''"
                                                @click="fetchCountries(90)">Last 90 days
                                        </span>
                                        <span class="dropdown-item "
                                                :class="(pie_days == '180') ? 'active' : ''"
                                                @click="fetchCountries(180)">Last 180 days
                                        </span>
                                        <span class="dropdown-item "
                                                :class="(pie_days == 'infinity') ? 'active' : ''"
                                                @click="fetchCountries('infinity')">Last calendar year
                                        </span>
                                    </div>
                                    <!-- End Dropdown -->

                                </header>

                                <div class="card-body text-center">
                                    <span v-if="pieLoading">
                                        <div class="spinner-grow spinner-grow-md ui-mt50 mr-1" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>    
                                    </span>
                                    <apexchart v-if="!pieLoading" 
                                                class="mt-md-3 mt-xl-0"
                                                type=pie 
                                                :options="chartOptions2" 
                                                :series="series2"  
                                                height="190" />
                                </div>
                            </div>
                        </div>



                        <div class="col-md-8 mb-5">
                            <div class="card">
                                <header class="card-header d-flex align-items-center justify-content-between">
                                    <h2 class="h4 card-header-title">Recent Programs</h2>
                                    <span v-if="!dataLoading" class="ti-reload cursor-pointer" 
                                        @click="fetchTrendingProgram()">
                                    </span>
                                    <span v-if="dataLoading">
                                        <div class="spinner-grow spinner-grow-sm" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    </span>
                                </header>

                                <div class="card-body">

                                    <div v-if="dataLoading" class="text-center">
                                        <div class="spinner-grow" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    </div>

                                    <div v-if="!dataLoading" class="table-responsive">
                                        <table class="table table-hover mb-0">
                                            <thead>
                                                <tr>
                                                    <th style="width:30%">Title</th>
                                                    <th class="text-center">Sector</th>
                                                    <th class="text-center">Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                            <tr v-if="!dataLoading && !rows.length">
                                                <td colspan="4" class="text-center">
                                                    <span>No data found.</span>
                                                </td>
                                            </tr>

                                            <!-- v-if="!dataLoading  && rows.length"  -->
                                            <tr 
                                                v-for="(row, index) in rows" 
                                                :key="index"
                                                class="f14">

                                                <!-- Title -->
                                                <td class="font-weight-semi-bold">
                                                <router-link 
                                                    :to="{ name:'edit-products',
                                                    params:{sector_id: row.sector_id, 
                                                            id:row.encrypt_id}}" 
                                                    class="default-color text-decoration-hover"
                                                    v-html="row.title">
                                                </router-link>
                                                </td>
                                                <!-- End Title -->

                                                <!-- Category -->
                                                <td class="font-weight-semi-bold text-center">
                                                    <span class="badge badge-md badge-pill badge-danger-soft"
                                                        v-html="row.sector">
                                                    </span>
                                                </td>
                                                <!-- End Category -->

                                                <!-- Date -->
                                                <td v-html="(row.deleted_at) ? row.deleted_at : 
                                                    (row.updated_at) ? row.updated_at : row.created_at"
                                                    class="font-weight-semi-bold text-center f12">
                                                </td>
                                                <!-- End Date -->

                                            </tr>
                                            
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <th>Title</th>
                                                    <th class="text-center">Sector</th>
                                                    <th class="text-center">Date</th>
                                                </tr>
                                            </tfoot>

                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-4 mb-5 ui-mt-180">
                            <div class="card">
                                <header class="card-header d-flex align-items-center justify-content-between">
                                    <h2 class="h4 card-header-title">Top Sectors</h2>
                                    <span v-if="!catLoading" class="ti-reload cursor-pointer" 
                                        @click="fetchTopSectors()">
                                    </span>
                                    <span v-if="catLoading">
                                        <div class="spinner-grow spinner-grow-sm" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    </span>
                                </header>

                                <div class="card-body text-center">
                                    <span v-if="catLoading">
                                        <div class="spinner-grow" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    </span>
                                    <apexchart class="mt-md-3 mt-xl-0"
                                                v-if="!catLoading"
                                                type=bar 
                                                :options="chartOptions3" 
                                                :series="series3"  
                                                height="300" />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>




                <Footer></Footer>
            </div>
        </main>
        <!-- End Main -->
        
    </div>
</template>

<script>
    import Header from '../layouts/Header.vue';
    import Navigation from '../layouts/Navigation';
    import Footer from '../layouts/Footer.vue';
    import iziToast from 'izitoast';

    export default {
        name: 'App',
        components: {
            Header,
            Navigation,
            Footer,
        },
        data(){
            return {
                // Chart
                line_type: 'monthly',
                chartOptions1: {
                    chart: { height: 450, zoom: { enabled: false }},
                    animations: { enabled: true },
                    dataLabels: {  enabled: false },
                    stroke: {  curve: 'straight' },
                    title: { text: '', align: 'center' },
                    grid: {  row: { colors: ['#f3f3f3', 'transparent'],  opacity: 0.5 }},
                    markers: { size: 5, align:top,  hover: {  sizeOffset: 5  }},
                },

                // Pie
                pieLoading: true,
                pie_days: 7,
                series2: [],
                chartOptions2: {
                    chart: {
                      width: 380,
                      type: 'pie',
                    },
                    responsive: [{
                        breakpoint: 480,
                        options: {
                            chart: {
                              width: 200
                            },
                        legend: {
                            position: 'bottom'
                        }
                      }
                    }]
                },

              // Categories
              chartOptions3: {
                    chart: { height: 280, zoom: { enabled: false }},
                    animations: { enabled: true },
                    dataLabels: {  enabled: false },
                    stroke: {  curve: 'straight' },
                    title: { text: '', align: 'center' },
                    grid: {  row: { colors: ['#f3f3f3', 'transparent'],  opacity: 0.5 }},
                    markers: { size: 5, align:top,  hover: {  sizeOffset: 5  }},
                },
                series3: [],

                auth: {
                    role: '',
                    accesstoken: '',
                },
                mostVisited: [],


                // Total Visitors
                visit_days: 7,
                total_visit: 0,
                percentage_visit: '',
                arrow_visit: '',
                visitLoading: true,

                // Total Training
                training_days: 7,
                total_training: 0,
                percentage_training: '',
                arrow_training: '',
                trainingLoading: true,

                // Total Iam instructor
                instructor_days: 7,
                total_instructor: 0,
                percentage_instructor: '',
                arrow_instructor: '',
                instructorLoading: true,

                // Total i've experience
                experience_days: 7,
                total_experience: 0,
                percentage_experience: '',
                arrow_experience: '',
                experienceLoading: true,

                // Total Accreditation
                accreditation_days: 7,
                total_accreditation: 0,
                percentage_accreditation: '',
                arrow_accreditation: '',
                accreditationLoading: true,

                // Total Certificate
                certificate_days: 7,
                total_certificate: 0,
                percentage_certificate: '',
                arrow_certificate: '',
                certificateLoading: true,

                // Total Member
                member_days: 7,
                total_member: 0,
                percentage_member: '',
                arrow_member: '',
                memberLoading: true,

                // Total User
                user_days: 7,
                total_user: 0,
                percentage_user: '',
                arrow_user: '',
                userLoading: true,

                 // line Chart
                series1: [],
                chartLoading: true,

                // Posts Trend
                rows: [],
                dataLoading: true,

                // Categories
                catLoading: true,
                updateLoading: false,
            }
        },
        mounted() {},
        created() {
            // AccessToken & Roles
            if(localStorage.getItem('access_token')) {
                this.auth.accessToken = localStorage.getItem('access_token');
            }

            this.fetchTotalVisitors(this.visit_days, true);
        },
        methods: {

            // fetch Total Visitors
            fetchTotalVisitors(days, next=false){
                this.visitLoading = true;
                this.visit_days = days;
                this.axios.defaults.headers.common = {
                    'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
                    'Authorization': `Bearer ` + this.auth.access_token,
                };
                const config = { headers: { 'Content-Type': 'multipart/form-data' }};  
                const options = {
                    url: window.baseURL+'/explore/totalVisitors',
                    method: 'GET',
                    data: {},
                    params: {
                        days: this.visit_days
                    }
                }
                this.axios(options, config)
                    .then(res => {
                        this.visitLoading = false;
                        this.total_visit = res.data.total;
                        this.percentage_visit = res.data.percentage;
                        this.arrow_visit = res.data.arrow;

                        // Call Next Func
                        if(next) { this.fetchTotalTrainings(this.training_days, true); }
                    })
                    .catch(() => { });
            },


            // fetch Total Training
            fetchTotalTrainings(days, next=false){
                this.trainingLoading = true;
                this.training_days = days;
                this.axios.defaults.headers.common = {
                    'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
                    'Authorization': `Bearer ` + this.auth.access_token,
                };
                const config = { headers: { 'Content-Type': 'multipart/form-data' }};  
                const options = {
                    url: window.baseURL+'/explore/totalTrainings',
                    method: 'GET',
                    data: {},
                    params: {
                        days: this.training_days
                    }
                }
                this.axios(options, config)
                    .then(res => {
                        this.trainingLoading = false;
                        this.total_training = res.data.total;
                        this.percentage_training = res.data.percentage;
                        this.arrow_training = res.data.arrow;

                        // Call Next Func
                        if(next) { this.fetchTotalMembers(this.member_days, true); }
                    })
                    .catch(() => { });
            },


            // fetch Total Member
            fetchTotalMembers(days, next=false){
                this.memberLoading = true;
                this.member_days = days;
                this.axios.defaults.headers.common = {
                    'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
                    'Authorization': `Bearer ` + this.auth.access_token,
                };
                const config = { headers: { 'Content-Type': 'multipart/form-data' }};  
                const options = {
                    url: window.baseURL+'/explore/totalMembers',
                    method: 'GET',
                    data: {},
                    params: {
                        days: this.member_days
                    }
                }
                this.axios(options, config)
                    .then(res => {
                        this.memberLoading = false;
                        this.total_member = res.data.total;
                        this.percentage_member = res.data.percentage;
                        this.arrow_member = res.data.arrow;

                        // Call Next Func
                        if(next) { this.fetchTotalInstructor(this.instructor_days, true); }
                     })
                    .catch(() => { });
            },


            fetchTotalInstructor(days, next=false){
                this.instructorLoading = true;
                this.instructor_days = days;
                this.axios.defaults.headers.common = {
                    'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
                    'Authorization': `Bearer ` + this.auth.access_token,
                };
                const config = { headers: { 'Content-Type': 'multipart/form-data' }};  
                const options = {
                    url: window.baseURL+'/explore/totalInstructors',
                    method: 'GET',
                    data: {},
                    params: {
                        days: this.instructor_days
                    }
                }
                this.axios(options, config)
                    .then(res => {
                        this.instructorLoading = false;
                        this.total_instructor = res.data.total;
                        this.percentage_instructor = res.data.percentage;
                        this.arrow_instructor = res.data.arrow;

                        if(next) { this.fetchTotalExperience(this.experience_days, true); }
                     })
                    .catch(() => { });
            },

            fetchTotalExperience(days, next=false){
                this.experienceLoading = true;
                this.experience_days = days;
                this.axios.defaults.headers.common = {
                    'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
                    'Authorization': `Bearer ` + this.auth.access_token,
                };
                const config = { headers: { 'Content-Type': 'multipart/form-data' }};  
                const options = {
                    url: window.baseURL+'/explore/totalExperiences',
                    method: 'GET',
                    data: {},
                    params: {
                        days: this.experience_days
                    }
                }
                this.axios(options, config)
                    .then(res => {
                        this.experienceLoading = false;
                        this.total_experience = res.data.total;
                        this.percentage_experience = res.data.percentage;
                        this.arrow_experience = res.data.arrow;

                        if(next) { this.fetchTotalUsers(this.user_days, true); }
                     })
                    .catch(() => { });
            },


            // fetch Total Users
            fetchTotalUsers(days, next=false){
                this.userLoading = true;
                this.user_days = days;
                this.axios.defaults.headers.common = {
                    'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
                    'Authorization': `Bearer ` + this.auth.access_token,
                };
                const config = { headers: { 'Content-Type': 'multipart/form-data' }};  
                const options = {
                    url: window.baseURL+'/explore/totalUsers',
                    method: 'GET',
                    data: {},
                    params: {
                        days: this.user_days
                    }
                }
                this.axios(options, config)
                    .then(res => {
                        this.userLoading = false;
                        this.total_user = res.data.total;
                        this.percentage_user = res.data.percentage;
                        this.arrow_user = res.data.arrow;

                        // Call Next Func
                        if(next) { this.fetchTotalAccreditation(this.accreditation_days, true); }
                    })
                    .catch(() => { });
            },


            // fetch Total Accreditation
            fetchTotalAccreditation(days, next=false){
                this.accreditationLoading = true;
                this.accreditation_days = days;
                this.axios.defaults.headers.common = {
                    'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
                    'Authorization': `Bearer ` + this.auth.access_token,
                };
                const config = { headers: { 'Content-Type': 'multipart/form-data' }};  
                const options = {
                    url: window.baseURL+'/explore/totalAccreditations',
                    method: 'GET',
                    data: {},
                    params: {
                        days: this.accreditation_days
                    }
                }
                this.axios(options, config)
                    .then(res => {
                        this.accreditationLoading = false;
                        this.total_accreditation = res.data.total;
                        this.percentage_accreditation = res.data.percentage;
                        this.arrow_accreditation = res.data.arrow;

                        // Call Next Func
                        if(next) { this.fetchTotalCertificate(this.certificate_days, true); }
                    })
                    .catch(() => { });
            },

            // fetch Total Certificate
            fetchTotalCertificate(days, next=false){
                this.certificateLoading = true;
                this.certificate_days = days;
                this.axios.defaults.headers.common = {
                    'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
                    'Authorization': `Bearer ` + this.auth.access_token,
                };
                const config = { headers: { 'Content-Type': 'multipart/form-data' }};  
                const options = {
                    url: window.baseURL+'/explore/totalCertificates',
                    method: 'GET',
                    data: {},
                    params: {
                        days: this.certificate_days
                    }
                }
                this.axios(options, config)
                    .then(res => {
                        this.certificateLoading = false;
                        this.total_certificate = res.data.total;
                        this.percentage_certificate = res.data.percentage;
                        this.arrow_certificate = res.data.arrow;

                        // Call Next Func
                        if(next) { this.fetchLineChart(this.line_type, true); }
                    })
                    .catch(() => { });
            },


            // fetch Chart
            fetchLineChart(type, next=false){
                this.chartLoading = true;
                this.line_type = type;
                this.axios.defaults.headers.common = {
                    'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
                    'Authorization': `Bearer ` + this.auth.access_token,
                };
                const config = { headers: { 'Content-Type': 'multipart/form-data' }};  
                const options = {
                    url: window.baseURL+'/explore/lineChart',
                    method: 'GET',
                    data: {},
                    params: {
                        type: this.line_type
                    }
                }
                this.axios(options, config)
                    .then(res => {
                        this.chartLoading = false;
                        this.series1 = [{name:'Views', data: res.data.rows.series}];
                        this.chartOptions1.xaxis = {categories: res.data.rows.xaxis};

                        // Call Next Func
                        if(next) { this.fetchCountries(this.pie_days, true); }
                           
                    })
                    .catch(() => {});
            },

            // fetch Countries
            fetchCountries(days, next=false){
                this.pieLoading = true;
                this.pie_days = days;
                this.axios.defaults.headers.common = {
                    'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
                    'Authorization': `Bearer ` + this.auth.access_token,
                };
                const config = { headers: { 'Content-Type': 'multipart/form-data' }};  
                const options = {
                    url: window.baseURL+'/explore/pieChart',
                    method: 'GET',
                    data: {},
                    params: {
                        days: this.pie_days
                    }
                }
                this.axios(options, config)
                    .then(res => {
                        this.pieLoading = false;
                        this.series2 = res.data.rows.total;
                        this.chartOptions2.labels = res.data.rows.countries;

                        // Call Next Func
                        if(next) { 
                            this.fetchTrendingProgram(this.pie_days, true); 
                        }
                    })
                    .catch(() => { });
            },

            // Fetch Top Five Posts
            fetchTrendingProgram(next=false){
                this.dataLoading = true;
                this.axios.defaults.headers.common = {
                    'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
                    'Authorization': `Bearer ` + this.auth.access_token,
                };
                const config = { headers: { 'Content-Type': 'multipart/form-data' }};  
                const options = {
                    url: window.baseURL+'/explore/recentPrograms',
                    method: 'GET',
                    data: {},
                    params: {}
                }
                this.axios(options, config)
                    .then(res => {
                        this.dataLoading = false;
                        this.rows = res.data.rows;
                        // Call Next Func
                        if(next) { 
                            this.fetchTopSectors(); 
                        }
                    })
                    .catch(() => {});
            },


            // Top Destinations
            fetchTopSectors(){
                this.catLoading = true;
                this.axios.defaults.headers.common = {
                    'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
                    'Authorization': `Bearer ` + this.auth.access_token,
                };
                const config = { headers: { 'Content-Type': 'multipart/form-data' }};  
                const options = {
                    url: window.baseURL+'/explore/topSectors',
                    method: 'GET',
                    data: {},
                    params: {}
                }
                this.axios(options, config)
                    .then(res => {
                        this.catLoading = false;
                        this.chartOptions3 = { xaxis: { categories: res.data.xaxis}};
                        this.series3 = [{ name:'Sectors', data: res.data.series }];
                    })
                    .catch(() => { });
            },

                
            checkUpdate(){
                this.updateLoading = true;
                setTimeout(() => {
                    iziToast.warning({
                        icon: 'ti-alert',
                        title: 'Wow-man,',
                        message: "There's no new updates available!"
                    });
                    this.updateLoading = false;
                },3000);
            },

        },
    }
</script>
