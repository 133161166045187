<template>
  <div class="">
    <Header></Header>

    <!-- Main -->
    <main class="u-main">
      <Navigation></Navigation>

      <div class="u-content">
        <div class="u-body min-h-700">
          <h1 class="h2 mb-2">Our Partners (Shortcut)</h1>

          <!-- Breadcrumb -->
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <router-link :to="{ name: 'dashboard' }">Dashboard</router-link>
              </li>
              <li class="breadcrumb-item">
                <router-link
                  :to="{
                    name: 'ourpartners-sub',
                    params: { root_id: root_id },
                  }"
                  >Our Partners (Shortcut)</router-link
                >
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Add New
              </li>
            </ol>
          </nav>
          <!-- End Breadcrumb -->

          <!-- Loading -->
          <!-- <div v-if="pgLoading" class="row h-100">
                <div class="container text-center">
                    <div class="spinner-grow mt-5" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            </div> -->
          <!-- End Loading -->

          <form
            @submit.prevent="addNew"
            enctype="multipart/form-data"
            class="h-100"
          >
            <!-- Content -->
            <div class="tab-content">
              <div class="row">
                <!-- ******* Card ******** -->
                <div class="col-md-12 mb-5">
                  <!-- Card PageName -->
                  <div class="card">
                    <div class="card-body">
                      <div id="accordion" class="accordion">
                        <div id="TabOverview0" class="card-header">
                          <h2
                            class="h4 card-header-title"
                            @click="collapseToggle('Overview0')"
                            aria-expanded="false"
                            aria-controls="collapseOverview0"
                            data-toggle="collapse"
                            data-target="#collapseOverview0"
                          >
                            PageName
                            <span
                              id="iconToggleOverview0"
                              class="ti-angle-up u-sidebar-nav-menu__item-arrow pull-right black"
                            >
                            </span>
                          </h2>
                        </div>
                        <div
                          id="collapseOverview0"
                          class="collapse"
                          aria-labelledby="TabOverview0"
                          data-parent="#accordion"
                        >
                          <div class="col-12 pt-3">
                            <div class="form-group">
                              <label
                                >Background 1200 * 440
                                <button
                                  v-if="row.preview"
                                  type="button"
                                  class="btn btn-danger btn-sm btn-pill"
                                  style="margin-top: -10px; margin-left: 10px"
                                  @click="
                                    row.preview = '';
                                    row.image = '';
                                  "
                                >
                                  Remove Image
                                </button>
                              </label>
                              <p>
                                <img
                                  v-if="row.preview"
                                  :src="row.preview"
                                  style="max-width: 100%"
                                />
                              </p>
                              <input
                                type="file"
                                class="form-control"
                                v-on:change="onImageChange"
                              />
                            </div>

                            <div class="row col-12" style="padding: 0">
                              <!-- Title -->
                              <div class="form-group col-9">
                                <label for="input1">background Title</label>
                                <vue-editor
                                  id="editor01"
                                  v-model="row.bgTitle"
                                  :init="{
                                    height: 200,
                                  }"
                                />
                              </div>
                              <!-- End Title -->

                              <!-- Slug -->
                              <div class="form-group col-3">
                                <label for="input2">background Color</label>
                                <input
                                  class="form-control"
                                  id="input2"
                                  type="color"
                                  style="height: 200px"
                                  v-model="row.bgColor"
                                />
                              </div>
                              <!-- End Slug -->
                            </div>

                            <div class="row col-12" style="padding: 0">
                              <!-- Title -->
                              <div class="form-group col-12">
                                <label for="input1">Title</label>
                                <input
                                  class="form-control"
                                  id="input1"
                                  type="text"
                                  v-model="row.title"
                                  @keyup="onTitleChange"
                                />
                              </div>
                              <!-- End Title -->

                              <!-- Slug -->
                              <div class="form-group col-12">
                                <label for="input2">Slug</label>
                                <input
                                  class="form-control text-lowercase"
                                  id="input2"
                                  type="text"
                                  v-model="row.slug"
                                  @keydown.space.prevent
                                  @paste="onSlugPaste"
                                  @change="onSlugChange(false)"
                                />
                              </div>
                              <!-- End Slug -->

                              <!-- Sort -->
                              <div class="form-group col-12">
                                <label for="input3">Sort</label>
                                <input
                                  class="form-control"
                                  id="input3"
                                  min="0"
                                  type="number"
                                  v-model.number="row.sort"
                                />
                              </div>
                              <!-- End Sort -->
                            </div>

                            <h4><br />Status & Visibility</h4>
                            <div class="col-md-12">
                              <div class="form-group">
                                <div class="custom-control custom-switch mb-2">
                                  <input
                                    type="checkbox"
                                    class="custom-control-input"
                                    id="customSwitch1"
                                    :checked="row.status"
                                    @click="onStatus"
                                  />
                                  <label
                                    class="custom-control-label"
                                    for="customSwitch1"
                                    v-html="row.status ? 'Active' : 'Inactive'"
                                  >
                                  </label>
                                </div>
                              </div>

                              <div class="form-group">
                                <div class="custom-control custom-switch mb-2">
                                  <input
                                    type="checkbox"
                                    class="custom-control-input"
                                    id="customSwitch2"
                                    :checked="row.has_application"
                                    @click="onApplication"
                                  />
                                  <label
                                    class="custom-control-label"
                                    for="customSwitch2"
                                    v-html="
                                      row.has_application
                                        ? 'Show Application'
                                        : 'Hide Application'
                                    "
                                  >
                                  </label>
                                </div>
                              </div>

                              <div
                                class="col-12 row"
                                v-if="row.has_application"
                              >
                                <div class="form-group col-md-6 col-sm-12">
                                  <label>Application Name</label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    v-model="row.application_name"
                                  />
                                </div>
                                <div class="form-group col-md-6 col-sm-12">
                                  <label>Application Type</label>
                                  <select
                                    class="form-control"
                                    v-model="row.application_path"
                                  >
                                    <option value="trainings-applications">
                                      Training Application
                                    </option>
                                    <option value="members-applications">
                                      Membership Application
                                    </option>
                                    <option value="instructor-applications">
                                      Iam Instructor Application
                                    </option>
                                    <option value="experience-applications">
                                      I've Experience Application
                                    </option>
                                    <option value="accreditation-applications">
                                      Accreditation Application
                                    </option>
                                    <option value="certificate-applications">
                                      Certificate Application
                                    </option>
                                  </select>
                                </div>
                              </div>

                              <!-- Faq -->
                              <div class="form-group">
                                <div class="custom-control custom-switch mb-2">
                                  <input
                                    type="checkbox"
                                    class="custom-control-input"
                                    id="customSwitch3"
                                    :checked="row.has_faq"
                                    @click="onFaq"
                                  />
                                  <label
                                    class="custom-control-label"
                                    for="customSwitch3"
                                    v-html="
                                      row.has_faq ? 'Show FAQ' : 'Hide FAQ'
                                    "
                                  >
                                  </label>
                                </div>
                              </div>
                              <!-- End Faq -->

                              <div class="col-12 row" v-if="row.has_faq">
                                <div class="form-group col-md-6 col-sm-12">
                                  <label>FAQ Link</label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    v-model="row.faq_link"
                                  />
                                </div>
                              </div>

                              <div class="form-group">
                                <div class="custom-control custom-switch mb-2">
                                  <input
                                    type="checkbox"
                                    class="custom-control-input"
                                    id="customSwitch33"
                                    :checked="row.has_payment"
                                    @click="onPayment"
                                  />
                                  <label
                                    class="custom-control-label"
                                    for="customSwitch33"
                                    v-html="
                                      row.has_payment
                                        ? 'Show Payment'
                                        : 'Hide Payment'
                                    "
                                  >
                                  </label>
                                </div>
                              </div>
                              <div class="col-12 row" v-if="row.has_payment">
                                <div class="form-group col-md-6 col-sm-12">
                                  <label>Payment Name</label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    v-model="row.payment_name"
                                  />
                                </div>
                                <div class="form-group col-md-6 col-sm-12">
                                  <label>Payment Link</label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    v-model="row.payment_link"
                                  />
                                </div>
                              </div>

                              <!-- Faq -->
                              <div class="form-group">
                                <div class="custom-control custom-switch mb-2">
                                  <input
                                    type="checkbox"
                                    class="custom-control-input"
                                    id="customSwitch4"
                                    :checked="row.has_download"
                                    @click="onDownload"
                                  />
                                  <label
                                    class="custom-control-label"
                                    for="customSwitch4"
                                    v-html="
                                      row.has_download
                                        ? 'Show Download File'
                                        : 'Hide Download File'
                                    "
                                  >
                                  </label>
                                </div>
                              </div>
                              <!-- End Faq -->

                              <div class="col-12 row" v-if="row.has_download">
                                <div class="form-group col-8">
                                  <label>Download Title</label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    v-model="row.download_name"
                                  />
                                </div>
                                <div class="form-group col-4">
                                  <label
                                    >Download File
                                    <button
                                      v-if="row.download_preview"
                                      type="button"
                                      class="btn btn-danger btn-sm btn-pill"
                                      style="
                                        margin-top: -10px;
                                        margin-left: 10px;
                                      "
                                      @click="
                                        row.download_preview = '';
                                        row.download_file = '';
                                      "
                                    >
                                      Remove File
                                    </button>
                                  </label>
                                  <a
                                    v-if="row.download_preview"
                                    :href="row.download_preview"
                                    target="_blank"
                                    class="pull-right"
                                    >Preview
                                  </a>
                                  <input
                                    type="file"
                                    class="form-control"
                                    @change="onFileChange"
                                  />
                                </div>
                                <div class="form-group col-md-6 col-sm-12">
                                  <label
                                    >Download Image 180 * 220
                                    <button
                                      v-if="row.download_image_preview"
                                      type="button"
                                      class="btn btn-danger btn-sm btn-pill"
                                      style="
                                        margin-top: -10px;
                                        margin-left: 10px;
                                      "
                                      @click="
                                        row.download_image_preview = '';
                                        row.download_image = '';
                                      "
                                    >
                                      Remove Image
                                    </button>
                                  </label>
                                  <p>
                                    <img
                                      v-if="row.download_image_preview"
                                      :src="row.download_image_preview"
                                      style="max-width: 50%"
                                    />
                                  </p>
                                  <input
                                    type="file"
                                    class="form-control"
                                    @change="onFilePreviewChange"
                                    accept="image/*"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End Card PageName -->

                  <!-- Card Overview -->
                  <div class="card mt-5">
                    <div class="card-body">
                      <div id="accordion" class="accordion">
                        <div id="TabOverview" class="card-header">
                          <h2
                            class="h4 card-header-title"
                            @click="collapseToggle('Overview')"
                            aria-expanded="false"
                            aria-controls="collapseOverview"
                            data-toggle="collapse"
                            data-target="#collapseOverview"
                          >
                            Overview
                            <span
                              id="iconToggleOverview"
                              class="ti-angle-up u-sidebar-nav-menu__item-arrow pull-right black"
                            >
                            </span>
                          </h2>
                        </div>
                        <div
                          id="collapseOverview"
                          class="collapse"
                          aria-labelledby="TabOverview"
                          data-parent="#accordion"
                        >
                          <div class="col-12 pt-3">
                            <div class="row col-12" style="padding: 0">
                              <div class="form-group col-12">
                                <label for="editor1">Body</label>
                                <vue-editor
                                  id="editor1"
                                  v-model="row.body1"
                                  :init="{
                                    height: 300,
                                  }"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End Card Overview -->

                  <!-- Card Certificate Type -->
                  <div class="card mt-5">
                    <div class="card-body">
                      <div id="accordion" class="accordion">
                        <div id="TabOverview2" class="card-header">
                          <h2
                            class="h4 card-header-title"
                            @click="collapseToggle('Overview2')"
                            aria-expanded="false"
                            aria-controls="collapseOverview2"
                            data-toggle="collapse"
                            data-target="#collapseOverview2"
                          >
                            Our Purpose
                            <span
                              id="iconToggleOverview2"
                              class="ti-angle-up u-sidebar-nav-menu__item-arrow pull-right black"
                            >
                            </span>
                          </h2>
                        </div>
                        <div
                          id="collapseOverview2"
                          class="collapse"
                          aria-labelledby="TabOverview2"
                          data-parent="#accordion"
                        >
                          <div class="col-12 pt-3">
                            <div class="row col-12" style="padding: 0">
                              <div class="form-group col-md-6 col-sm-12">
                                <label for="input4_3">Title</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="row.title2_1"
                                />
                              </div>

                              <div class="form-group col-md-6 col-sm-12">
                                <label for="input4_3">Title</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="row.title2_2"
                                />
                              </div>
                            </div>

                            <div class="row col-12" style="padding: 0">
                              <!-- Body -->
                              <div class="form-group col-md-6 col-sm-12">
                                <label for="input2_1">Body</label>
                                <vue-editor
                                  id="input2_1"
                                  v-model="row.body2_1"
                                  :init="{
                                    height: 300,
                                  }"
                                />
                              </div>

                              <div class="form-group col-md-6 col-sm-12">
                                <label for="input4_3">Youtube url</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="row.video2_2"
                                />
                              </div>
                              <!-- End Body -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End Card Certificate Type -->

                  <!-- Card Certificate Type -->
                  <div class="card mt-5">
                    <div class="card-body">
                      <div id="accordion" class="accordion">
                        <div id="TabOverview3" class="card-header">
                          <h2
                            class="h4 card-header-title"
                            @click="collapseToggle('Overview3')"
                            aria-expanded="false"
                            aria-controls="collapseOverview3"
                            data-toggle="collapse"
                            data-target="#collapseOverview3"
                          >
                            Mission & Vision
                            <span
                              id="iconToggleOverview3"
                              class="ti-angle-up u-sidebar-nav-menu__item-arrow pull-right black"
                            >
                            </span>
                          </h2>
                        </div>
                        <div
                          id="collapseOverview3"
                          class="collapse"
                          aria-labelledby="TabOverview3"
                          data-parent="#accordion"
                        >
                          <div class="col-12 pt-3">
                            <div class="row col-12" style="padding: 0">
                              <div class="form-group col-md-6 col-sm-12">
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="row.title3_1"
                                />
                              </div>

                              <div class="form-group col-md-6 col-sm-12">
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="row.title3_2"
                                />
                              </div>
                            </div>

                            <div class="row col-12" style="padding: 0">
                              <!-- Body -->
                              <div class="form-group col-md-6 col-sm-12">
                                <vue-editor
                                  id="input3_1"
                                  v-model="row.body3_1"
                                  :init="{
                                    height: 300,
                                  }"
                                />
                              </div>

                              <div class="form-group col-md-6 col-sm-12">
                                <vue-editor
                                  id="input3_2"
                                  v-model="row.body3_2"
                                  :init="{
                                    height: 300,
                                  }"
                                />
                              </div>
                              <!-- End Body -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End Card Certificate Type -->

                  <!-- Card Certificate Type -->
                  <div class="card mt-5">
                    <div class="card-body">
                      <div id="accordion" class="accordion">
                        <div id="TabOverview4" class="card-header">
                          <h2
                            class="h4 card-header-title"
                            @click="collapseToggle('Overview4')"
                            aria-expanded="false"
                            aria-controls="collapseOverview4"
                            data-toggle="collapse"
                            data-target="#collapseOverview4"
                          >
                            Our Value
                            <span
                              id="iconToggleOverview4"
                              class="ti-angle-up u-sidebar-nav-menu__item-arrow pull-right black"
                            >
                            </span>
                          </h2>
                        </div>
                        <div
                          id="collapseOverview4"
                          class="collapse"
                          aria-labelledby="TabOverview4"
                          data-parent="#accordion"
                        >
                          <div class="col-12 pt-3">
                            <div class="row col-12" style="padding: 0">
                              <div class="form-group col-md-6 col-sm-12">
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="row.title4_1"
                                />
                              </div>

                              <div class="form-group col-md-6 col-sm-12">
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="row.title4_2"
                                />
                              </div>
                            </div>

                            <div class="row col-12" style="padding: 0">
                              <!-- Body -->
                              <div class="form-group col-md-6 col-sm-12">
                                <vue-editor
                                  id="input4_1"
                                  v-model="row.body4_1"
                                  :init="{
                                    height: 300,
                                  }"
                                />
                              </div>

                              <div class="form-group col-md-6 col-sm-12">
                                <vue-editor
                                  id="input4_2"
                                  v-model="row.body4_2"
                                  :init="{
                                    height: 300,
                                  }"
                                />
                              </div>
                              <!-- End Body -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End Card Certificate Type -->

                  <!-- Card Certificate Type -->
                  <div class="card mt-5">
                    <div class="card-body">
                      <div id="accordion" class="accordion">
                        <div id="TabOverview5" class="card-header">
                          <h2
                            class="h4 card-header-title"
                            @click="collapseToggle('Overview5')"
                            aria-expanded="false"
                            aria-controls="collapseOverview5"
                            data-toggle="collapse"
                            data-target="#collapseOverview5"
                          >
                            What We Do
                            <span
                              id="iconToggleOverview5"
                              class="ti-angle-up u-sidebar-nav-menu__item-arrow pull-right black"
                            >
                            </span>
                          </h2>
                        </div>
                        <div
                          id="collapseOverview5"
                          class="collapse"
                          aria-labelledby="TabOverview5"
                          data-parent="#accordion"
                        >
                          <div class="col-12 pt-3">
                            <div class="row col-12" style="padding: 0">
                              <div class="form-group col-md-6 col-sm-12">
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="row.title5_1"
                                />
                              </div>

                              <div class="form-group col-md-6 col-sm-12">
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="row.title5_2"
                                />
                              </div>
                            </div>

                            <div class="row col-12" style="padding: 0">
                              <div class="form-group col-md-6 col-sm-12">
                                <vue-editor
                                  id="input5_1"
                                  v-model="row.body5_1"
                                  :init="{
                                    height: 300,
                                  }"
                                />
                              </div>
                              <div class="form-group col-md-6 col-sm-12">
                                <button
                                  v-if="row.preview5_1"
                                  type="button"
                                  class="btn btn-danger btn-sm btn-pill"
                                  style="margin-top: -10px; margin-left: 10px"
                                  @click="
                                    row.preview5_1 = '';
                                    row.image5_1 = '';
                                  "
                                >
                                  Remove Image
                                </button>
                                <label>660 * 390</label>
                                <img
                                  :src="row.preview5_1 ? row.preview5_1 : ''"
                                  style="width: 100%; height: 215px"
                                />
                                <input
                                  type="file"
                                  class="form-control"
                                  @change="onImage5_1Change"
                                />
                              </div>
                              <div class="form-group col-col-sm-12md-6">
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="row.read5_1"
                                />
                              </div>
                              <div class="form-group col-md-6 col-sm-12"></div>

                              <hr />

                              <div class="form-group col-md-6 col-sm-12">
                                <vue-editor
                                  id="input5_2"
                                  v-model="row.body5_2"
                                  :init="{
                                    height: 300,
                                  }"
                                />
                              </div>
                              <div class="form-group col-md-6 col-sm-12">
                                <button
                                  v-if="row.preview5_2"
                                  type="button"
                                  class="btn btn-danger btn-sm btn-pill"
                                  style="margin-top: -10px; margin-left: 10px"
                                  @click="
                                    row.preview5_2 = '';
                                    row.image5_2 = '';
                                  "
                                >
                                  Remove Image
                                </button>
                                <label>660 * 390</label>
                                <img
                                  :src="row.preview5_2 ? row.preview5_2 : ''"
                                  style="width: 100%; height: 215px"
                                />
                                <input
                                  type="file"
                                  class="form-control"
                                  @change="onImage5_2Change"
                                />
                              </div>
                              <div class="form-group col-md-6 col-sm-12">
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="row.read5_2"
                                />
                              </div>
                              <div class="form-group col-md-6 col-sm-12"></div>

                              <hr />

                              <div class="form-group col-md-6 col-sm-12">
                                <vue-editor
                                  id="input5_3"
                                  v-model="row.body5_3"
                                  :init="{
                                    height: 300,
                                  }"
                                />
                              </div>
                              <div class="form-group col-md-6 col-sm-12">
                                <button
                                  v-if="row.preview5_3"
                                  type="button"
                                  class="btn btn-danger btn-sm btn-pill"
                                  style="margin-top: -10px; margin-left: 10px"
                                  @click="
                                    row.preview5_3 = '';
                                    row.image5_3 = '';
                                  "
                                >
                                  Remove Image
                                </button>
                                <label>660 * 390</label>
                                <img
                                  :src="row.preview5_3 ? row.preview5_3 : ''"
                                  style="width: 100%; height: 215px"
                                />
                                <input
                                  type="file"
                                  class="form-control"
                                  @change="onImage5_3Change"
                                />
                              </div>
                              <div class="form-group col-md-6 col-sm-12">
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="row.read5_3"
                                />
                              </div>
                              <div class="form-group col-md-6 col-sm-12"></div>

                              <hr />

                              <div class="form-group col-md-6 col-sm-12">
                                <vue-editor
                                  id="input5_4"
                                  v-model="row.body5_4"
                                  :init="{
                                    height: 300,
                                  }"
                                />
                              </div>
                              <div class="form-group col-md-6 col-sm-12">
                                <button
                                  v-if="row.preview5_4"
                                  type="button"
                                  class="btn btn-danger btn-sm btn-pill"
                                  style="margin-top: -10px; margin-left: 10px"
                                  @click="
                                    row.preview5_4 = '';
                                    row.image5_4 = '';
                                  "
                                >
                                  Remove Image
                                </button>
                                <label>660 * 390</label>
                                <img
                                  :src="row.preview5_4 ? row.preview5_4 : ''"
                                  style="width: 100%; height: 215px"
                                />
                                <input
                                  type="file"
                                  class="form-control"
                                  @change="onImage5_4Change"
                                />
                              </div>
                              <div class="form-group col-md-6 col-sm-12">
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="row.read5_4"
                                />
                              </div>
                              <div class="form-group col-md-6 col-sm-12"></div>

                              <hr />

                              <div class="form-group col-md-6 col-sm-12">
                                <vue-editor
                                  id="input5_5"
                                  v-model="row.body5_5"
                                  :init="{
                                    height: 300,
                                  }"
                                />
                              </div>
                              <div class="form-group col-md-6 col-sm-12">
                                <button
                                  v-if="row.preview5_5"
                                  type="button"
                                  class="btn btn-danger btn-sm btn-pill"
                                  style="margin-top: -10px; margin-left: 10px"
                                  @click="
                                    row.preview5_5 = '';
                                    row.image5_5 = '';
                                  "
                                >
                                  Remove Image
                                </button>
                                <label>660 * 390</label>
                                <img
                                  :src="row.preview5_5 ? row.preview5_5 : ''"
                                  style="width: 100%; height: 215px"
                                />
                                <input
                                  type="file"
                                  class="form-control"
                                  @change="onImage5_5Change"
                                />
                              </div>
                              <div class="form-group col-md-6 col-sm-12">
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="row.read5_5"
                                />
                              </div>
                              <div class="form-group col-md-6 col-sm-12"></div>

                              <hr />

                              <div class="form-group col-md-6 col-sm-12">
                                <vue-editor
                                  id="input5_6"
                                  v-model="row.body5_6"
                                  :init="{
                                    height: 300,
                                  }"
                                />
                              </div>
                              <div class="form-group col-md-6 col-sm-12">
                                <button
                                  v-if="row.preview5_6"
                                  type="button"
                                  class="btn btn-danger btn-sm btn-pill"
                                  style="margin-top: -10px; margin-left: 10px"
                                  @click="
                                    row.preview5_6 = '';
                                    row.image5_6 = '';
                                  "
                                >
                                  Remove Image
                                </button>
                                <label>660 * 390</label>
                                <img
                                  :src="row.preview5_6 ? row.preview5_6 : ''"
                                  style="width: 100%; height: 215px"
                                />
                                <input
                                  type="file"
                                  class="form-control"
                                  @change="onImage5_6Change"
                                />
                              </div>
                              <div class="form-group col-md-6 col-sm-12">
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="row.read5_6"
                                />
                              </div>
                              <div class="form-group col-md-6 col-sm-12"></div>

                              <hr />

                              <div class="form-group col-md-6 col-sm-12">
                                <vue-editor
                                  id="input5_7"
                                  v-model="row.body5_7"
                                  :init="{
                                    height: 300,
                                  }"
                                />
                              </div>
                              <div class="form-group col-md-6 col-sm-12">
                                <button
                                  v-if="row.preview5_7"
                                  type="button"
                                  class="btn btn-danger btn-sm btn-pill"
                                  style="margin-top: -10px; margin-left: 10px"
                                  @click="
                                    row.preview5_7 = '';
                                    row.image5_7 = '';
                                  "
                                >
                                  Remove Image
                                </button>
                                <label>660 * 390</label>
                                <img
                                  :src="row.preview5_7 ? row.preview5_7 : ''"
                                  style="width: 100%; height: 215px"
                                />
                                <input
                                  type="file"
                                  class="form-control"
                                  @change="onImage5_7Change"
                                />
                              </div>
                              <div class="form-group col-md-6 col-sm-12">
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="row.read5_7"
                                />
                              </div>
                              <div class="form-group col-md-6 col-sm-12"></div>

                              <hr />

                              <div class="form-group col-md-6 col-sm-12">
                                <vue-editor
                                  id="input5_8"
                                  v-model="row.body5_8"
                                  :init="{
                                    height: 300,
                                  }"
                                />
                              </div>
                              <div class="form-group col-md-6 col-sm-12">
                                <button
                                  v-if="row.preview5_8"
                                  type="button"
                                  class="btn btn-danger btn-sm btn-pill"
                                  style="margin-top: -10px; margin-left: 10px"
                                  @click="
                                    row.preview5_8 = '';
                                    row.image5_8 = '';
                                  "
                                >
                                  Remove Image
                                </button>
                                <label>660 * 390</label>
                                <img
                                  :src="row.preview5_8 ? row.preview5_8 : ''"
                                  style="width: 100%; height: 215px"
                                />
                                <input
                                  type="file"
                                  class="form-control"
                                  @change="onImage5_8Change"
                                />
                              </div>
                              <div class="form-group col-md-6 col-sm-12">
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="row.read5_8"
                                />
                              </div>
                              <div class="form-group col-md-6 col-sm-12"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End Card Certificate Type -->
                </div>
                <!-- ******* End Card ******** -->
              </div>
            </div>
            <!-- End Content -->

            <!-- Buttons -->
            <div class="col-md-12 row">
              <div class="form-group mr-2">
                <button class="btn btn-primary" :disabled="btnLoading">
                  <span v-if="btnLoading">
                    <span
                      class="spinner-grow spinner-grow-sm mr-1"
                      role="status"
                      aria-hidden="true"
                    >
                    </span
                    >Loading...
                  </span>
                  <span v-if="!btnLoading" class="ti-check-box"></span>
                  <span v-if="!btnLoading"> Create Shortcut</span>
                </button>
              </div>

              <div class="form-group">
                <button
                  type="button"
                  class="btn btn-danger"
                  :disabled="btnLoading"
                  @click="cancel"
                >
                  <span class="ti-close"></span>
                  <span> Cancel </span>
                </button>
              </div>
            </div>
            <!-- End Buttons -->
          </form>
        </div>

        <Footer></Footer>
      </div>
    </main>
    <!-- End Main -->
  </div>
</template>

<script>
import Header from "../layouts/Header.vue";
import Navigation from "../layouts/Navigation";
import Footer from "../layouts/Footer.vue";
import { VueEditor } from "vue2-editor";
import iziToast from "izitoast";

export default {
  name: "Create",
  components: {
    Header,
    Navigation,
    Footer,
    VueEditor,
  },
  data() {
    return {
      auth: {
        access_token: "",
      },
      row: {
        // div 1

        title: "",
        slug: "",
        sort: 0,

        preview: "",
        image: "",
        bgTitle: "",
        bgColor: "",
        body1: "",

        status: 1,
        has_faq: 0,
        has_application: 0,
        has_download: 0,
        has_payment: 0,

        faq_link: "",
        payment_name: "",
        payment_link: "",

        download_name: "",
        download_image_preview: "",
        download_image: "",
        download_preview: "",
        download_file: "",

        application_name: "",
        application_path: "",

        // div2
        title2_1: "",
        title2_2: "",
        body2_1: "",
        video2_2: "",

        // div3
        title3_1: "",
        title3_2: "",
        body3_1: "",
        body3_2: "",

        // div4
        title4_1: "",
        title4_2: "",
        body4_1: "",
        body4_2: "",

        // div5
        title5_1: "",
        title5_2: "",

        preview5_1: "",
        image5_1: "",
        body5_1: "",
        read5_1: "",

        preview5_2: "",
        image5_2: "",
        body5_2: "",
        read5_2: "",

        preview5_3: "",
        image5_3: "",
        body5_3: "",
        read5_3: "",

        preview5_4: "",
        image5_4: "",
        body5_4: "",
        read5_4: "",

        preview5_5: "",
        image5_5: "",
        body5_5: "",
        read5_5: "",

        preview5_6: "",
        image5_6: "",
        body5_6: "",
        read5_6: "",

        preview5_7: "",
        image5_7: "",
        body5_7: "",
        read5_7: "",

        preview5_8: "",
        image5_8: "",
        body5_8: "",
        read5_8: "",
      },

      pgLoading: false,
      btnLoading: false,

      root_id: "",
    };
  },
  mounted() {},
  computed: {},
  created() {
    // AccessToken & Role
    if (localStorage.getItem("access_token")) {
      this.auth.access_token = localStorage.getItem("access_token");
    }

    if (this.$route.params.root_id) {
      this.root_id = this.$route.params.root_id;
    }

    //this.fetchRow();
  },
  methods: {
    // fetch Row
    fetchRow() {
      this.pgLoading = true;
      this.axios.defaults.headers.common = {
        //'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.access_token,
      };
      const options = {
        url: window.baseURL + "/ourpartners/" + this.$route.params.id,
        method: "GET",
        data: {},
        params: {},
      };
      this.axios(options)
        .then((res) => {
          this.pgLoading = false;

          // div 1
          this.row.preview = res.data.row.image;
          this.row.image = res.data.row.image;

          this.row.status = res.data.row.status;
          this.row.has_faq = res.data.row.has_faq;
          this.row.has_application = res.data.row.has_application;
          this.row.has_download = res.data.row.has_download;
          this.row.has_payment = res.data.row.has_payment;

          this.row.faq_link = res.data.row.faq_link;
          this.row.payment_name = res.data.row.payment_name;
          this.row.payment_link = res.data.row.payment_link;

          this.row.download_name = res.data.row.download_name;
          this.row.download_file = res.data.row.download_file;
          this.row.download_preview = res.data.row.download_file;
          this.row.download_image = res.data.row.download_image;
          this.row.download_image_preview = res.data.row.download_image;

          this.row.application_name = res.data.row.application_name;
          this.row.application_path = res.data.row.application_path;

          this.row.bgTitle = res.data.row.bgTitle;
          this.row.bgColor = res.data.row.bgColor;
          this.row.body1 = res.data.row.body1;

          // div2
          this.row.title2_1 = res.data.row.title2_1;
          this.row.title2_2 = res.data.row.title2_2;
          this.row.body2_1 = res.data.row.body2_1;
          this.row.video2_2 = res.data.row.video2_2;

          // div3
          this.row.title3_1 = res.data.row.title3_1;
          this.row.title3_2 = res.data.row.title3_2;
          this.row.body3_1 = res.data.row.body3_1;
          this.row.body3_2 = res.data.row.body3_2;

          // div4
          this.row.title4_1 = res.data.row.title4_1;
          this.row.title4_2 = res.data.row.title4_2;
          this.row.body4_1 = res.data.row.body4_1;
          this.row.body4_2 = res.data.row.body4_2;

          // div5
          this.row.title5_1 = res.data.row.title5_1;
          this.row.title5_2 = res.data.row.title5_2;

          this.row.preview5_1 = res.data.row.image5_1;
          this.row.image5_1 = res.data.row.image5_1;
          this.row.body5_1 = res.data.row.body5_1;
          this.row.read5_1 = res.data.row.read5_1;

          this.row.preview5_2 = res.data.row.image5_2;
          this.row.image5_2 = res.data.row.image5_2;
          this.row.body5_2 = res.data.row.body5_2;
          this.row.read5_2 = res.data.row.read5_2;

          this.row.preview5_3 = res.data.row.image5_3;
          this.row.image5_3 = res.data.row.image5_3;
          this.row.body5_3 = res.data.row.body5_3;
          this.row.read5_3 = res.data.row.read5_3;

          this.row.preview5_4 = res.data.row.image5_4;
          this.row.image5_4 = res.data.row.image5_4;
          this.row.body5_4 = res.data.row.body5_4;
          this.row.read5_4 = res.data.row.read5_4;

          this.row.preview5_5 = res.data.row.image5_5;
          this.row.image5_5 = res.data.row.image5_5;
          this.row.body5_5 = res.data.row.body5_5;
          this.row.read5_5 = res.data.row.read5_5;

          this.row.preview5_6 = res.data.row.image5_6;
          this.row.image5_6 = res.data.row.image5_6;
          this.row.body5_6 = res.data.row.body5_6;
          this.row.read5_6 = res.data.row.read5_6;

          this.row.preview5_7 = res.data.row.image5_7;
          this.row.image5_7 = res.data.row.image5_7;
          this.row.body5_7 = res.data.row.body5_7;
          this.row.read5_7 = res.data.row.read5_7;

          this.row.preview5_8 = res.data.row.image5_8;
          this.row.image5_8 = res.data.row.image5_8;
          this.row.body5_8 = res.data.row.body5_8;
          this.row.read5_8 = res.data.row.read5_8;
        })
        .catch((err) => {
          this.btnLoading = false;
          iziToast.warning({
            icon: "ti-alert",
            title: "Wow-man,",
            message: err.response ? err.response.data.message : "" + err,
          });
        })
        .finally(() => {});
    },

    // editRow
    addNew() {
      this.btnLoading = true;
      this.axios.defaults.headers.common = {
        //'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.access_token,
      };

      const config = { headers: { "Content-Type": "multipart/form-data" } };
      const options = {
        url: window.baseURL + "/ourpartners",
        method: "POST",
        data: {
          parent_id: this.root_id,

          title: this.row.title,
          slug: this.row.slug,
          sort: this.row.sort,

          status: this.row.status,
          has_faq: this.row.has_faq,
          has_application: this.row.has_application,
          has_download: this.row.has_download,
          has_payment: this.row.has_payment,

          faq_link: this.row.faq_link,
          payment_name: this.row.payment_name,
          payment_link: this.row.payment_link,

          download_name: this.row.download_name,
          download_image: this.row.download_image,
          download_file: this.row.download_file,

          application_name: this.row.application_name,
          application_path: this.row.application_path,

          // image
          image: this.row.image,

          // mask
          bgTitle: this.row.bgTitle,
          bgColor: this.row.bgColor,

          body1: this.row.body1,

          // div2
          title2_1: this.row.title2_1,
          title2_2: this.row.title2_2,
          body2_1: this.row.body2_1,
          video2_2: this.row.video2_2,

          // div3
          title3_1: this.row.title3_1,
          title3_2: this.row.title3_2,
          body3_1: this.row.body3_1,
          body3_2: this.row.body3_2,

          // div4
          title4_1: this.row.title4_1,
          title4_2: this.row.title4_2,
          body4_1: this.row.body4_1,
          body4_2: this.row.body4_2,

          // div5
          title5_1: this.row.title5_1,
          title5_2: this.row.title5_2,

          image5_1: this.row.image5_1,
          body5_1: this.row.body5_1,
          read5_1: this.row.read5_1,

          image5_2: this.row.image5_2,
          body5_2: this.row.body5_2,
          read5_2: this.row.read5_2,

          image5_3: this.row.image5_3,
          body5_3: this.row.body5_3,
          read5_3: this.row.read5_3,

          image5_4: this.row.image5_4,
          body5_4: this.row.body5_4,
          read5_4: this.row.read5_4,

          image5_5: this.row.image5_5,
          body5_5: this.row.body5_5,
          read5_5: this.row.read5_5,

          image5_6: this.row.image5_6,
          body5_6: this.row.body5_6,
          read5_6: this.row.read5_6,

          image5_7: this.row.image5_7,
          body5_7: this.row.body5_7,
          read5_7: this.row.read5_7,

          image5_8: this.row.image5_8,
          body5_8: this.row.body5_8,
          read5_8: this.row.read5_8,
        },
      };
      this.axios(options, config)
        .then(() => {
          this.btnLoading = false;
          iziToast.success({
            icon: "ti-check",
            title: "Great job,",
            message: "Item Created Successfully.",
          });
          this.$router.push({
            name: "ourpartners-sub",
            params: { root_id: this.root_id },
          });
        })
        .catch((err) => {
          this.btnLoading = false;
          iziToast.warning({
            icon: "ti-alert",
            title: "Wow-man,",
            message: err.response ? err.response.data.message : "" + err,
          });
        })
        .finally(() => {});
    },

    // Upload Featured image
    onImageChange(e) {
      const file = e.target.files[0];
      this.row.preview = URL.createObjectURL(file);
      this.createBase64mage(file);
    },
    createBase64mage(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image = e.target.result;
      };
    },

    onFileChange(e) {
      const file = e.target.files[0];
      this.row.download_preview = URL.createObjectURL(file);
      this.createBase64File(file);
    },
    createBase64File(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.download_file = e.target.result;
      };
    },

    onFilePreviewChange(e) {
      const file = e.target.files[0];
      this.row.download_image_preview = URL.createObjectURL(file);
      this.createBase64FilePreview(file);
    },
    createBase64FilePreview(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.download_image = e.target.result;
      };
    },

    onImage5_1Change(e) {
      const file = e.target.files[0];
      this.row.preview5_1 = URL.createObjectURL(file);
      this.createBase64Image5_1(file);
    },
    createBase64Image5_1(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image5_1 = e.target.result;
      };
    },

    onImage5_2Change(e) {
      const file = e.target.files[0];
      this.row.preview5_2 = URL.createObjectURL(file);
      this.createBase64Image5_2(file);
    },
    createBase64Image5_2(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image5_2 = e.target.result;
      };
    },

    onImage5_3Change(e) {
      const file = e.target.files[0];
      this.row.preview5_3 = URL.createObjectURL(file);
      this.createBase64Image5_3(file);
    },
    createBase64Image5_3(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image5_3 = e.target.result;
      };
    },

    onImage5_4Change(e) {
      const file = e.target.files[0];
      this.row.preview5_4 = URL.createObjectURL(file);
      this.createBase64Image5_4(file);
    },
    createBase64Image5_4(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image5_4 = e.target.result;
      };
    },

    onImage5_5Change(e) {
      const file = e.target.files[0];
      this.row.preview5_5 = URL.createObjectURL(file);
      this.createBase64Image5_5(file);
    },
    createBase64Image5_5(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image5_5 = e.target.result;
      };
    },

    onImage5_6Change(e) {
      const file = e.target.files[0];
      this.row.preview5_6 = URL.createObjectURL(file);
      this.createBase64Image5_6(file);
    },
    createBase64Image5_6(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image5_6 = e.target.result;
      };
    },

    onImage5_7Change(e) {
      const file = e.target.files[0];
      this.row.preview5_7 = URL.createObjectURL(file);
      this.createBase64Image5_7(file);
    },
    createBase64Image5_7(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image5_7 = e.target.result;
      };
    },

    onImage5_8Change(e) {
      const file = e.target.files[0];
      this.row.preview5_8 = URL.createObjectURL(file);
      this.createBase64Image5_8(file);
    },
    createBase64Image5_8(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image5_8 = e.target.result;
      };
    },

    // active status
    onStatus() {
      if (this.row.status) this.row.status = 0;
      else this.row.status = 1;
    },
    onFaq() {
      if (this.row.has_faq) this.row.has_faq = 0;
      else this.row.has_faq = 1;
    },
    onPayment() {
      if (this.row.has_payment) this.row.has_payment = 0;
      else this.row.has_payment = 1;
    },
    onApplication() {
      if (this.row.has_application) this.row.has_application = 0;
      else this.row.has_application = 1;
    },
    onDownload() {
      if (this.row.has_download) this.row.has_download = 0;
      else this.row.has_download = 1;
    },

    // Title
    onTitleChange() {
      this.onSlugChange(this.row.title);
    },

    // on Paste
    onSlugPaste() {
      let str = this.row.slug;
      this.onSlugChange(str);
    },
    onSlugChange(str) {
      if (!str) {
        let str = this.row.slug;
        this.row.slug = str
          .replace(/\s+/g, "-")
          .replace(/\?+/g, "-")
          .replace(/\/+/g, "-")
          .replace(/\\+/g, "-");
      } else {
        this.row.slug = str
          .replace(/\s+/g, "-")
          .replace(/\?+/g, "-")
          .replace(/\/+/g, "-")
          .replace(/\\+/g, "-");
      }
    },

    // toggleCollapse
    collapseToggle(div) {
      let el = document.querySelector("span#iconToggle" + div);
      if (el.classList.contains("ti-angle-down")) {
        el.classList.remove("ti-angle-down");
        el.classList.add("ti-angle-up");
      } else {
        el.classList.remove("ti-angle-up");
        el.classList.add("ti-angle-down");
      }
    },

    // Cancel
    cancel() {
      if (confirm("Are You Sure?")) {
        this.$router.push({
          name: "ourpartners-sub",
          params: { root_id: this.root_id },
        });
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
