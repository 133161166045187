<template>
  <div class="">
    <Header></Header>

    <!-- Main -->
    <main class="u-main">
      <Navigation></Navigation>

      <div class="u-content">
        <div class="u-body min-h-700">
          <h1 class="h2 mb-2">Products</h1>

          <!-- Breadcrumb -->
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <router-link :to="{ name: 'dashboard' }">Dashboard</router-link>
              </li>
              <li class="breadcrumb-item">
                <router-link :to="{ name: 'products2' }">Products</router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">Edit</li>
            </ol>
          </nav>
          <!-- End Breadcrumb -->

          <!-- Loading -->
          <div v-if="pgLoading" class="row h-100">
            <div class="container text-center">
              <p><br /></p>
              <div class="spinner-grow" role="status">
                <span class="sr-only">Loading...</span>
              </div>
              <p><br /></p>
            </div>
          </div>
          <!-- End Loading -->

          <form
            v-if="!pgLoading"
            @submit.prevent="editRow"
            enctype="multipart/form-data"
            class="h-100"
          >
            <!-- Content -->
            <div class="tab-content">
              <div class="row">
                <!-- ******* Card ******** -->
                <div class="col-md-12 mb-5">
                  <!-- Card Background -->
                  <div class="card">
                    <div class="card-body">
                      <div id="accordion" class="accordion">
                        <div id="TabBackground" class="card-header">
                          <h2
                            class="h4 card-header-title"
                            @click="collapseToggle('Background')"
                            aria-expanded="false"
                            aria-controls="collapseBackground"
                            data-toggle="collapse"
                            data-target="#collapseBackground"
                          >
                            Background
                            <span
                              id="iconToggleBackground"
                              class="ti-angle-up u-sidebar-nav-menu__item-arrow pull-right black"
                            >
                            </span>
                          </h2>
                        </div>
                        <div
                          id="collapseBackground"
                          class="collapse show"
                          aria-labelledby="TabBackground"
                          data-parent="#accordion"
                        >
                          <div class="col-12 pt-3">
                            <div class="form-group">
                              <label
                                >Background 1200 * 440
                                <button
                                  v-if="row.preview"
                                  type="button"
                                  class="btn btn-danger btn-sm btn-pill"
                                  style="margin-top: -10px; margin-left: 10px"
                                  @click="
                                    row.preview = '';
                                    row.base64Image = '';
                                  "
                                >
                                  Remove Image
                                </button>
                              </label>
                              <p>
                                <img
                                  v-if="row.preview"
                                  :src="row.preview"
                                  style="max-width: 100%"
                                />
                              </p>
                              <input
                                type="file"
                                class="form-control"
                                v-on:change="onImageChange"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End Card Background -->

                  <!-- Card Accreditations -->
                  <div class="card mt-5">
                    <div class="card-body">
                      <div id="accordion" class="accordion">
                        <div id="TabMask" class="card-header">
                          <h2
                            class="h4 card-header-title"
                            @click="collapseToggle('Mask')"
                            aria-expanded="false"
                            aria-controls="collapseMask"
                            data-toggle="collapse"
                            data-target="#collapseMask"
                          >
                            Mask
                            <span
                              id="iconToggleMask"
                              class="ti-angle-up u-sidebar-nav-menu__item-arrow pull-right black"
                            >
                            </span>
                          </h2>
                        </div>
                        <div
                          id="collapseMask"
                          class="collapse"
                          aria-labelledby="TabMask"
                          data-parent="#accordion"
                        >
                          <div class="col-12 pt-3 row">
                            <!-- Title -->
                            <div class="form-group col-9">
                              <label for="input1">background Title</label>
                              <vue-editor
                                id="editor01"
                                v-model="row.bgTitle"
                                :init="{
                                  height: 200,
                                }"
                              />
                            </div>
                            <!-- End Title -->

                            <!-- Slug -->
                            <div class="form-group col-3">
                              <label for="input2">background Color</label>
                              <input
                                class="form-control"
                                id="input2"
                                type="color"
                                style="height: 200px"
                                v-model="row.bgColor"
                              />
                            </div>
                            <!-- End Slug -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End Card Writer -->

                  <!-- Card Accreditations -->
                  <div class="card mt-5">
                    <div class="card-body">
                      <div id="accordion" class="accordion">
                        <div id="TabContent" class="card-header">
                          <h2
                            class="h4 card-header-title"
                            @click="collapseToggle('Content')"
                            aria-expanded="false"
                            aria-controls="collapseContent"
                            data-toggle="collapse"
                            data-target="#collapseContent"
                          >
                            Content
                            <span
                              id="iconToggleContent"
                              class="ti-angle-up u-sidebar-nav-menu__item-arrow pull-right black"
                            >
                            </span>
                          </h2>
                        </div>
                        <div
                          id="collapseContent"
                          class="collapse"
                          aria-labelledby="TabContent"
                          data-parent="#accordion"
                        >
                          <div class="col-12 pt-3">
                            <!-- Title -->
                            <div class="form-group">
                              <label for="input1">Title</label>
                              <input
                                class="form-control"
                                id="input1"
                                type="text"
                                v-model="row.title"
                                @keyup="onTitleChange"
                              />
                            </div>
                            <!-- End Title -->

                            <!-- Slug -->
                            <div class="form-group">
                              <label for="input2">Slug</label>
                              <input
                                class="form-control text-lowercase"
                                id="input2"
                                type="text"
                                v-model="row.slug"
                                @keydown.space.prevent
                                @paste="onSlugPaste"
                                @change="onSlugChange(false)"
                              />
                            </div>
                            <!-- End Slug -->

                            <!-- Title -->
                            <div class="form-group">
                              <label for="input1_1">Sub Title</label>
                              <input
                                class="form-control"
                                id="input1_1"
                                type="text"
                                v-model="row.subtitle"
                              />
                            </div>
                            <!-- End Title -->

                            <!-- Sort -->
                            <div class="form-group">
                              <label for="input3">Sort</label>
                              <input
                                class="form-control"
                                id="input3"
                                min="0"
                                type="number"
                                v-model.number="row.sort"
                              />
                            </div>
                            <!-- End Sort -->

                            <!-- Body -->
                            <div class="form-group">
                              <label for="input4">Short Body</label>
                              <vue-editor
                                id="input4"
                                v-model="row.short_body"
                                :init="{
                                  height: 300,
                                }"
                              />
                            </div>
                            <!-- End Body -->

                            <!-- Body -->
                            <div class="form-group">
                              <input
                                for="input5"
                                type="text"
                                class="form-control"
                                v-model="row.title1"
                                style="width: 50%; margin-bottom: 10px"
                              />
                              <vue-editor
                                id="input5"
                                v-model="row.body1"
                                :init="{
                                  height: 300,
                                }"
                              />
                            </div>
                            <!-- End Body -->

                            <!-- Body -->
                            <div class="form-group">
                              <input
                                for="input6"
                                type="text"
                                class="form-control"
                                v-model="row.title2"
                                style="width: 50%; margin-bottom: 10px"
                              />
                              <vue-editor
                                id="input6"
                                v-model="row.body2"
                                :init="{
                                  height: 300,
                                }"
                              />
                            </div>
                            <!-- End Body -->

                            <!-- Body -->
                            <div class="form-group">
                              <input
                                for="input7"
                                type="text"
                                class="form-control"
                                v-model="row.title3"
                                style="width: 50%; margin-bottom: 10px"
                              />
                              <vue-editor
                                id="input7"
                                v-model="row.body3"
                                :init="{
                                  height: 300,
                                }"
                              />
                            </div>
                            <!-- End Body -->

                            <!-- Body -->
                            <div class="form-group">
                              <input
                                for="input8"
                                type="text"
                                class="form-control"
                                v-model="row.title4"
                                style="width: 50%; margin-bottom: 10px"
                              />
                              <vue-editor
                                id="input8"
                                v-model="row.body4"
                                :init="{
                                  height: 300,
                                }"
                              />
                            </div>
                            <!-- End Body -->

                            <!-- Body -->
                            <div class="form-group">
                              <input
                                for="input9"
                                type="text"
                                class="form-control"
                                v-model="row.title5"
                                style="width: 50%; margin-bottom: 10px"
                              />
                              <vue-editor
                                id="input9"
                                v-model="row.body5"
                                :init="{
                                  height: 300,
                                }"
                              />
                            </div>
                            <!-- End Body -->

                            <!-- Body -->
                            <div class="form-group" style="display: none">
                              <label for="input5">Body</label>
                              <vue-editor
                                id="input5"
                                v-model="row.body"
                                :init="{
                                  height: 900,
                                }"
                              />
                            </div>
                            <!-- End Body -->

                            <h4><br />Status & Visibility</h4>
                            <div class="col-md-12">
                              <!-- Status -->
                              <div class="form-group">
                                <div class="custom-control custom-switch mb-2">
                                  <input
                                    type="checkbox"
                                    class="custom-control-input"
                                    id="customSwitch1"
                                    :checked="row.status"
                                    @click="onStatus"
                                  />
                                  <label
                                    class="custom-control-label"
                                    for="customSwitch1"
                                    v-html="row.status ? 'Active' : 'Inactive'"
                                  >
                                  </label>
                                </div>
                              </div>
                              <!-- End Status -->

                              <div class="form-group">
                                <div class="custom-control custom-switch mb-2">
                                  <input
                                    type="checkbox"
                                    class="custom-control-input"
                                    id="customSwitch2"
                                    :checked="row.has_application"
                                    @click="onApplication"
                                  />
                                  <label
                                    class="custom-control-label"
                                    for="customSwitch2"
                                    v-html="
                                      row.has_application
                                        ? 'Show Application'
                                        : 'Hide Application'
                                    "
                                  >
                                  </label>
                                </div>
                              </div>
                              <!-- End Training -->

                              <div
                                class="col-12 row"
                                v-if="row.has_application"
                              >
                                <div class="form-group col-md-6 col-sm-12">
                                  <label>Application Name</label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    v-model="row.application_name"
                                  />
                                </div>
                                <div class="form-group col-md-6 col-sm-12">
                                  <label>Application Type</label>
                                  <select
                                    class="form-control"
                                    v-model="row.application_path"
                                  >
                                    <option value="trainings-applications">
                                      Training Application
                                    </option>
                                    <option value="members-applications">
                                      Membership Application
                                    </option>
                                    <option value="instructor-applications">
                                      Iam Instructor Application
                                    </option>
                                    <option value="experience-applications">
                                      I've Experience Application
                                    </option>
                                    <option value="accreditation-applications">
                                      Accreditation Application
                                    </option>
                                    <option value="certificate-applications">
                                      Certificate Application
                                    </option>
                                  </select>
                                </div>
                              </div>

                              <!-- Faq -->
                              <div class="form-group">
                                <div class="custom-control custom-switch mb-2">
                                  <input
                                    type="checkbox"
                                    class="custom-control-input"
                                    id="customSwitch3"
                                    :checked="row.has_faq"
                                    @click="onFaq"
                                  />
                                  <label
                                    class="custom-control-label"
                                    for="customSwitch3"
                                    v-html="
                                      row.has_faq ? 'Show FAQ' : 'Hide FAQ'
                                    "
                                  >
                                  </label>
                                </div>
                              </div>
                              <!-- End Faq -->

                              <div class="col-12 row" v-if="row.has_faq">
                                <div class="form-group col-md-6 col-sm-12">
                                  <label>FAQ Link</label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    v-model="row.faq_link"
                                  />
                                </div>
                              </div>

                              <div class="form-group">
                                <div class="custom-control custom-switch mb-2">
                                  <input
                                    type="checkbox"
                                    class="custom-control-input"
                                    id="customSwitch33"
                                    :checked="row.has_payment"
                                    @click="onPayment"
                                  />
                                  <label
                                    class="custom-control-label"
                                    for="customSwitch33"
                                    v-html="
                                      row.has_payment
                                        ? 'Show Payment'
                                        : 'Hide Payment'
                                    "
                                  >
                                  </label>
                                </div>
                              </div>
                              <div class="col-12 row" v-if="row.has_payment">
                                <div class="form-group col-md-6 col-sm-12">
                                  <label>Payment Name</label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    v-model="row.payment_name"
                                  />
                                </div>
                                <div class="form-group col-md-6 col-sm-12">
                                  <label>Payment Link</label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    v-model="row.payment_link"
                                  />
                                </div>
                              </div>

                              <div class="form-group">
                                <div class="custom-control custom-switch mb-2">
                                  <input
                                    type="checkbox"
                                    class="custom-control-input"
                                    id="customSwitch4"
                                    :checked="row.has_download"
                                    @click="onDownload"
                                  />
                                  <label
                                    class="custom-control-label"
                                    for="customSwitch4"
                                    v-html="
                                      row.has_download
                                        ? 'Show Download File'
                                        : 'Hide Download File'
                                    "
                                  >
                                  </label>
                                </div>
                              </div>

                              <div class="col-12 row" v-if="row.has_download">
                                <div class="form-group col-8">
                                  <label>Download Title</label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    v-model="row.download_name"
                                  />
                                </div>
                                <div class="form-group col-4">
                                  <label
                                    >Download File
                                    <button
                                      v-if="row.download_preview"
                                      type="button"
                                      class="btn btn-danger btn-sm btn-pill"
                                      style="
                                        margin-top: -10px;
                                        margin-left: 10px;
                                      "
                                      @click="
                                        row.download_preview = '';
                                        row.download_file = '';
                                      "
                                    >
                                      Remove File
                                    </button>
                                  </label>
                                  <a
                                    v-if="row.download_preview"
                                    :href="row.download_preview"
                                    target="_blank"
                                    class="pull-right"
                                    >Preview
                                  </a>
                                  <input
                                    type="file"
                                    class="form-control"
                                    @change="onFileChange"
                                  />
                                </div>
                                <div class="form-group col-md-6 col-sm-12">
                                  <label
                                    >Download Preview 180 * 220
                                    <button
                                      v-if="row.download_image_preview"
                                      type="button"
                                      class="btn btn-danger btn-sm btn-pill"
                                      style="
                                        margin-top: -10px;
                                        margin-left: 10px;
                                      "
                                      @click="
                                        row.download_image_preview = '';
                                        row.download_image = '';
                                      "
                                    >
                                      Remove Image
                                    </button>
                                  </label>
                                  <p>
                                    <img
                                      v-if="row.download_image_preview"
                                      :src="row.download_image_preview"
                                      style="max-width: 50%"
                                    />
                                  </p>
                                  <input
                                    type="file"
                                    class="form-control"
                                    @change="onFilePreviewChange"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End Card Writer -->
                </div>
                <!-- ******* End Card ******** -->
              </div>
            </div>
            <!-- End Content -->

            <!-- Buttons -->
            <div class="col-md-12 row">
              <div class="form-group mr-2">
                <button class="btn btn-primary" :disabled="btnLoading">
                  <span v-if="btnLoading">
                    <span
                      class="spinner-grow spinner-grow-sm mr-1"
                      role="status"
                      aria-hidden="true"
                    >
                    </span
                    >Loading...
                  </span>
                  <span v-if="!btnLoading" class="ti-check-box"></span>
                  <span v-if="!btnLoading"> Update Program</span>
                </button>
              </div>

              <div class="form-group">
                <button
                  type="button"
                  class="btn btn-danger"
                  :disabled="btnLoading"
                  @click="cancel"
                >
                  <span class="ti-close"></span>
                  <span> Cancel </span>
                </button>
              </div>
            </div>
            <!-- End Buttons -->
          </form>
        </div>

        <Footer></Footer>
      </div>
    </main>
    <!-- End Main -->
  </div>
</template>

<script>
import Header from "../layouts/Header.vue";
import Navigation from "../layouts/Navigation";
import Footer from "../layouts/Footer.vue";
import { VueEditor } from "vue2-editor";
import iziToast from "izitoast";

export default {
  name: "Edit",
  components: {
    Header,
    Navigation,
    Footer,
    VueEditor,
  },
  data() {
    return {
      auth: {
        access_token: "",
      },
      row: {
        status: 1,
        has_faq: 0,
        has_application: 0,
        has_download: 0,
        has_payment: 0,

        faq_link: "",
        payment_name: "",
        payment_link: "",

        download_name: "",
        download_image_preview: "",
        download_image: "",
        download_preview: "",
        download_file: "",

        application_name: "",
        application_path: "",

        preview: "",
        image: "",
        base64Image: "",
        sort: 0,
        slug: "",
        subtitle: "",
        title: "",
        bgTitle: "",
        bgColor: "",
        body: "",

        title1: "Program Overview",
        title2: "What You'll Learn",
        title3: "Who Should Apply?",
        title4: "Entry Requirements",
        title5: "Price",

        body1: "",
        body2: "",
        body3: "",
        body4: "",
        body5: "",
        short_body: "",
      },

      croppieImage: "",
      cropped: false,
      pgLoading: true,
      btnLoading: false,
    };
  },
  mounted() {},
  computed: {},
  created() {
    // AccessToken & Role
    if (localStorage.getItem("access_token")) {
      this.auth.access_token = localStorage.getItem("access_token");
    }

    this.fetchRow();
  },
  methods: {
    // fetch Row
    fetchRow() {
      this.pgLoading = true;
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.access_token,
      };
      const options = {
        url: window.baseURL + "/products2/" + this.$route.params.id,
        method: "GET",
        data: {},
        params: {},
      };
      this.axios(options)
        .then((res) => {
          this.pgLoading = false;
          this.row.status = res.data.row.status;
          this.row.has_faq = res.data.row.has_faq;
          this.row.has_application = res.data.row.has_application;
          this.row.has_download = res.data.row.has_download;
          this.row.has_payment = res.data.row.has_payment;

          this.row.faq_link = res.data.row.faq_link;
          this.row.payment_name = res.data.row.payment_name;
          this.row.payment_link = res.data.row.payment_link;

          this.row.download_name = res.data.row.download_name;
          this.row.download_image_preview = res.data.row.download_image;
          this.row.download_image = res.data.row.download_image;
          this.row.download_preview = res.data.row.download_file;
          this.row.download_file = res.data.row.download_file;

          this.row.application_name = res.data.row.application_name;
          this.row.application_path = res.data.row.application_path;

          this.row.preview = res.data.row.image;
          this.row.base64Image = res.data.row.image;
          this.row.sort = res.data.row.sort;
          this.row.subtitle = res.data.row.subtitle;
          this.row.slug = res.data.row.slug;
          this.row.title = res.data.row.title;
          // this.row.body = res.data.row.body;

          this.row.title1 = res.data.row.title1;
          this.row.title2 = res.data.row.title2;
          this.row.title3 = res.data.row.title3;
          this.row.title4 = res.data.row.title4;
          this.row.title5 = res.data.row.title5;

          this.row.body1 = res.data.row.body1;
          this.row.body2 = res.data.row.body2;
          this.row.body3 = res.data.row.body3;
          this.row.body4 = res.data.row.body4;
          this.row.body5 = res.data.row.body5;
          this.row.bgTitle = res.data.row.bgTitle;
          this.row.bgColor = res.data.row.bgColor;
          this.row.short_body = res.data.row.short_body;
        })
        .catch((err) => {
          this.btnLoading = false;
          iziToast.warning({
            icon: "ti-alert",
            title: "Wow-man,",
            message: err.response ? err.response.data.message : "" + err,
          });
        })
        .finally(() => {});
    },

    // editRow
    editRow() {
      this.btnLoading = true;
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.access_token,
      };
      const config = { headers: { "Content-Type": "multipart/form-data" } };
      const options = {
        url: window.baseURL + "/products2/" + this.$route.params.id,
        method: "PUT",
        data: {
          status: this.row.status,
          has_faq: this.row.has_faq,
          has_application: this.row.has_application,
          has_download: this.row.has_download,
          has_payment: this.row.has_payment,

          faq_link: this.row.faq_link,
          payment_name: this.row.payment_name,
          payment_link: this.row.payment_link,

          download_name: this.row.download_name,
          download_image: this.row.download_image,
          download_file: this.row.download_file,

          application_name: this.row.application_name,
          application_path: this.row.application_path,

          base64Image: this.row.base64Image,
          sort: this.row.sort,
          subtitle: this.row.subtitle,
          title: this.row.title,
          slug: this.row.slug,
          body: this.row.body,

          title1: this.row.title1,
          title2: this.row.title2,
          title3: this.row.title3,
          title4: this.row.title4,
          title5: this.row.title5,

          body1: this.row.body1,
          body2: this.row.body2,
          body3: this.row.body3,
          body4: this.row.body4,
          body5: this.row.body5,
          short_body: this.row.short_body,
          bgTitle: this.row.bgTitle,
          bgColor: this.row.bgColor,
        },
      };
      this.axios(options, config)
        .then(() => {
          this.btnLoading = false;
          iziToast.success({
            icon: "ti-check",
            title: "Great job,",
            message: "Item Updated Successfully.",
          });
          this.$router.push({ name: "products2" });
        })
        .catch((err) => {
          this.btnLoading = false;
          iziToast.warning({
            icon: "ti-alert",
            title: "Wow-man,",
            message: err.response ? err.response.data.message : "" + err,
          });
        })
        .finally(() => {});
    },

    // Title
    onTitleChange() {
      this.onSlugChange(this.row.title);
    },

    // on Paste
    onSlugPaste() {
      let str = this.row.slug;
      this.onSlugChange(str);
    },
    onSlugChange(str) {
      if (!str) {
        let str = this.row.slug;
        this.row.slug = str
          .replace(/\s+/g, "-")
          .replace(/\?+/g, "-")
          .replace(/\/+/g, "-")
          .replace(/\\+/g, "-");
      } else {
        this.row.slug = str
          .replace(/\s+/g, "-")
          .replace(/\?+/g, "-")
          .replace(/\/+/g, "-")
          .replace(/\\+/g, "-");
      }
    },

    croppie(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      var reader = new FileReader();
      this.cropped = false;
      reader.onload = (e) => {
        this.$refs.croppieRef.bind({
          url: e.target.result,
        });
      };
      reader.readAsDataURL(files[0]);
    },

    onImageChange(e) {
      const file = e.target.files[0];
      this.row.preview = URL.createObjectURL(file);
      this.createBase64Image(file);
    },
    createBase64Image(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.base64Image = e.target.result;
      };
    },

    onFileChange(e) {
      const file = e.target.files[0];
      this.row.download_preview = URL.createObjectURL(file);
      this.createBase64File(file);
    },
    createBase64File(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.download_file = e.target.result;
      };
    },

    onFilePreviewChange(e) {
      const file = e.target.files[0];
      this.row.download_image_preview = URL.createObjectURL(file);
      this.createBase64FilePreview(file);
    },
    createBase64FilePreview(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.download_image = e.target.result;
      };
    },

    // active status
    onStatus() {
      if (this.row.status) this.row.status = 0;
      else this.row.status = 1;
    },
    onFaq() {
      if (this.row.has_faq) this.row.has_faq = 0;
      else this.row.has_faq = 1;
    },
    onPayment() {
      if (this.row.has_payment) this.row.has_payment = 0;
      else this.row.has_payment = 1;
    },
    onApplication() {
      if (this.row.has_application) this.row.has_application = 0;
      else this.row.has_application = 1;
    },
    onDownload() {
      if (this.row.has_download) this.row.has_download = 0;
      else this.row.has_download = 1;
    },

    // toggleCollapse
    collapseToggle(div) {
      let el = document.querySelector("span#iconToggle" + div);
      if (el.classList.contains("ti-angle-down")) {
        el.classList.remove("ti-angle-down");
        el.classList.add("ti-angle-up");
      } else {
        el.classList.remove("ti-angle-up");
        el.classList.add("ti-angle-down");
      }
    },

    // Cancel
    cancel() {
      if (confirm("Are You Sure?")) {
        this.$router.push({ name: "products2" });
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
