<template>
    <div class="">
        
        <!-- Sidebar -->
        <aside id="sidebar" class="u-sidebar">
            <div class="u-sidebar-inner">

                <!-- Sidebar Header -->
                <header class="u-sidebar-header">
                    <router-link :to="{ name: 'dashboard'}" class="u-sidebar-logo">
                        <img class="u-header-logo__icon" src="/assets/img/dashboard-icon.png" 
                            style="height: 20px" alt="">
                        <img class="u-header-logo__text" src="/assets/svg/dashboard-txt3.png" 
                            style="" alt="">
                    </router-link>
                </header>
                <!-- End Sidebar Header -->

                <!-- Sidebar Nav -->
                <nav class="u-sidebar-nav">
                    <!-- Sidebar Nav Menu -->

                    <ul class="u-sidebar-nav-menu u-sidebar-nav-menu--top-level" @click="opnLi">
                        <!-- Dashboard -->
                        <li class="u-sidebar-nav-menu__item">
                            <router-link :to="{ name: 'dashboard' }" 
                                class="u-sidebar-nav-menu__link"
                                :class="(this.$route.path == '/') ? 'active' : '' ">
                                <span class="ti-dashboard u-sidebar-nav-menu__item-icon"></span>
                                <span class="u-sidebar-nav-menu__item-title">Dashboard</span>
                            </router-link>
                        </li>
                        <!-- End Dashboard -->

                        <!-- Home -->
                        <li class="u-sidebar-nav-menu__item">
                            <router-link :to="{ name: 'sliders' }" class="u-sidebar-nav-menu__link"
                                :class="(this.$route.path == '/sliders') ? 'active' : ''">
                                <span class="ti-image u-sidebar-nav-menu__item-icon"></span>
                                <span class="u-sidebar-nav-menu__item-title">Home Sliders</span>
                            </router-link>
                        </li>
                        <!-- End Home -->

                        <!-- <li class="u-sidebar-nav-menu__item">
                            <router-link :to="{ name: 'homePrograms' }" class="u-sidebar-nav-menu__link"
                                :class="(this.$route.path == '/homePrograms') ? 'active' : ''">
                                <span class="ti-image u-sidebar-nav-menu__item-icon"></span>
                                <span class="u-sidebar-nav-menu__item-title">Home Programs</span>
                            </router-link>
                        </li> -->

                        <!-- Home -->
                        <li class="u-sidebar-nav-menu__item">
                            <router-link :to="{ name: 'home' }" class="u-sidebar-nav-menu__link"
                                :class="(this.$route.path == '/home') ? 'active' : ''">
                                <span class="ti-home u-sidebar-nav-menu__item-icon"></span>
                                <span class="u-sidebar-nav-menu__item-title">Home</span>
                            </router-link>
                        </li>
                        <!-- End Home -->

                         <!-- Popular Searchs -->
                        <li class="u-sidebar-nav-menu__item">
                            <router-link :to="{ name: 'searchs' }" class="u-sidebar-nav-menu__link"
                                :class="(this.$route.path == '/popular-searchs' || 
                                        this.$route.path == '/popular-searchs/status/active' ||
                                        this.$route.path == '/popular-searchs/status/inactive' ||
                                        this.$route.path == '/popular-searchs/status/trash' ||
                                        this.$route.path == '/popular-searchs/create') 
                                        ? 'active' : '' ">
                                <span class="ti-search u-sidebar-nav-menu__item-icon"></span>
                                <span class="u-sidebar-nav-menu__item-title">Popular Searchs</span>
                            </router-link>
                        </li>
                        <!-- End Popular Searchs -->

                        <!-- Accreditation -->
                        <li class="u-sidebar-nav-menu__item">
                            <router-link :to="{ name: 'online-applications', 
                                        params:{type: 'trainings-applications'}}" 
                                        class="u-sidebar-nav-menu__link"
                                        :class="
                                        (this.$route.path == '/online-applications/trainings-applications' || 
                                        this.$route.path == '/online-applications/memberships-applications' ||
                                        this.$route.path == '/online-applications/instructor-applications' ||
                                        this.$route.path == '/online-applications/experience-applications' ||
                                        this.$route.path == '/online-applications/accreditation-applications' || 
                                        this.$route.path == '/online-applications/certificate-applications') 
                                        ? 'active' : '' ">
                                <span class="ti-medall u-sidebar-nav-menu__item-icon"></span>
                                <span class="u-sidebar-nav-menu__item-title">Online Applications</span>
                            </router-link>
                        </li>
                        <!-- End Accreditation -->

                        <!-- Accreditation -->

                        <li class="u-sidebar-nav-menu__item">
                            <router-link :to="{ name: 'ourservices' }" class="u-sidebar-nav-menu__link"
                                :class="(this.$route.path == '/ourservices' || 
                                         this.$route.path == '/ourservices/status/active' ||
                                         this.$route.path == '/ourservices/status/inactive' ||
                                         this.$route.path == '/ourservices/status/trash' ||
                                         this.$route.path == '/ourservices/create'
                                        ) ? 'active' : '' ">
                                <span class="ti-medall u-sidebar-nav-menu__item-icon"></span>
                                <span class="u-sidebar-nav-menu__item-title">Our Services</span>
                            </router-link>
                        </li>

                        <li class="u-sidebar-nav-menu__item">
                            <router-link :to="{ name: 'ourpartners' }" class="u-sidebar-nav-menu__link"
                                :class="(this.$route.path == '/ourpartners' || 
                                         this.$route.path == '/ourpartners/status/active' ||
                                         this.$route.path == '/ourpartners/status/inactive' ||
                                         this.$route.path == '/ourpartners/status/trash' ||
                                         this.$route.path == '/ourpartners/create'
                                        ) ? 'active' : '' ">
                                <span class="ti-medall u-sidebar-nav-menu__item-icon"></span>
                                <span class="u-sidebar-nav-menu__item-title">Our Partners</span>
                            </router-link>
                        </li>


                        <li class="u-sidebar-nav-menu__item">
                            <router-link :to="{ name: 'accreditations' }" class="u-sidebar-nav-menu__link"
                                :class="(this.$route.path == '/accreditations' || 
                                         this.$route.path == '/accreditations/status/active' ||
                                         this.$route.path == '/accreditations/status/inactive' ||
                                         this.$route.path == '/accreditations/status/trash'
                                        ) ? 'active' : '' ">
                                <span class="ti-medall u-sidebar-nav-menu__item-icon"></span>
                                <span class="u-sidebar-nav-menu__item-title">Accreditations</span>
                            </router-link>
                        </li>
                        <!-- End Accreditation -->

                        <!-- Tranings -->
                        <!-- <li class="u-sidebar-nav-menu__item">
                            <router-link :to="{ name: 'trainings' }" 
                                class="u-sidebar-nav-menu__link"
                                :class="(this.$route.path == '/trainings-applications' ||
                                         this.$route.path == '/trainings-applications/status/seen' ||
                                         this.$route.path == '/trainings-applications/status/unseen' ||
                                         this.$route.path == '/trainings-applications/status/trash')  
                                         ? 'active' : '' ">
                                <span class="ti-announcement u-sidebar-nav-menu__item-icon"></span>
                                <span class="u-sidebar-nav-menu__item-title">Training 
                                    <span style='font-size:12px'>Applications</span></span>
                            </router-link>
                        </li> -->
                        <!-- End Tranings -->

                        <!-- Programs -->
                        <li class="u-sidebar-nav-menu__item">
                            <router-link :to="{ name: 'programs' }" class="u-sidebar-nav-menu__link"
                                :class="(this.$route.path == '/programs' ||
                                        this.$route.path == '/programs/status/active' ||
                                        this.$route.path == '/programs/status/inactive' || 
                                        this.$route.path == '/programs/status/trash' ||
                                        this.$route.path == '/programs/create') ? 'active' : '' ">
                                <span class="ti-pencil-alt u-sidebar-nav-menu__item-icon"></span>
                                <span class="u-sidebar-nav-menu__item-title">Programs</span>
                            </router-link>
                        </li>
                        <!-- End Programs -->


                         <!-- Contacts -->
                        <li class="u-sidebar-nav-menu__item">
                            <router-link :to="{ name: 'certificates' }" class="u-sidebar-nav-menu__link"
                                :class="(this.$route.path == '/certificates') ? 'active' : '' ">
                                <span class="ti-file u-sidebar-nav-menu__item-icon"></span>
                                <span class="u-sidebar-nav-menu__item-title">Our Certifications</span>
                            </router-link>
                        </li>
                        <!-- End Contacts -->

                       
                        <!-- Memberships -->
                        <li class="u-sidebar-nav-menu__item">
                            <router-link :to="{ name: 'memberships' }" class="u-sidebar-nav-menu__link"
                                :class="(this.$route.path == '/memberships' ||
                                        this.$route.path == '/memberships/status/published' ||
                                        this.$route.path == '/memberships/status/draft' || 
                                        this.$route.path == '/memberships/status/trash' ||
                                        this.$route.path == '/memberships/create') ? 'active' : '' ">
                                <span class="ti-id-badge u-sidebar-nav-menu__item-icon"></span>
                                <span class="u-sidebar-nav-menu__item-title">Memberships</span>
                            </router-link>
                        </li>
                        <!-- End Memberships -->

                        <!-- Members Applications -->
                        <!-- <li class="u-sidebar-nav-menu__item">
                            <router-link :to="{ name: 'members' }" 
                                class="u-sidebar-nav-menu__link"
                                :class="(this.$route.path == '/members-applications' || 
                                         this.$route.path == '/members-applications/status/seen' ||
                                         this.$route.path == '/members-applications/status/unseen' ||
                                         this.$route.path == '/members-applications/status/trash')   
                                         ? 'active' : '' ">
                                <span class="ti-signal u-sidebar-nav-menu__item-icon"></span>
                                <span class="u-sidebar-nav-menu__item-title">Membership 
                                    <span style="font-size: 12px">Applications</span>
                                </span>
                            </router-link>
                        </li> -->
                        <!-- End Members Applications -->

                        <!-- About -->
                        <li class="u-sidebar-nav-menu__item">
                            <router-link :to="{ name: 'about' }" class="u-sidebar-nav-menu__link"
                                :class="(this.$route.path == '/about') ? 'active' : '' ">
                                <span class="ti-help-alt u-sidebar-nav-menu__item-icon"></span>
                                <span class="u-sidebar-nav-menu__item-title">About</span>
                            </router-link>
                        </li>
                        <!-- End About -->

                        <!-- Contacts -->
                        <li class="u-sidebar-nav-menu__item">
                            <router-link :to="{ name: 'contacts' }" class="u-sidebar-nav-menu__link"
                                :class="(this.$route.path == '/contacts') ? 'active' : '' ">
                                <span class="ti-headphone-alt u-sidebar-nav-menu__item-icon"></span>
                                <span class="u-sidebar-nav-menu__item-title">Contacts</span>
                            </router-link>
                        </li>
                        <!-- End Contacts -->

                        <!-- Inbox -->
                        <li class="u-sidebar-nav-menu__item">
                            <router-link :to="{ name: 'inbox' }" class="u-sidebar-nav-menu__link"
                                :class="(this.$route.path == '/inbox' ||
                                         this.$route.path == '/inbox/status/seen' ||
                                         this.$route.path == '/inbox/status/unseen' ||
                                         this.$route.path == '/inbox/status/trash')  
                                        ? 'active' : '' ">
                                <span class="ti-email u-sidebar-nav-menu__item-icon"></span>
                                <span class="u-sidebar-nav-menu__item-title">Inbox</span>
                            </router-link>
                        </li>
                        <!-- End Inbox -->

                       


                       

                        <!-- <li class="u-sidebar-nav-menu__item">
                            <router-link :to="{ name: 'iam-instructor' }" 
                                class="u-sidebar-nav-menu__link"
                                :class="(this.$route.path == '/iam-instructor-applications' || 
                                         this.$route.path == '/iam-instructor-applications/status/seen' ||
                                         this.$route.path == '/iam-instructor-applications/status/unseen' ||
                                         this.$route.path == '/iam-instructor-applications/status/trash')   
                                         ? 'active' : '' ">
                                <span class="ti-signal u-sidebar-nav-menu__item-icon"></span>
                                <span class="u-sidebar-nav-menu__item-title">I'm instructor 
                                    <span style="font-size: 12px">Applications</span>
                                </span>
                            </router-link>
                        </li> -->

                        <!-- <li class="u-sidebar-nav-menu__item">
                            <router-link :to="{ name: 'ihave-experience' }" 
                                class="u-sidebar-nav-menu__link"
                                :class="(this.$route.path == '/ihave-experience-applications' || 
                                         this.$route.path == '/ihave-experience-applications/status/seen' ||
                                         this.$route.path == '/ihave-experience-applications/status/unseen' ||
                                         this.$route.path == '/ihave-experience-applications/status/trash')   
                                         ? 'active' : '' ">
                                <span class="ti-signal u-sidebar-nav-menu__item-icon"></span>
                                <span class="u-sidebar-nav-menu__item-title">I'v Experience 
                                    <span style="font-size: 12px">Applications</span>
                                </span>
                            </router-link>
                        </li> -->


                        <!-- Media -->
                        <li class="u-sidebar-nav-menu__item">
                            <router-link :to="{ name: 'media' }" class="u-sidebar-nav-menu__link"
                                :class="(this.$route.path == '/media') ? 'active' : '' ">
                                <span class="ti-cloud-up u-sidebar-nav-menu__item-icon"></span>
                                <span class="u-sidebar-nav-menu__item-title">Media</span>
                            </router-link>
                        </li>
                        <!-- End Media -->

                        <!-- Pages -->
                        <li class="u-sidebar-nav-menu__item">
                            <router-link :to="{ name: 'pages' }" class="u-sidebar-nav-menu__link"
                                :class="(this.$route.path == '/pages' ||
                                         this.$route.path == '/pages/status/active' ||
                                         this.$route.path == '/pages/status/inactive' ||
                                         this.$route.path == '/pages/create')  
                                         ? 'active' : '' ">
                                <span class="ti-files u-sidebar-nav-menu__item-icon"></span>
                                <span class="u-sidebar-nav-menu__item-title">Pages</span>
                            </router-link>
                        </li>
                        <!-- End Pages -->

                        
                        
                        

                        <!-- Users -->
                        <li class="u-sidebar-nav-menu__item">
                            <router-link :to="{ name: 'users' }" class="u-sidebar-nav-menu__link"
                                :class="(this.$route.path == '/users' || 
                                        this.$route.path == '/users/status/seen' ||
                                        this.$route.path == '/users/status/unseen' ||
                                        this.$route.path == '/users/status/trash') 
                                        ? 'active' : '' ">
                                <span class="ti-user u-sidebar-nav-menu__item-icon"></span>
                                <span class="u-sidebar-nav-menu__item-title">Users</span>
                            </router-link>
                        </li>
                        <!-- End Users -->

                        <!-- Faq -->
                        <li class="u-sidebar-nav-menu__item">
                            <router-link :to="{ name: 'faqs' }" class="u-sidebar-nav-menu__link"
                                :class="(this.$route.path == '/faqs' || 
                                        this.$route.path == '/faqs/status/active' ||
                                        this.$route.path == '/faqs/status/inactive' ||
                                        this.$route.path == '/faqs/status/trash' ||
                                        this.$route.path == '/faqs/create') 
                                        ? 'active' : '' ">
                                <span class="ti-info-alt u-sidebar-nav-menu__item-icon"></span>
                                <span class="u-sidebar-nav-menu__item-title">FAQs</span>
                            </router-link>
                        </li>
                        <!-- Faq -->

                        <!-- Privacy Policy -->
                        <li class="u-sidebar-nav-menu__item">
                            <router-link :to="{ name: 'privacy' }" class="u-sidebar-nav-menu__link"
                                :class="(this.$route.path == '/privacy-policy' || 
                                        this.$route.path == '/privacy-policy/status/active' ||
                                        this.$route.path == '/privacy-policy/status/inactive' ||
                                        this.$route.path == '/privacy-policy/status/trash' ||
                                        this.$route.path == '/privacy-policy/create') 
                                        ? 'active' : '' ">
                                <span class="ti-receipt u-sidebar-nav-menu__item-icon"></span>
                                <span class="u-sidebar-nav-menu__item-title">Privacy Policy</span>
                            </router-link>
                        </li>
                        <!-- End Privacy Policy -->

                        <!-- Events & Conferences -->
                        <li class="u-sidebar-nav-menu__item">
                            <router-link :to="{ name: 'events' }" class="u-sidebar-nav-menu__link"
                                :class="(this.$route.path == '/events-conferences') ? 'active' : '' ">
                                <span class="ti-flag-alt u-sidebar-nav-menu__item-icon"></span>
                                <span class="u-sidebar-nav-menu__item-title">Events & Conferences</span>
                            </router-link>
                        </li>
                        <!-- End Events & Conferenes -->

                        <!-- Online Training -->
                        <li class="u-sidebar-nav-menu__item">
                            <router-link :to="{ name: 'online' }" class="u-sidebar-nav-menu__link"
                                :class="(this.$route.path == '/online-training' || 
                                        this.$route.path == '/online-training/status/active' ||
                                        this.$route.path == '/online-training/status/inactive' ||
                                        this.$route.path == '/online-training/status/trash' ||
                                        this.$route.path == '/online-training/create') 
                                        ? 'active' : '' ">
                                <span class="ti-paint-roller u-sidebar-nav-menu__item-icon"></span>
                                <span class="u-sidebar-nav-menu__item-title">Online Trainings</span>
                            </router-link>
                        </li>
                        <!-- End Online Training -->

                        <!-- Social -->
                        <li class="u-sidebar-nav-menu__item">
                            <router-link :to="{ name: 'socials' }" class="u-sidebar-nav-menu__link"
                                :class="(this.$route.path == '/socials' || 
                                        this.$route.path == '/socials/status/active' ||
                                        this.$route.path == '/socials/status/inactive' ||
                                        this.$route.path == '/socials/status/trash') 
                                        ? 'active' : '' ">
                                <span class="ti-twitter u-sidebar-nav-menu__item-icon"></span>
                                <span class="u-sidebar-nav-menu__item-title">Social Networks</span>
                            </router-link>
                        </li>
                        <!-- End Social -->

                        <!-- Subscribers -->
                        <li class="u-sidebar-nav-menu__item">
                            <router-link :to="{ name: 'subscribers' }" class="u-sidebar-nav-menu__link"
                                :class="(this.$route.path == '/subscribers' || 
                                        this.$route.path == '/subscribers/status/seen' ||
                                        this.$route.path == '/subscribers/status/unseen' ||
                                        this.$route.path == '/subscribers/status/trash') 
                                        ? 'active' : '' ">
                                <span class="ti-map u-sidebar-nav-menu__item-icon"></span>
                                <span class="u-sidebar-nav-menu__item-title">Subscribers</span>
                            </router-link>
                        </li>
                        <!-- End Subscribers -->

                        <!-- Subscribers -->
                        <li class="u-sidebar-nav-menu__item">
                            <router-link :to="{ name: 'settings' }" class="u-sidebar-nav-menu__link"
                                :class="(this.$route.path == '/settings')? 'active' : '' ">
                                <span class="ti-settings u-sidebar-nav-menu__item-icon"></span>
                                <span class="u-sidebar-nav-menu__item-title">Settings</span>
                            </router-link>
                        </li>
                        <!-- End Subscribers -->


                        <!-- Subscribers -->
                        <li class="u-sidebar-nav-menu__item">
                            <router-link :to="{ name: 'emails' }" class="u-sidebar-nav-menu__link"
                                :class="(this.$route.path == '/email-templates')? 'active' : '' ">
                                <span class="ti-email u-sidebar-nav-menu__item-icon"></span>
                                <span class="u-sidebar-nav-menu__item-title">Email Templates</span>
                            </router-link>
                        </li>
                        <!-- End Subscribers -->


                        
                        <!-- Settings -->
                        <!-- <li class="u-sidebar-nav-menu__item">
                            <router-link :to="{ name: 'apps' }" class="u-sidebar-nav-menu__link"
                                :class="(this.$route.path == '/dashboard/apps') ? 'active' : '' ">
                                <span class="ti-layout-grid2 u-sidebar-nav-menu__item-icon"></span>
                                <span class="u-sidebar-nav-menu__item-title">App Settings</span>
                            </router-link>
                        </li> -->
                        <!-- End Settings -->



                        <li class="u-sidebar-nav-menu__item"></li>
                        <br/><br/><br/><br/><br/><br/>

                    </ul>
                    <!-- End Sidebar Nav Menu -->
                </nav>
                <!-- End Sidebar Nav -->

            </div>
        </aside>
        <!-- End Sidebar -->

    </div>
</template>

<script>
    export default {
        name: 'Navigation',
        props: {
            // nav_roles: {
            //     default: (localStorage.getItem('nav_roles')) ? localStorage.getItem('nav_roles') : 'show',
            // },
            // nav_sliders: {
            //     default: (localStorage.getItem('nav_sliders')) ? localStorage.getItem('nav_sliders') : 'show',
            // },
            // nav_themes: {
            //     default: (localStorage.getItem('nav_themes')) ? localStorage.getItem('nav_themes') : 'show',
            // },
            // nav_reports: {
            //     default: (localStorage.getItem('nav_reports')) ? localStorage.getItem('nav_reports') : 'hide',
            // },
            // nav_activity_logs: {
            //     default: (localStorage.getItem('nav_activity_logs')) ? localStorage.getItem('nav_activity_logs') : 'show',
            // },
            // nav_cache_management: {
            //     default: (localStorage.getItem('nav_cache_management')) ? localStorage.getItem('nav_cache_management') : 'show',
            // },
        },
        data(){
            return {
                //
            }
        },
        mounted() {},
        watch: {
          $route() {
            //
            // if(localStorage.getItem('nav_roles')) {
            //     this.$props.nav_roles = localStorage.getItem('nav_roles');
            // }
            // if(localStorage.getItem('nav_sliders')) {
            //     this.$props.nav_sliders = localStorage.getItem('nav_sliders');
            // }
            // if(localStorage.getItem('nav_themes')) {
            //     this.$props.nav_themes = localStorage.getItem('nav_themes');
            // }
            // if(localStorage.getItem('nav_reports')) {
            //     this.$props.nav_reports = localStorage.getItem('nav_reports');
            // }
            // if(localStorage.getItem('nav_activity_logs')) {
            //     this.$props.nav_activity_logs = localStorage.getItem('nav_activity_logs');
            // }
            // if(localStorage.getItem('nav_cache_management')) {
            //     this.$props.nav_cache_management = localStorage.getItem('nav_cache_management');
            // }

          }
        },
        created() {
            //
            // if(localStorage.getItem('nav_roles')) {
            //     this.$props.nav_roles = localStorage.getItem('nav_roles');
            // }
            // if(localStorage.getItem('nav_sliders')) {
            //     this.$props.nav_sliders = localStorage.getItem('nav_sliders');
            // }
            // if(localStorage.getItem('nav_themes')) {
            //     this.$props.nav_themes = localStorage.getItem('nav_themes');
            // }
            // if(localStorage.getItem('nav_reports')) {
            //     this.$props.nav_reports = localStorage.getItem('nav_reports');
            // }
            // if(localStorage.getItem('nav_activity_logs')) {
            //     this.$props.nav_activity_logs = localStorage.getItem('nav_activity_logs');
            // }
            // if(localStorage.getItem('nav_cache_management')) {
            //     this.$props.nav_cache_management = localStorage.getItem('nav_cache_management');
            // }
        },
        methods: {
            //
       //   requestPropChange: function (path, value) {
             //    if (this.is(path, 'String') && this.isDefined(value) && (new RegExp('^[a-zA-Z_\\$]\\w*(\\.([a-zA-Z_\\$]\\w*|[0-9]+))*$')).test(path)) {
             //      this.$emit('propChange', {path: path.split('.'), value: value})
             //    }
             // },

            onChangeNav(e){
                if(this.$refs['itemComponent'+e].classList.contains('u-sidebar-nav--opened')) {
                    this.$refs['itemComponent'+e].classList.remove('u-sidebar-nav--opened');
                    this.$refs['menuItemComponent'+e].style.display = 'none';
                } else {
                    this.$refs['itemComponent'+e].classList.add('u-sidebar-nav--opened');
                    this.$refs['menuItemComponent'+e].style.display = 'block';
                }
            },

            opnLi(){
                let el = document.querySelector("body");
                let aside = document.querySelector("aside");
                //if(aside.classList.contains('toggled')) {

                    el.classList.remove('side-nav-on-action');
                    el.classList.remove('sidebar-mobile-closed');
                    
                    aside.classList.remove('toggled');
                    aside.classList.remove('action');
                    aside.classList.remove('mini');
                //} else {
                //    aside.classList.add('toggled');
                //    aside.classList.add('action');
                //    aside.classList.add('mini');
                //}
            },

        },
    }
</script>
