<template>
  <div class="">
    <Header></Header>

    <!-- Main -->
    <main class="u-main">
      <Navigation></Navigation>

      <div class="u-content">
        <div class="u-body min-h-700">
          <h1 class="h2 mb-2">Home</h1>

          <!-- Breadcrumb -->
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <router-link :to="{ name: 'dashboard' }">Dashboard</router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">Home</li>
            </ol>
          </nav>
          <!-- End Breadcrumb -->

          <!-- Loading -->
          <div v-if="pgLoading" class="row h-100">
            <div class="container text-center">
              <div class="spinner-grow mt-5" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </div>
          <!-- End Loading -->

          <!-- Form -->
          <form
            v-if="!pgLoading"
            @submit.prevent="editRow"
            enctype="multipart/form-data"
            class="h-100"
          >
            <div class="tab-content">
              <div class="row">
                <div class="col-md-12 mb-5">
                  <div class="card">
                    <div class="card-body">
                      <div id="accordion" class="accordion">
                        <div id="TabBackground1" class="card-header">
                          <h2
                            class="h4 card-header-title"
                            @click="collapseToggle('Background1')"
                            aria-expanded="false"
                            aria-controls="collapseBackground1"
                            data-toggle="collapse"
                            data-target="#collapseBackground1"
                          >
                            <span
                              id="iconToggleBackground1"
                              class="ti-angle-up u-sidebar-nav-menu__item-arrow pull-right black"
                            >
                            </span>
                          </h2>
                        </div>
                        <div
                          id="collapseBackground1"
                          class="collapse show"
                          aria-labelledby="TabBackground1"
                          data-parent="#accordion"
                        >
                          <div class="col-12 pt-3">
                            <div class="col-12 row" style="padding: 0">
                              <div class="form-group col-12 row">
                                <div
                                  class="form-group col-12"
                                  style="margin-top: 10px"
                                >
                                  <label>Title</label>
                                  <vue-editor
                                    id="editors0title1"
                                    v-model="row.title1"
                                    :init="{
                                      height: 200,
                                    }"
                                  />
                                </div>
                                <div
                                  class="form-group col-12"
                                  style="margin-top: 10px"
                                >
                                  <label>Description</label>
                                  <vue-editor
                                    id="editors0body1"
                                    v-model="row.body1"
                                    :init="{
                                      height: 300,
                                    }"
                                  />
                                </div>

                                <div
                                  class="col-12 row"
                                  style="
                                    margin-top: 10px;
                                    margin-left: 0px;
                                    padding: 0;
                                  "
                                >
                                  <div class="form-group col-6">
                                    <label>Button</label>
                                    <input
                                      type="text"
                                      v-model="row.button1"
                                      class="form-control"
                                    />
                                  </div>
                                  <div class="form-group col-6">
                                    <label>Redirect</label>
                                    <input
                                      type="text"
                                      v-model="row.redirect1"
                                      class="form-control"
                                    />
                                  </div>

                                  <div class="form-group col-12">
                                    <label>Video Youtube Link</label>
                                    <input
                                      type="text"
                                      v-model="row.video1"
                                      class="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-12 mb-5">
                  <div class="card">
                    <div class="card-body">
                      <div id="accordion" class="accordion">
                        <div id="TabBackground2" class="card-header">
                          <h2
                            class="h4 card-header-title"
                            @click="collapseToggle('Background2')"
                            aria-expanded="false"
                            aria-controls="collapseBackground2"
                            data-toggle="collapse"
                            data-target="#collapseBackground2"
                          >
                            <span
                              id="iconToggleBackground2"
                              class="ti-angle-up u-sidebar-nav-menu__item-arrow pull-right black"
                            >
                            </span>
                          </h2>
                        </div>
                        <div
                          id="collapseBackground2"
                          class="collapse show"
                          aria-labelledby="TabBackground2"
                          data-parent="#accordion"
                        >
                          <div class="col-12 pt-3">
                            <div class="col-12 row" style="padding: 0">
                              <div class="form-group col-12">
                                <label
                                  >Image 1400 * 590
                                  <button
                                    v-if="row.preview2"
                                    type="button"
                                    class="btn btn-danger btn-sm btn-pill"
                                    style="margin-top: -10px; margin-left: 10px"
                                    @click="
                                      row.preview2 = '';
                                      row.image2 = '';
                                    "
                                  >
                                    Remove Image
                                  </button>
                                </label>
                                <p>
                                  <img
                                    v-if="row.preview2"
                                    :src="row.preview2"
                                    style="max-width: 100%"
                                  />
                                </p>
                                <input
                                  type="file"
                                  class="form-control"
                                  v-on:change="onImage2Change"
                                />
                              </div>
                              <div class="form-group col-12 row">
                                <div class="form-group col-12">
                                  <label>Line Color</label>
                                  <input
                                    type="color"
                                    class="form-control"
                                    v-model="row.lineColor2"
                                    style="height: 100px"
                                  />
                                </div>
                                <div
                                  class="form-group col-12"
                                  style="margin-top: 10px"
                                >
                                  <label>Title</label>
                                  <vue-editor
                                    id="editors0title2"
                                    v-model="row.title2"
                                    :init="{
                                      height: 200,
                                    }"
                                  />
                                </div>

                                <div
                                  class="form-group col-12"
                                  style="margin-top: 10px"
                                >
                                  <label>Description</label>
                                  <vue-editor
                                    id="editors0body2"
                                    v-model="row.body2"
                                    :init="{
                                      height: 300,
                                    }"
                                  />
                                </div>

                                <div
                                  class="col-12 row"
                                  style="
                                    margin-top: 10px;
                                    margin-left: 0px;
                                    padding: 0;
                                  "
                                >
                                  <div class="form-group col-6">
                                    <label>Button</label>
                                    <input
                                      type="text"
                                      v-model="row.button2"
                                      class="form-control"
                                    />
                                  </div>
                                  <div class="form-group col-6">
                                    <label>Redirect</label>
                                    <input
                                      type="text"
                                      v-model="row.redirect2"
                                      class="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-12 mb-5">
                  <div class="card">
                    <div class="card-body">
                      <div id="accordion" class="accordion">
                        <div id="TabBackground3" class="card-header">
                          <h2
                            class="h4 card-header-title"
                            @click="collapseToggle('Background3')"
                            aria-expanded="false"
                            aria-controls="collapseBackground3"
                            data-toggle="collapse"
                            data-target="#collapseBackground3"
                          >
                            <span
                              id="iconToggleBackground3"
                              class="ti-angle-up u-sidebar-nav-menu__item-arrow pull-right black"
                            >
                            </span>
                          </h2>
                        </div>
                        <div
                          id="collapseBackground3"
                          class="collapse show"
                          aria-labelledby="TabBackground3"
                          data-parent="#accordion"
                        >
                          <div class="col-12 pt-3">
                            <div class="col-12 row" style="padding: 0">
                              <div class="form-group col-12 row">
                                <div class="form-group col-12">
                                  <label>Line Color</label>
                                  <input
                                    type="color"
                                    class="form-control"
                                    v-model="row.lineColor3"
                                    style="height: 100px"
                                  />
                                </div>

                                <div
                                  class="form-group col-12"
                                  style="margin-top: 10px"
                                >
                                  <label>Title</label>
                                  <vue-editor
                                    id="editors0title3"
                                    v-model="row.title3"
                                    :init="{
                                      height: 200,
                                    }"
                                  />
                                </div>

                                <div
                                  class="form-group col-12"
                                  style="margin-top: 10px"
                                >
                                  <label>Description</label>
                                  <vue-editor
                                    id="editors0body3"
                                    v-model="row.body3"
                                    :init="{
                                      height: 200,
                                    }"
                                  />
                                </div>

                                <div
                                  class="form-group row col-12"
                                  style="margin-top: 10px; padding: 0"
                                >
                                  <div class="form-group col-4 py-3">
                                    <div class="col-12">
                                      <label
                                        >Image 260 * 120
                                        <button
                                          v-if="row.logo_preview1"
                                          type="button"
                                          class="btn btn-danger btn-sm btn-pill"
                                          style="
                                            margin-top: -10px;
                                            margin-left: 10px;
                                          "
                                          @click="
                                            row.logo_preview1 = '';
                                            row.logo1 = '';
                                          "
                                        >
                                          Remove Image
                                        </button>
                                      </label>
                                      <p>
                                        <img
                                          v-if="row.logo_preview1"
                                          :src="row.logo_preview1"
                                          style="max-width: 100%"
                                        />
                                      </p>
                                      <input
                                        type="file"
                                        class="form-control"
                                        v-on:change="onLogo1Change"
                                      />
                                    </div>
                                    <div class="form-group col-12 py-3">
                                      <label>Button</label>
                                      <input
                                        type="text"
                                        class="form-control"
                                        v-model="row.btn1"
                                      />
                                    </div>
                                    <div class="form-group col-12">
                                      <label>Redirect</label>
                                      <input
                                        type="text"
                                        class="form-control"
                                        v-model="row.link1"
                                      />
                                    </div>
                                  </div>

                                  <div class="form-group col-4 py-3">
                                    <div class="col-12">
                                      <label
                                        >Image 260 * 120
                                        <button
                                          v-if="row.logo_preview2"
                                          type="button"
                                          class="btn btn-danger btn-sm btn-pill"
                                          style="
                                            margin-top: -10px;
                                            margin-left: 10px;
                                          "
                                          @click="
                                            row.logo_preview2 = '';
                                            row.logo2 = '';
                                          "
                                        >
                                          Remove Image
                                        </button>
                                      </label>
                                      <p>
                                        <img
                                          v-if="row.logo_preview2"
                                          :src="row.logo_preview2"
                                          style="max-width: 100%"
                                        />
                                      </p>
                                      <input
                                        type="file"
                                        class="form-control"
                                        v-on:change="onLogo2Change"
                                      />
                                    </div>
                                    <div class="form-group col-12 py-3">
                                      <label>Button</label>
                                      <input
                                        type="text"
                                        class="form-control"
                                        v-model="row.btn2"
                                      />
                                    </div>
                                    <div class="form-group col-12">
                                      <label>Redirect</label>
                                      <input
                                        type="text"
                                        class="form-control"
                                        v-model="row.link2"
                                      />
                                    </div>
                                  </div>

                                  <div class="form-group col-4 py-3">
                                    <div class="col-12">
                                      <label
                                        >Image 260 * 120
                                        <button
                                          v-if="row.logo_preview3"
                                          type="button"
                                          class="btn btn-danger btn-sm btn-pill"
                                          style="
                                            margin-top: -10px;
                                            margin-left: 10px;
                                          "
                                          @click="
                                            row.logo_preview3 = '';
                                            row.logo3 = '';
                                          "
                                        >
                                          Remove Image
                                        </button>
                                      </label>
                                      <p>
                                        <img
                                          v-if="row.logo_preview3"
                                          :src="row.logo_preview3"
                                          style="max-width: 100%"
                                        />
                                      </p>
                                      <input
                                        type="file"
                                        class="form-control"
                                        v-on:change="onLogo3Change"
                                      />
                                    </div>
                                    <div class="form-group col-12 py-3">
                                      <label>Button</label>
                                      <input
                                        type="text"
                                        class="form-control"
                                        v-model="row.btn3"
                                      />
                                    </div>
                                    <div class="form-group col-12">
                                      <label>Redirect</label>
                                      <input
                                        type="text"
                                        class="form-control"
                                        v-model="row.link3"
                                      />
                                    </div>
                                  </div>

                                  <div class="col-12"><hr /></div>

                                  <div class="form-group col-4 py-3">
                                    <div class="col-12">
                                      <label
                                        >Image 260 * 120
                                        <button
                                          v-if="row.logo_preview4"
                                          type="button"
                                          class="btn btn-danger btn-sm btn-pill"
                                          style="
                                            margin-top: -10px;
                                            margin-left: 10px;
                                          "
                                          @click="
                                            row.logo_preview4 = '';
                                            row.logo4 = '';
                                          "
                                        >
                                          Remove Image
                                        </button>
                                      </label>
                                      <p>
                                        <img
                                          v-if="row.logo_preview4"
                                          :src="row.logo_preview4"
                                          style="max-width: 100%"
                                        />
                                      </p>
                                      <input
                                        type="file"
                                        class="form-control"
                                        v-on:change="onLogo4Change"
                                      />
                                    </div>
                                    <div class="form-group col-12 py-3">
                                      <label>Button</label>
                                      <input
                                        type="text"
                                        class="form-control"
                                        v-model="row.btn4"
                                      />
                                    </div>
                                    <div class="form-group col-12">
                                      <label>Redirect</label>
                                      <input
                                        type="text"
                                        class="form-control"
                                        v-model="row.link4"
                                      />
                                    </div>
                                  </div>

                                  <div class="form-group col-4 py-3">
                                    <div class="col-12">
                                      <label
                                        >Image 260 * 120
                                        <button
                                          v-if="row.logo_preview5"
                                          type="button"
                                          class="btn btn-danger btn-sm btn-pill"
                                          style="
                                            margin-top: -10px;
                                            margin-left: 10px;
                                          "
                                          @click="
                                            row.logo_preview5 = '';
                                            row.logo5 = '';
                                          "
                                        >
                                          Remove Image
                                        </button>
                                      </label>
                                      <p>
                                        <img
                                          v-if="row.logo_preview5"
                                          :src="row.logo_preview5"
                                          style="max-width: 100%"
                                        />
                                      </p>
                                      <input
                                        type="file"
                                        class="form-control"
                                        v-on:change="onLogo5Change"
                                      />
                                    </div>
                                    <div class="form-group col-12 py-3">
                                      <label>Button</label>
                                      <input
                                        type="text"
                                        class="form-control"
                                        v-model="row.btn5"
                                      />
                                    </div>
                                    <div class="form-group col-12">
                                      <label>Redirect</label>
                                      <input
                                        type="text"
                                        class="form-control"
                                        v-model="row.link5"
                                      />
                                    </div>
                                  </div>

                                  <div class="form-group col-4 py-3">
                                    <div class="col-12">
                                      <label
                                        >Image 260 * 120
                                        <button
                                          v-if="row.logo_preview6"
                                          type="button"
                                          class="btn btn-danger btn-sm btn-pill"
                                          style="
                                            margin-top: -10px;
                                            margin-left: 10px;
                                          "
                                          @click="
                                            row.logo_preview6 = '';
                                            row.logo6 = '';
                                          "
                                        >
                                          Remove Image
                                        </button>
                                      </label>
                                      <p>
                                        <img
                                          v-if="row.logo_preview6"
                                          :src="row.logo_preview6"
                                          style="max-width: 100%"
                                        />
                                      </p>
                                      <input
                                        type="file"
                                        class="form-control"
                                        v-on:change="onLogo6Change"
                                      />
                                    </div>
                                    <div class="form-group col-12 py-3">
                                      <label>Button</label>
                                      <input
                                        type="text"
                                        class="form-control"
                                        v-model="row.btn6"
                                      />
                                    </div>
                                    <div class="form-group col-12">
                                      <label>Redirect</label>
                                      <input
                                        type="text"
                                        class="form-control"
                                        v-model="row.link6"
                                      />
                                    </div>
                                  </div>

                                  <div class="col-12"><hr /></div>

                                  <div class="form-group col-4 py-3">
                                    <div class="col-12">
                                      <label
                                        >Image 260 * 120
                                        <button
                                          v-if="row.logo_preview7"
                                          type="button"
                                          class="btn btn-danger btn-sm btn-pill"
                                          style="
                                            margin-top: -10px;
                                            margin-left: 10px;
                                          "
                                          @click="
                                            row.logo_preview7 = '';
                                            row.logo7 = '';
                                          "
                                        >
                                          Remove Image
                                        </button>
                                      </label>
                                      <p>
                                        <img
                                          v-if="row.logo_preview7"
                                          :src="row.logo_preview7"
                                          style="max-width: 100%"
                                        />
                                      </p>
                                      <input
                                        type="file"
                                        class="form-control"
                                        v-on:change="onLogo7Change"
                                      />
                                    </div>
                                    <div class="form-group col-12 py-3">
                                      <label>Button</label>
                                      <input
                                        type="text"
                                        class="form-control"
                                        v-model="row.btn7"
                                      />
                                    </div>
                                    <div class="form-group col-12">
                                      <label>Redirect</label>
                                      <input
                                        type="text"
                                        class="form-control"
                                        v-model="row.link7"
                                      />
                                    </div>
                                  </div>

                                  <div class="form-group col-4 py-3">
                                    <div class="col-12">
                                      <label
                                        >Image 260 * 120
                                        <button
                                          v-if="row.logo_preview8"
                                          type="button"
                                          class="btn btn-danger btn-sm btn-pill"
                                          style="
                                            margin-top: -10px;
                                            margin-left: 10px;
                                          "
                                          @click="
                                            row.logo_preview8 = '';
                                            row.logo8 = '';
                                          "
                                        >
                                          Remove Image
                                        </button>
                                      </label>
                                      <p>
                                        <img
                                          v-if="row.logo_preview8"
                                          :src="row.logo_preview8"
                                          style="max-width: 100%"
                                        />
                                      </p>
                                      <input
                                        type="file"
                                        class="form-control"
                                        v-on:change="onLogo8Change"
                                      />
                                    </div>
                                    <div class="form-group col-12 py-3">
                                      <label>Button</label>
                                      <input
                                        type="text"
                                        class="form-control"
                                        v-model="row.btn8"
                                      />
                                    </div>
                                    <div class="form-group col-12">
                                      <label>Redirect</label>
                                      <input
                                        type="text"
                                        class="form-control"
                                        v-model="row.link8"
                                      />
                                    </div>
                                  </div>

                                  <div class="form-group col-4 py-3">
                                    <div class="col-12">
                                      <label
                                        >Image 260 * 120
                                        <button
                                          v-if="row.logo_preview9"
                                          type="button"
                                          class="btn btn-danger btn-sm btn-pill"
                                          style="
                                            margin-top: -10px;
                                            margin-left: 10px;
                                          "
                                          @click="
                                            row.logo_preview9 = '';
                                            row.logo9 = '';
                                          "
                                        >
                                          Remove Image
                                        </button>
                                      </label>
                                      <p>
                                        <img
                                          v-if="row.logo_preview9"
                                          :src="row.logo_preview9"
                                          style="max-width: 100%"
                                        />
                                      </p>
                                      <input
                                        type="file"
                                        class="form-control"
                                        v-on:change="onLogo9Change"
                                      />
                                    </div>
                                    <div class="form-group col-12 py-3">
                                      <label>Button</label>
                                      <input
                                        type="text"
                                        class="form-control"
                                        v-model="row.btn9"
                                      />
                                    </div>
                                    <div class="form-group col-12">
                                      <label>Redirect</label>
                                      <input
                                        type="text"
                                        class="form-control"
                                        v-model="row.link9"
                                      />
                                    </div>
                                  </div>

                                  <div class="col-12"><hr /></div>

                                  <div class="form-group col-4 py-3">
                                    <div class="col-12">
                                      <label
                                        >Image 260 * 120
                                        <button
                                          v-if="row.logo_preview10"
                                          type="button"
                                          class="btn btn-danger btn-sm btn-pill"
                                          style="
                                            margin-top: -10px;
                                            margin-left: 10px;
                                          "
                                          @click="
                                            row.logo_preview10 = '';
                                            row.logo10 = '';
                                          "
                                        >
                                          Remove Image
                                        </button>
                                      </label>
                                      <p>
                                        <img
                                          v-if="row.logo_preview10"
                                          :src="row.logo_preview10"
                                          style="max-width: 100%"
                                        />
                                      </p>
                                      <input
                                        type="file"
                                        class="form-control"
                                        v-on:change="onLogo10Change"
                                      />
                                    </div>
                                    <div class="form-group col-12 py-3">
                                      <label>Button</label>
                                      <input
                                        type="text"
                                        class="form-control"
                                        v-model="row.btn10"
                                      />
                                    </div>
                                    <div class="form-group col-12">
                                      <label>Redirect</label>
                                      <input
                                        type="text"
                                        class="form-control"
                                        v-model="row.link10"
                                      />
                                    </div>
                                  </div>

                                  <div class="form-group col-4 py-3">
                                    <div class="col-12">
                                      <label
                                        >Image 260 * 120
                                        <button
                                          v-if="row.logo_preview11"
                                          type="button"
                                          class="btn btn-danger btn-sm btn-pill"
                                          style="
                                            margin-top: -10px;
                                            margin-left: 10px;
                                          "
                                          @click="
                                            row.logo_preview11 = '';
                                            row.logo11 = '';
                                          "
                                        >
                                          Remove Image
                                        </button>
                                      </label>
                                      <p>
                                        <img
                                          v-if="row.logo_preview11"
                                          :src="row.logo_preview11"
                                          style="max-width: 100%"
                                        />
                                      </p>
                                      <input
                                        type="file"
                                        class="form-control"
                                        v-on:change="onLogo11Change"
                                      />
                                    </div>
                                    <div class="form-group col-12 py-3">
                                      <label>Button</label>
                                      <input
                                        type="text"
                                        class="form-control"
                                        v-model="row.btn11"
                                      />
                                    </div>
                                    <div class="form-group col-12">
                                      <label>Redirect</label>
                                      <input
                                        type="text"
                                        class="form-control"
                                        v-model="row.link11"
                                      />
                                    </div>
                                  </div>

                                  <div class="form-group col-4 py-3">
                                    <div class="col-12">
                                      <label
                                        >Image 260 * 120
                                        <button
                                          v-if="row.logo_preview12"
                                          type="button"
                                          class="btn btn-danger btn-sm btn-pill"
                                          style="
                                            margin-top: -10px;
                                            margin-left: 10px;
                                          "
                                          @click="
                                            row.logo_preview12 = '';
                                            row.logo12 = '';
                                          "
                                        >
                                          Remove Image
                                        </button>
                                      </label>
                                      <p>
                                        <img
                                          v-if="row.logo_preview12"
                                          :src="row.logo_preview12"
                                          style="max-width: 100%"
                                        />
                                      </p>
                                      <input
                                        type="file"
                                        class="form-control"
                                        v-on:change="onLogo12Change"
                                      />
                                    </div>
                                    <div class="form-group col-12 py-3">
                                      <label>Button</label>
                                      <input
                                        type="text"
                                        class="form-control"
                                        v-model="row.btn12"
                                      />
                                    </div>
                                    <div class="form-group col-12">
                                      <label>Redirect</label>
                                      <input
                                        type="text"
                                        class="form-control"
                                        v-model="row.link12"
                                      />
                                    </div>
                                  </div>

                                  <div class="col-12"><hr /></div>

                                  <div class="form-group col-4 py-3">
                                    <div class="col-12">
                                      <label
                                        >Image 260 * 120
                                        <button
                                          v-if="row.logo_preview13"
                                          type="button"
                                          class="btn btn-danger btn-sm btn-pill"
                                          style="
                                            margin-top: -10px;
                                            margin-left: 10px;
                                          "
                                          @click="
                                            row.logo_preview13 = '';
                                            row.logo13 = '';
                                          "
                                        >
                                          Remove Image
                                        </button>
                                      </label>
                                      <p>
                                        <img
                                          v-if="row.logo_preview13"
                                          :src="row.logo_preview13"
                                          style="max-width: 100%"
                                        />
                                      </p>
                                      <input
                                        type="file"
                                        class="form-control"
                                        v-on:change="onLogo13Change"
                                      />
                                    </div>
                                    <div class="form-group col-12 py-3">
                                      <label>Button</label>
                                      <input
                                        type="text"
                                        class="form-control"
                                        v-model="row.btn13"
                                      />
                                    </div>
                                    <div class="form-group col-12">
                                      <label>Redirect</label>
                                      <input
                                        type="text"
                                        class="form-control"
                                        v-model="row.link13"
                                      />
                                    </div>
                                  </div>

                                  <div class="form-group col-4 py-3">
                                    <div class="col-12">
                                      <label
                                        >Image 260 * 120
                                        <button
                                          v-if="row.logo_preview14"
                                          type="button"
                                          class="btn btn-danger btn-sm btn-pill"
                                          style="
                                            margin-top: -10px;
                                            margin-left: 10px;
                                          "
                                          @click="
                                            row.logo_preview14 = '';
                                            row.logo14 = '';
                                          "
                                        >
                                          Remove Image
                                        </button>
                                      </label>
                                      <p>
                                        <img
                                          v-if="row.logo_preview14"
                                          :src="row.logo_preview14"
                                          style="max-width: 100%"
                                        />
                                      </p>
                                      <input
                                        type="file"
                                        class="form-control"
                                        v-on:change="onLogo14Change"
                                      />
                                    </div>
                                    <div class="form-group col-12 py-3">
                                      <label>Button</label>
                                      <input
                                        type="text"
                                        class="form-control"
                                        v-model="row.btn14"
                                      />
                                    </div>
                                    <div class="form-group col-12">
                                      <label>Redirect</label>
                                      <input
                                        type="text"
                                        class="form-control"
                                        v-model="row.link14"
                                      />
                                    </div>
                                  </div>

                                  <div class="form-group col-4 py-3">
                                    <div class="col-12">
                                      <label
                                        >Image 260 * 120
                                        <button
                                          v-if="row.logo_preview15"
                                          type="button"
                                          class="btn btn-danger btn-sm btn-pill"
                                          style="
                                            margin-top: -10px;
                                            margin-left: 10px;
                                          "
                                          @click="
                                            row.logo_preview15 = '';
                                            row.logo15 = '';
                                          "
                                        >
                                          Remove Image
                                        </button>
                                      </label>
                                      <p>
                                        <img
                                          v-if="row.logo_preview15"
                                          :src="row.logo_preview15"
                                          style="max-width: 100%"
                                        />
                                      </p>
                                      <input
                                        type="file"
                                        class="form-control"
                                        v-on:change="onLogo15Change"
                                      />
                                    </div>
                                    <div class="form-group col-12 py-3">
                                      <label>Button</label>
                                      <input
                                        type="text"
                                        class="form-control"
                                        v-model="row.btn15"
                                      />
                                    </div>
                                    <div class="form-group col-12">
                                      <label>Redirect</label>
                                      <input
                                        type="text"
                                        class="form-control"
                                        v-model="row.link15"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-12 row mt-10">
              <div class="form-group mr-2">
                <button class="btn btn-primary" :disabled="btnLoading">
                  <span v-if="btnLoading">
                    <span
                      class="spinner-grow spinner-grow-sm mr-1"
                      role="status"
                      aria-hidden="true"
                    >
                    </span
                    >Loading...
                  </span>
                  <span v-if="!btnLoading" class="ti-check-box"></span>
                  <span v-if="!btnLoading"> Update Home</span>
                </button>
              </div>

              <div class="form-group">
                <button
                  type="button"
                  class="btn btn-danger"
                  :disabled="btnLoading"
                  @click="cancel"
                >
                  <span class="ti-close"></span>
                  <span> Cancel </span>
                </button>
              </div>
            </div>
          </form>
          <!-- End Form -->
        </div>
        <!-- End Content Body -->

        <Footer></Footer>
      </div>
      <!-- Content -->
    </main>
    <!-- End Main -->
  </div>
</template>

<script>
import Header from "../layouts/Header.vue";
import Navigation from "../layouts/Navigation";
import Footer from "../layouts/Footer.vue";
import { VueEditor } from "vue2-editor";
import iziToast from "izitoast";

export default {
  name: "Home",
  components: {
    Header,
    Navigation,
    Footer,
    VueEditor,
  },
  data() {
    return {
      auth: {
        access_token: "",
      },
      row: {
        // 1
        title1: "",
        body1: "",
        video1: "",
        button1: "",
        redirect1: "",

        // 2
        preview2: "",
        image2: "",
        lineColor2: "",
        title2: "",
        body2: "",
        button2: "",
        redirect2: "",

        // 3
        lineColor3: "",
        title3: "",
        body3: "",

        logo1: "",
        logo_preview1: "",
        btn1: "",
        link1: "",

        logo2: "",
        logo_preview2: "",
        btn2: "",
        link2: "",

        logo3: "",
        logo_preview3: "",
        btn3: "",
        link3: "",

        logo4: "",
        logo_preview4: "",
        btn4: "",
        link4: "",

        logo5: "",
        logo_preview5: "",
        btn5: "",
        link5: "",

        logo6: "",
        logo_preview6: "",
        btn6: "",
        link6: "",

        logo7: "",
        logo_preview7: "",
        btn7: "",
        link7: "",

        logo8: "",
        logo_preview8: "",
        btn8: "",
        link8: "",

        logo9: "",
        logo_preview9: "",
        btn9: "",
        link9: "",

        logo10: "",
        logo_preview10: "",
        btn10: "",
        link10: "",

        logo11: "",
        logo_preview11: "",
        btn11: "",
        link11: "",

        logo12: "",
        logo_preview12: "",
        btn12: "",
        link12: "",

        logo13: "",
        logo_preview13: "",
        btn13: "",
        link13: "",

        logo14: "",
        logo_preview14: "",
        btn14: "",
        link14: "",

        logo15: "",
        logo_preview15: "",
        btn15: "",
        link15: "",
      },

      pgLoading: true,
      btnLoading: false,
    };
  },
  mounted() {},
  created() {
    // AccessToken & Role
    if (localStorage.getItem("access_token")) {
      this.auth.access_token = localStorage.getItem("access_token");
    }

    this.fetchRow();
  },
  methods: {
    // fetchRow
    fetchRow() {
      this.pgLoading = true;
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.access_token,
      };
      const options = {
        url: window.baseURL + "/landingPages/1",
        method: "GET",
        data: {},
        params: {},
      };
      this.axios(options)
        .then((res) => {
          this.pgLoading = false;

          // 1
          this.row.title1 = res.data.row.title1;
          this.row.body1 = res.data.row.body1;
          this.row.video1 = res.data.row.video1;
          this.row.button1 = res.data.row.button1;
          this.row.redirect1 = res.data.row.redirect1;

          // 2
          this.row.preview2 = res.data.row.image2;
          this.row.image2 = res.data.row.image2;
          this.row.lineColor2 = res.data.row.lineColor2;
          this.row.title2 = res.data.row.title2;
          this.row.body2 = res.data.row.body2;
          this.row.button2 = res.data.row.button2;
          this.row.redirect2 = res.data.row.redirect2;

          // 3
          this.row.lineColor3 = res.data.row.lineColor3;
          this.row.title3 = res.data.row.title3;
          this.row.body3 = res.data.row.body3;

          this.row.logo1 = res.data.row.logo1;
          this.row.logo_preview1 = res.data.row.logo1;
          this.row.btn1 = res.data.row.btn1;
          this.row.link1 = res.data.row.link1;

          this.row.logo2 = res.data.row.logo2;
          this.row.logo_preview2 = res.data.row.logo2;
          this.row.btn2 = res.data.row.btn2;
          this.row.link2 = res.data.row.link2;

          this.row.logo3 = res.data.row.logo3;
          this.row.logo_preview3 = res.data.row.logo3;
          this.row.btn3 = res.data.row.btn3;
          this.row.link3 = res.data.row.link3;

          this.row.logo4 = res.data.row.logo4;
          this.row.logo_preview4 = res.data.row.logo4;
          this.row.btn4 = res.data.row.btn4;
          this.row.link4 = res.data.row.link4;

          this.row.logo5 = res.data.row.logo5;
          this.row.logo_preview5 = res.data.row.logo5;
          this.row.btn5 = res.data.row.btn5;
          this.row.link5 = res.data.row.link5;

          this.row.logo6 = res.data.row.logo6;
          this.row.logo_preview6 = res.data.row.logo6;
          this.row.btn6 = res.data.row.btn6;
          this.row.link6 = res.data.row.link6;

          this.row.logo7 = res.data.row.logo7;
          this.row.logo_preview7 = res.data.row.logo7;
          this.row.btn7 = res.data.row.btn7;
          this.row.link7 = res.data.row.link7;

          this.row.logo8 = res.data.row.logo8;
          this.row.logo_preview8 = res.data.row.logo8;
          this.row.btn8 = res.data.row.btn8;
          this.row.link8 = res.data.row.link8;

          this.row.logo9 = res.data.row.logo9;
          this.row.logo_preview9 = res.data.row.logo9;
          this.row.btn9 = res.data.row.btn9;
          this.row.link9 = res.data.row.link9;

          this.row.logo10 = res.data.row.logo10;
          this.row.logo_preview10 = res.data.row.logo10;
          this.row.btn10 = res.data.row.btn10;
          this.row.link10 = res.data.row.link10;

          this.row.logo11 = res.data.row.logo11;
          this.row.logo_preview11 = res.data.row.logo11;
          this.row.btn11 = res.data.row.btn11;
          this.row.link11 = res.data.row.link11;

          this.row.logo12 = res.data.row.logo12;
          this.row.logo_preview12 = res.data.row.logo12;
          this.row.btn12 = res.data.row.btn12;
          this.row.link12 = res.data.row.link12;

          this.row.logo13 = res.data.row.logo13;
          this.row.logo_preview13 = res.data.row.logo13;
          this.row.btn13 = res.data.row.btn13;
          this.row.link13 = res.data.row.link13;

          this.row.logo14 = res.data.row.logo14;
          this.row.logo_preview14 = res.data.row.logo14;
          this.row.btn14 = res.data.row.btn14;
          this.row.link14 = res.data.row.link14;

          this.row.logo15 = res.data.row.logo15;
          this.row.logo_preview15 = res.data.row.logo15;
          this.row.btn15 = res.data.row.btn15;
          this.row.link15 = res.data.row.link15;
        })
        .catch(() => {})
        .finally(() => {});
    },

    // editRow
    editRow() {
      this.btnLoading = true;
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.access_token,
      };
      const config = { headers: { "Content-Type": "multipart/form-data" } };
      const options = {
        url: window.baseURL + "/landingPages/1",
        method: "PUT",
        data: {
          // 1
          title1: this.row.title1,
          body1: this.row.body1,
          video1: this.row.video1,
          button1: this.row.button1,
          redirect1: this.row.redirect1,

          // 2
          image2: this.row.image2,
          lineColor2: this.row.lineColor2,
          title2: this.row.title2,
          body2: this.row.body2,
          button2: this.row.button2,
          redirect2: this.row.redirect2,

          // 3
          lineColor3: this.row.lineColor3,
          title3: this.row.title3,
          body3: this.row.body3,

          logo1: this.row.logo1,
          btn1: this.row.btn1,
          link1: this.row.link1,

          logo2: this.row.logo2,
          btn2: this.row.btn2,
          link2: this.row.link2,

          logo3: this.row.logo3,
          btn3: this.row.btn3,
          link3: this.row.link3,

          logo4: this.row.logo4,
          btn4: this.row.btn4,
          link4: this.row.link4,

          logo5: this.row.logo5,
          btn5: this.row.btn5,
          link5: this.row.link5,

          logo6: this.row.logo6,
          btn6: this.row.btn6,
          link6: this.row.link6,

          logo7: this.row.logo7,
          btn7: this.row.btn7,
          link7: this.row.link7,

          logo8: this.row.logo8,
          btn8: this.row.btn8,
          link8: this.row.link8,

          logo9: this.row.logo9,
          btn9: this.row.btn9,
          link9: this.row.link9,

          logo10: this.row.logo10,
          btn10: this.row.btn10,
          link10: this.row.link10,

          logo11: this.row.logo11,
          btn11: this.row.btn11,
          link11: this.row.link11,

          logo12: this.row.logo12,
          btn12: this.row.btn12,
          link12: this.row.link12,

          logo13: this.row.logo13,
          btn13: this.row.btn13,
          link13: this.row.link13,

          logo14: this.row.logo14,
          btn14: this.row.btn14,
          link14: this.row.link14,

          logo15: this.row.logo15,
          btn15: this.row.btn15,
          link15: this.row.link15,
        },
      };
      this.axios(options, config)
        .then(() => {
          this.btnLoading = false;
          iziToast.success({
            icon: "ti-check",
            title: "Great job,",
            message: "Home Updated Successfully.",
          });
          //this.$router.push({ name: 'home' })
        })
        .catch((err) => {
          this.btnLoading = false;
          iziToast.warning({
            icon: "ti-alert",
            title: "Wow-man,",
            message: err.response ? err.response.data.message : "" + err,
          });
        })
        .finally(() => {});
    },

    // Upload Featured image
    onImage1Change(e) {
      const file = e.target.files[0];
      this.row.preview1 = URL.createObjectURL(file);
      this.createBase64Image1(file);
    },
    createBase64Image1(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image1 = e.target.result;
      };
    },

    onImage1_2Change(e) {
      const file = e.target.files[0];
      this.row.preview1_2 = URL.createObjectURL(file);
      this.createBase64Image1_2(file);
    },
    createBase64Image1_2(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image1_2 = e.target.result;
      };
    },

    onImage2Change(e) {
      const file = e.target.files[0];
      this.row.preview2 = URL.createObjectURL(file);
      this.createBase64Image2(file);
    },
    createBase64Image2(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.image2 = e.target.result;
      };
    },

    onLogo1Change(e) {
      const file = e.target.files[0];
      this.row.logo_preview1 = URL.createObjectURL(file);
      this.createBase64Logo1(file);
    },
    createBase64Logo1(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.logo1 = e.target.result;
      };
    },

    onLogo2Change(e) {
      const file = e.target.files[0];
      this.row.logo_preview2 = URL.createObjectURL(file);
      this.createBase64Logo2(file);
    },
    createBase64Logo2(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.logo2 = e.target.result;
      };
    },

    onLogo3Change(e) {
      const file = e.target.files[0];
      this.row.logo_preview3 = URL.createObjectURL(file);
      this.createBase64Logo3(file);
    },
    createBase64Logo3(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.logo3 = e.target.result;
      };
    },

    onLogo4Change(e) {
      const file = e.target.files[0];
      this.row.logo_preview4 = URL.createObjectURL(file);
      this.createBase64Logo4(file);
    },
    createBase64Logo4(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.logo4 = e.target.result;
      };
    },

    onLogo5Change(e) {
      const file = e.target.files[0];
      this.row.logo_preview5 = URL.createObjectURL(file);
      this.createBase64Logo5(file);
    },
    createBase64Logo5(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.logo5 = e.target.result;
      };
    },

    onLogo6Change(e) {
      const file = e.target.files[0];
      this.row.logo_preview6 = URL.createObjectURL(file);
      this.createBase64Logo6(file);
    },
    createBase64Logo6(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.logo6 = e.target.result;
      };
    },

    onLogo7Change(e) {
      const file = e.target.files[0];
      this.row.logo_preview7 = URL.createObjectURL(file);
      this.createBase64Logo7(file);
    },
    createBase64Logo7(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.logo7 = e.target.result;
      };
    },

    onLogo8Change(e) {
      const file = e.target.files[0];
      this.row.logo_preview8 = URL.createObjectURL(file);
      this.createBase64Logo8(file);
    },
    createBase64Logo8(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.logo8 = e.target.result;
      };
    },

    onLogo9Change(e) {
      const file = e.target.files[0];
      this.row.logo_preview9 = URL.createObjectURL(file);
      this.createBase64Logo9(file);
    },
    createBase64Logo9(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.logo9 = e.target.result;
      };
    },

    onLogo10Change(e) {
      const file = e.target.files[0];
      this.row.logo_preview10 = URL.createObjectURL(file);
      this.createBase64Logo10(file);
    },
    createBase64Logo10(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.logo10 = e.target.result;
      };
    },

    onLogo11Change(e) {
      const file = e.target.files[0];
      this.row.logo_preview11 = URL.createObjectURL(file);
      this.createBase64Logo11(file);
    },
    createBase64Logo11(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.logo11 = e.target.result;
      };
    },

    onLogo12Change(e) {
      const file = e.target.files[0];
      this.row.logo_preview12 = URL.createObjectURL(file);
      this.createBase64Logo12(file);
    },
    createBase64Logo12(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.logo12 = e.target.result;
      };
    },

    onLogo13Change(e) {
      const file = e.target.files[0];
      this.row.logo_preview13 = URL.createObjectURL(file);
      this.createBase64Logo13(file);
    },
    createBase64Logo13(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.logo13 = e.target.result;
      };
    },

    onLogo14Change(e) {
      const file = e.target.files[0];
      this.row.logo_preview14 = URL.createObjectURL(file);
      this.createBase64Logo14(file);
    },
    createBase64Logo14(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.logo14 = e.target.result;
      };
    },

    onLogo15Change(e) {
      const file = e.target.files[0];
      this.row.logo_preview15 = URL.createObjectURL(file);
      this.createBase64Logo15(file);
    },
    createBase64Logo15(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = (e) => {
        this.row.logo15 = e.target.result;
      };
    },

    // Cancel
    cancel() {
      if (confirm("Are You Sure?")) {
        this.$router.push({ name: "dashboard" });
      }
    },

    // toggleCollapse
    collapseToggle(div) {
      let el = document.querySelector("span#iconToggle" + div);
      if (el.classList.contains("ti-angle-down")) {
        el.classList.remove("ti-angle-down");
        el.classList.add("ti-angle-up");
      } else {
        el.classList.remove("ti-angle-up");
        el.classList.add("ti-angle-down");
      }
    },
  },
};
</script>
