import Vue from 'vue'
import Router from 'vue-router'
//import store from '../store';

Vue.use(Router)
export default new Router({
  mode: 'history',
  base: '/',
  fallback: true,
  routes: [
	
	/** Dashboard **/

	// App
	{ path: '/',  name: 'dashboard', component: require('../components/dashboard/App.vue').default, beforeEnter: requireAuth },

  // Auth
  { path: '/auth/login', name: 'login', component: require('../components/auth/Login.vue').default, beforeEnter: requireUnAuth },
  { path: '/forget/password', name:'forget', component: require('../components/auth/Forget.vue').default, beforeEnter: requireUnAuth },
  { path: '/reset/password', name:'reset', component: require('../components/auth/Reset.vue').default, beforeEnter: requireAuth },

  // Home Programs
  { path: '/homePrograms', name: 'homePrograms', component: require('../components/homePrograms/List.vue').default, beforeEnter: requireAuth },
  { path: '/homePrograms/status/:status', name: 'status-homePrograms', component: require('../components/homePrograms/List.vue').default, beforeEnter: requireAuth },
  { path: '/homePrograms/create', name: 'create-homePrograms', component: require('../components/homePrograms/Create.vue').default, beforeEnter: requireAuth },
  { path: '/homePrograms/edit/:id', name: 'edit-homePrograms', component: require('../components/homePrograms/Edit.vue').default, beforeEnter: requireAuth },


  // Sliders
  { path: '/sliders', name: 'sliders', component: require('../components/sliders/List.vue').default, beforeEnter: requireAuth },
  { path: '/sliders/status/:status', name: 'status-sliders', component: require('../components/sliders/List.vue').default, beforeEnter: requireAuth },
  { path: '/sliders/create', name: 'create-sliders', component: require('../components/sliders/Create.vue').default, beforeEnter: requireAuth },
  { path: '/sliders/edit/:id', name: 'edit-sliders', component: require('../components/sliders/Edit.vue').default, beforeEnter: requireAuth },

  // Home
  { path: '/home', name: 'home', component: require('../components/extra/Home.vue').default, beforeEnter: requireAuth },

  // Our Services
  { path: '/ourservices', name: 'ourservices', component: require('../components/ourservices/List.vue').default, beforeEnter: requireAuth },
  { path: '/ourservices/status/:status', name: 'status-ourservices', component: require('../components/ourservices/List.vue').default, beforeEnter: requireAuth },
  { path: '/ourservices/create', name: 'create-ourservices', component: require('../components/ourservices/Create.vue').default, beforeEnter: requireAuth },
  { path: '/ourservices/edit/:id', name: 'edit-ourservices', component: require('../components/ourservices/Edit.vue').default, beforeEnter: requireAuth },
  // Our Partners
  { path: '/ourpartners', name: 'ourpartners', component: require('../components/ourpartners/List.vue').default, beforeEnter: requireAuth },
  { path: '/ourpartners/status/:status', name: 'status-ourpartners', component: require('../components/ourpartners/List.vue').default, beforeEnter: requireAuth },
  { path: '/ourpartners/create', name: 'create-ourpartners', component: require('../components/ourpartners/Create.vue').default, beforeEnter: requireAuth },
  { path: '/ourpartners/edit/:id', name: 'edit-ourpartners', component: require('../components/ourpartners/Edit.vue').default, beforeEnter: requireAuth },

  { path: '/ourpartners-root/:root_id', name: 'ourpartners-sub', component: require('../components/ourpartners_sub/List.vue').default, beforeEnter: requireAuth },
  { path: '/ourpartners-root/status/:status/:root_id', name: 'status-ourpartners-sub', component: require('../components/ourpartners_sub/List.vue').default, beforeEnter: requireAuth },
  { path: '/ourpartners-root/create/:root_id', name: 'create-ourpartners-sub', component: require('../components/ourpartners_sub/Create.vue').default, beforeEnter: requireAuth },
  { path: '/ourpartners-root/edit/:id/:root_id', name: 'edit-ourpartners-sub', component: require('../components/ourpartners_sub/Edit.vue').default, beforeEnter: requireAuth },


  // Accreditaion
  { path: '/accreditations', name: 'accreditations', component: require('../components/accreditations/List.vue').default, beforeEnter: requireAuth },
  { path: '/accreditations/status/:status', name: 'status-accreditations', component: require('../components/accreditations/List.vue').default, beforeEnter: requireAuth },
  { path: '/accreditations/create', name: 'create-accreditations', component: require('../components/accreditations/Create.vue').default, beforeEnter: requireAuth },
  { path: '/accreditations/edit/:id', name: 'edit-accreditations', component: require('../components/accreditations/Edit.vue').default, beforeEnter: requireAuth },

  // Programs
  { path: '/programs', name: 'programs', component: require('../components/programs/List.vue').default, beforeEnter: requireAuth },
  { path: '/programs/status/:status', name: 'status-programs', component: require('../components/programs/List.vue').default, beforeEnter: requireAuth },
  { path: '/programs/create', name: 'create-programs', component: require('../components/programs/Create.vue').default, beforeEnter: requireAuth },
  { path: '/programs/edit/:id', name: 'edit-programs', component: require('../components/programs/Edit.vue').default, beforeEnter: requireAuth },

  // Sectors
  { path: '/sectors/:program_id/:root', name: 'sectors', component: require('../components/sectors/List.vue').default, beforeEnter: requireAuth },
  { path: '/sectors/:program_id/:root/sub', name: 'sub-sectors', component: require('../components/sectors/List.vue').default, beforeEnter: requireAuth },
  { path: '/sectors/:program_id/:root/status/:status', name: 'status-sectors', component: require('../components/sectors/List.vue').default, beforeEnter: requireAuth },
  { path: '/sectors/:program_id/:root/create', name: 'create-sectors', component: require('../components/sectors/Create.vue').default, beforeEnter: requireAuth },
  { path: '/sectors/:program_id/:root/edit/:id', name: 'edit-sectors', component: require('../components/sectors/Edit.vue').default, beforeEnter: requireAuth },

  // Products
  { path: '/products/:sector_id', name: 'products', component: require('../components/products/List.vue').default, beforeEnter: requireAuth },
  { path: '/products/:sector_id/status/:status', name: 'status-products', component: require('../components/products/List.vue').default, beforeEnter: requireAuth },
  { path: '/products/:sector_id/create', name: 'create-products', component: require('../components/products/Create.vue').default, beforeEnter: requireAuth },
  { path: '/products/:sector_id/edit/:id', name: 'edit-products', component: require('../components/products/Edit.vue').default, beforeEnter: requireAuth },

  // MemberShips
  { path: '/memberships', name: 'memberships', component: require('../components/memberships/List.vue').default, beforeEnter: requireAuth },
  { path: '/memberships/status/:status', name: 'status-memberships', component: require('../components/memberships/List.vue').default, beforeEnter: requireAuth },
  { path: '/memberships/create', name: 'create-memberships', component: require('../components/memberships/Create.vue').default, beforeEnter: requireAuth },
  { path: '/memberships/edit/:id', name: 'edit-memberships', component: require('../components/memberships/Edit.vue').default, beforeEnter: requireAuth },

  // About
  { path: '/about', name: 'about', component: require('../components/about/List.vue').default, beforeEnter: requireAuth },
  { path: '/about/status/:status', name: 'status-about', component: require('../components/about/List.vue').default, beforeEnter: requireAuth },
  { path: '/about/create', name: 'create-about', component: require('../components/about/Create.vue').default, beforeEnter: requireAuth },
  { path: '/about/edit/:id', name: 'edit-about', component: require('../components/about/Edit.vue').default, beforeEnter: requireAuth },

  //{ path: '/about', name: 'about', component: require('../components/about/Main.vue').default, beforeEnter: requireAuth },
  // Contacts
  { path: '/contacts', name: 'contacts', component: require('../components/extra/Contacts.vue').default, beforeEnter: requireAuth },

  // Certificate
  { path: '/certificates', name: 'certificates', component: require('../components/certificates/Main.vue').default, beforeEnter: requireAuth },
  { path: '/certificates/products', name: 'products-certificates', component: require('../components/certificates/List.vue').default, beforeEnter: requireAuth },
  { path: '/certificates/products/status/:status', name: 'status-products-certificates', component: require('../components/certificates/List.vue').default, beforeEnter: requireAuth },
  { path: '/certificates/products/create', name: 'create-products-certificates', component: require('../components/certificates/Create.vue').default, beforeEnter: requireAuth },
  { path: '/certificates/products/edit/:id', name: 'edit-products-certificates', component: require('../components/certificates/Edit.vue').default, beforeEnter: requireAuth },

  // Inbox
  { path: '/inbox', name: 'inbox', component: require('../components/inbox/List.vue').default, beforeEnter: requireAuth },
  { path: '/inbox/status/:status', name: 'status-inbox', component: require('../components/inbox/List.vue').default, beforeEnter: requireAuth },
  { path: '/inbox/:id', name: 'show-inbox', component: require('../components/inbox/Show.vue').default, beforeEnter: requireAuth },

  // Media
  { path: '/media', name: 'media', component: require('../components/extra/Media.vue').default, beforeEnter: requireAuth },

  // Pages
  { path: '/pages', name: 'pages', component: require('../components/pages/List.vue').default, beforeEnter: requireAuth },
  { path: '/pages/status/:status', name: 'status-pages', component: require('../components/pages/List.vue').default, beforeEnter: requireAuth },
  { path: '/pages/create', name: 'create-pages', component: require('../components/pages/Create.vue').default, beforeEnter: requireAuth },
  { path: '/pages/edit/:id', name: 'edit-pages', component: require('../components/pages/Edit.vue').default, beforeEnter: requireAuth },

  { path: '/page-root/:root_id', name: 'pages-sub', component: require('../components/pages_sub/List.vue').default, beforeEnter: requireAuth },
  { path: '/page-root/status/:status/:root_id', name: 'status-pages-sub', component: require('../components/pages_sub/List.vue').default, beforeEnter: requireAuth },
  { path: '/page-root/create/:root_id', name: 'create-pages-sub', component: require('../components/pages_sub/Create.vue').default, beforeEnter: requireAuth },
  { path: '/page-root/edit/:id/:root_id', name: 'edit-pages-sub', component: require('../components/pages_sub/Edit.vue').default, beforeEnter: requireAuth },

  // Applications
  { path: '/online-applications/:type', name: 'online-applications', component: require('../components/applications/List.vue').default, beforeEnter: requireAuth },
  { path: '/online-applications/:type/filter/:filter_by/:filter', name: 'online-applications-filter', component: require('../components/applications/List.vue').default, beforeEnter: requireAuth },
  
  { path: '/online-applications/:type/update/layout', name: 'update-online-applications', component: require('../components/applications/Update.vue').default, beforeEnter: requireAuth },
  { path: '/online-applications/v2/:type/update/layout', name: 'update-online-applications2', component: require('../components/applications/Update2.vue').default, beforeEnter: requireAuth },
  { path: '/online-applications/:type/:id', name: 'show-online-applications', component: require('../components/applications/Show.vue').default, beforeEnter: requireAuth },
  { path: '/online-applications/v2/:type/:id', name: 'show-online-applications2', component: require('../components/applications/Show2.vue').default, beforeEnter: requireAuth },

  // Users
  { path: '/users', name: 'users', component: require('../components/users/List.vue').default, beforeEnter: requireAuth },
  { path: '/users/status/:status', name: 'status-users', component: require('../components/users/List.vue').default, beforeEnter: requireAuth },
  { path: '/users/:id', name: 'show-users', component: require('../components/users/Show.vue').default, beforeEnter: requireAuth },
    { path: '/users/:id/edit', name: 'edit-users', component: require('../components/users/Edit.vue').default, beforeEnter: requireAuth },

  // Search
  { path: '/popular-searchs', name: 'searchs', component: require('../components/searchs/List.vue').default, beforeEnter: requireAuth },
  { path: '/popular-searchs/status/:status', name: 'status-searchs', component: require('../components/searchs/List.vue').default, beforeEnter: requireAuth },
  { path: '/popular-searchs/create', name: 'create-searchs', component: require('../components/searchs/Create.vue').default, beforeEnter: requireAuth },
  { path: '/popular-searchs/edit/:id', name: 'edit-searchs', component: require('../components/searchs/Edit.vue').default, beforeEnter: requireAuth },

  { path: '/popular-root-searchs/:root_id', name: 'searchs-sub', component: require('../components/searchs_sub/List.vue').default, beforeEnter: requireAuth },
  { path: '/popular-root-searchs/status/:status/:root_id', name: 'status-searchs-sub', component: require('../components/searchs_sub/List.vue').default, beforeEnter: requireAuth },
  { path: '/popular-root-searchs/create/:root_id', name: 'create-searchs-sub', component: require('../components/searchs_sub/Create.vue').default, beforeEnter: requireAuth },
  { path: '/popular-root-searchs/edit/:id/:root_id', name: 'edit-searchs-sub', component: require('../components/searchs_sub/Edit.vue').default, beforeEnter: requireAuth },

  // Products
  { path: '/popular-searchs/programs', name: 'products2', component: require('../components/products2/List.vue').default, beforeEnter: requireAuth },
  { path: '/popular-searchs/programs/status/:status', name: 'status-products2', component: require('../components/products2/List.vue').default, beforeEnter: requireAuth },
  { path: '/popular-searchs/programs/create', name: 'create-products2', component: require('../components/products2/Create.vue').default, beforeEnter: requireAuth },
  { path: '/popular-searchs/programs/edit/:id', name: 'edit-products2', component: require('../components/products2/Edit.vue').default, beforeEnter: requireAuth },


  // FAQs
  { path: '/faqs', name: 'faqs', component: require('../components/faqs/List.vue').default, beforeEnter: requireAuth },
  { path: '/faqs/status/:status', name: 'status-faqs', component: require('../components/faqs/List.vue').default, beforeEnter: requireAuth },
  { path: '/faqs/create', name: 'create-faqs', component: require('../components/faqs/Create.vue').default, beforeEnter: requireAuth },
  { path: '/faqs/edit/:id', name: 'edit-faqs', component: require('../components/faqs/Edit.vue').default, beforeEnter: requireAuth },

  // Privacy
  { path: '/privacy-policy', name: 'privacy', component: require('../components/privacy/List.vue').default, beforeEnter: requireAuth },
  { path: '/privacy-policy/status/:status', name: 'status-privacy', component: require('../components/privacy/List.vue').default, beforeEnter: requireAuth },
  { path: '/privacy-policy/create', name: 'create-privacy', component: require('../components/privacy/Create.vue').default, beforeEnter: requireAuth },
  { path: '/privacy-policy/edit/:id', name: 'edit-privacy', component: require('../components/privacy/Edit.vue').default, beforeEnter: requireAuth },

  // Events & Conferences
  { path: '/events-conferences', name: 'events', component: require('../components/extra/Events.vue').default, beforeEnter: requireAuth },

  // Online Training
  { path: '/online-training', name: 'online', component: require('../components/online/List.vue').default, beforeEnter: requireAuth },
  { path: '/online-training/status/:status', name: 'status-online', component: require('../components/online/List.vue').default, beforeEnter: requireAuth },
  { path: '/online-training/create', name: 'create-online', component: require('../components/online/Create.vue').default, beforeEnter: requireAuth },
  { path: '/online-training/edit/:id', name: 'edit-online', component: require('../components/online/Edit.vue').default, beforeEnter: requireAuth },

  // Socials
  { path: '/socials', name: 'socials', component: require('../components/socials/List.vue').default, beforeEnter: requireAuth },
  { path: '/socials/status/:status', name: 'status-socials', component: require('../components/socials/List.vue').default, beforeEnter: requireAuth },

  // Subscribers
  { path: '/subscribers', name: 'subscribers', component: require('../components/extra/Subscribers.vue').default, beforeEnter: requireAuth },

  // Settings
  { path: '/settings', name: 'settings', component: require('../components/extra/Setting.vue').default, beforeEnter: requireAuth },
  { path: '/email-templates', name: 'emails', component: require('../components/extra/Emails.vue').default, beforeEnter: requireAuth },
    
  // Errors
  { path: '/forbidden', name: 'forbidden', component: require('../components/errors/Forbidden.vue').default, beforeEnter: requireAuth },
  { path: '/*', name: 'not-found', component: require('../components/errors/NotFound.vue').default }

  ]
})


function requireAuth(to, from, next) {
  // store.dispatch('fetchAccessToken');
  // if (!store.state.accessToken) { next('/login'); } 
  // else { next(); }
  if(!localStorage.getItem('access_token')) { next('/auth/login'); }
  else { next(); }
  //next();
}

function requireUnAuth(to, from, next) {
  //store.dispatch('fetchAccessToken');
  // if (store.state.accessToken) { next('/'); } 
  // else { next(); }
  if(localStorage.getItem('access_token')) { next('/'); }
  else { next(); }
  //next();
}